<template>
  <template v-if="REDUCE_MOTION">
    <slot />
  </template>
  <transition
    v-else
    :css="css"
    :mode="mode"
    @enter="enter"
    @leave="leave"
    @after-leave="$emit('afterLeave')"
  >
    <slot />
  </transition>
</template>

<script>
import { REDUCE_MOTION } from '@/constants';
import transitions from '@/helpers/transitions';

export default {
  props: {
    css: { type: Boolean, required: true },
    enter: { type: Function, default: transitions.fade.fadeIn },
    leave: { type: Function, default: transitions.fade.fadeOut },
    mode: { type: String, default: 'out-in' }
  },
  emits: ['afterLeave'],
  data() {
    return {
      REDUCE_MOTION
    };
  }
};
</script>
