<template>
  <li
    class="mx-4 border-b-2"
    :class="{
      'border-primary-900 dark:border-gray-300': active,
      'border-opacity-0': !active
    }"
  >
    <a v-if="isExternalLink" :href="href" :class="navItemStyle" :target="target">
      <slot />
    </a>
    <router-link v-else-if="isRouterLink" :to="to" :class="navItemStyle">
      <slot />
    </router-link>
    <button v-else :class="navItemStyle" @click="$emit('click')">
      <slot />
    </button>
  </li>
</template>

<script>
export default {
  name: 'NavItem',
  props: {
    active: { type: Boolean, default: false },
    href: { type: String, default: null },
    newTab: { type: Boolean, default: false },
    to: { type: [String, Object], default: null }
  },
  computed: {
    isExternalLink() {
      return !!this.href;
    },
    isRouterLink() {
      return !!this.to;
    },
    navItemStyle() {
      return 'text-gray-600 hover:text-gray-900 dark:text-gray-200 dark-hover:text-white font-medium';
    },
    target() {
      return this.newTab ? '_blank' : '_self';
    }
  }
};
</script>
