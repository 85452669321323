import { cancelInvitations, cancelTeamInvitations, deleteWorkspace, downgradeWorkspace, getTeam, getWorkspace, joinWorkspace, redirectToBillingDetails, redirectToPaymentMethod, removeTeamMember, removeWorkspaceMember, sendTeamInvitations, sendWorkspaceInvitations, updateTeam, updateWorkspace, updateWorkspaceAvatar as updateAvatar } from '@/data/workspaces';
import router from '@/router';
import { isWorkspace } from '@/types/workspaces';
import { FEATURE_FLAGS } from '@/constants';
// Not part of the store to avoid resetting it.
let wasOwnerCalledToSettings = false;
const isPricingEnabled = FEATURE_FLAGS.PRICING_ENABLED;
/**
 * Helper to check if an user is considered an owner inside a list of members.
 */
function isWorkspaceOwnerIn(userId, members) {
    return members.find((m) => m.id === userId)?.owner || false;
}
const module = {
    state: {
        loadingInvitations: new Set(),
        sentInvitations: new Set(),
        team: null,
        workspace: null
    },
    actions: {
        async clearWorkspacesScope({ commit }) {
            commit('resetState');
        },
        /**
         * Sends invitations for the loaded team scope.
         */
        async sendTeamInvitations({ commit, dispatch, getters }, params) {
            try {
                const team = await sendTeamInvitations({
                    ...params,
                    teamId: getters.team.id,
                    workspaceId: getters.workspace.id
                });
                const workspace = await getWorkspace(team.workspace.id);
                commit('setTeam', team);
                commit('setWorkspace', workspace);
            }
            catch (e) {
                dispatch('flashThenClear', { message: 'invitations_failure' });
            }
        },
        /**
         * Sends invitations for the loaded workspace scope.
         */
        async sendWorkspaceInvitations({ commit, dispatch, getters }, emails) {
            commit('addLoadingInvitations', emails);
            try {
                const workspaceId = getters.workspace.id;
                const workspace = await sendWorkspaceInvitations({ workspaceId, emails });
                await dispatch('refreshOwnedFreeWorkspacesCount');
                commit('addSentInvitations', emails);
                commit('setWorkspace', workspace);
            }
            catch (e) {
                dispatch('flashThenClear', { message: 'invitations_failure' });
            }
            commit('removeLoadingInvitations', emails);
        },
        async updateTeam({ commit, dispatch }, params) {
            try {
                const team = await updateTeam(params);
                commit('setTeam', team);
                commit('replaceTeamInWorkspaceTeams', team);
                if (params.archived) {
                    router.push({ name: 'showWorkspace', params: { workspaceId: params.workspaceId } });
                    dispatch('flashThenClear', { message: 'archived_team' });
                }
                else if (params.archived === false) {
                    dispatch('flashThenClear', { message: 'unarchived_team' });
                }
            }
            catch (e) {
                const errorMessage = e.code === 'name_taken' ? 'error_team_name_taken' : 'team_update_failure';
                dispatch('flashThenClear', { message: errorMessage });
            }
        },
        async joinWorkspace(_context, params) {
            try {
                const workspace = await joinWorkspace(params);
                router.push({ name: 'showWorkspace', params: { workspaceId: workspace.id } });
            }
            catch (e) {
                router.push({ name: '404' });
            }
        },
        /**
         * Loads the given workspace into the store assuming your are in a workspace
         * scope (for example, in the teams screen which appers after the workspace
         * in the url path).
         *
         * Caution: If the resource is not found, the user is redirected to the
         * workspace selector as a side effect.
         */
        async loadWorkspaceScope(context, workspaceId) {
            const sameWorkspace = context.getters.workspace?.id === workspaceId;
            const completeWorkspace = Array.isArray(context.getters.workspace?.teams);
            // Clear old and UNRELATED workspace data. Or incomplete data.
            if (!sameWorkspace || !completeWorkspace) {
                context.commit('setWorkspace', null);
            }
            try {
                const workspace = await getWorkspace(workspaceId);
                const isWorkspaceOwner = isWorkspaceOwnerIn(context.rootGetters.session.id, workspace.members);
                const routeName = router.currentRoute.value.name;
                if (workspace.isSuspended) {
                    if (isWorkspaceOwner && routeName === 'editWorkspace') {
                        wasOwnerCalledToSettings = true;
                    }
                    if (isWorkspaceOwner && !wasOwnerCalledToSettings) {
                        router.push({
                            name: 'editWorkspace',
                            params: { id: workspace.id }
                        });
                        return;
                    }
                    const routesAllowed = isWorkspaceOwner
                        ? ['showWorkspace', 'editWorkspace', 'changeWorkspacePlan']
                        : ['showWorkspace'];
                    const isCurrentRouteAllowed = routesAllowed.includes(routeName);
                    if (!isCurrentRouteAllowed) {
                        router.push({
                            name: 'showWorkspace',
                            params: { id: workspace.id }
                        });
                        return;
                    }
                }
                if (isPricingEnabled && !workspace.hasDecidedPricingPlan) {
                    const routesAllowed = isWorkspaceOwner
                        ? ['showWorkspace', 'changeWorkspacePlan', 'membersWorkspace']
                        : ['showWorkspace', 'membersWorkspace'];
                    const isCurrentRouteAllowed = routesAllowed.includes(routeName);
                    if (!isCurrentRouteAllowed) {
                        router.push({
                            name: 'showWorkspace',
                            params: { id: workspace.id }
                        });
                        return;
                    }
                }
                await context.dispatch('refreshOwnedFreeWorkspacesCount');
                context.commit('setWorkspace', workspace);
                context.dispatch('selectWorkspace', workspaceId);
            }
            catch (e) {
                router.push({ name: 'indexWorkspace' });
                context.dispatch('flashThenClear', { message: 'error_fetching_resource' });
            }
        },
        /**
         * Loads the given `team` from the `workspace` into the store assuming you
         * are in a scope like `workspaces/wId/teams/tId/*`.
         *
         * Minimal (bare) workspace info is also loaded into its corresponding
         * store.
         *
         * Caution: If the resource is not found, the user is redirected to the
         * workspace selector as a side effect. A team that exists but not for the
         * workspace may be considered missing by the backend.
         */
        async loadTeamAndWorkspaceScope(context, param) {
            // Clear old and UNRELATED team data.
            if (context.getters.team?.id !== param.teamId) {
                context.commit('setTeam', null);
            }
            try {
                const teamPromise = getTeam(param);
                const workspacePromise = param.loadFullWorkspace
                    ? getWorkspace(param.workspaceId)
                    : Promise.resolve(null);
                let workspace = await workspacePromise;
                if (isWorkspace(workspace) &&
                    isPricingEnabled &&
                    (workspace.isSuspended || !workspace.hasDecidedPricingPlan)) {
                    router.push({
                        name: 'showWorkspace',
                        params: { id: workspace.id }
                    });
                    return;
                }
                const team = await teamPromise;
                if (!workspace) {
                    workspace = team.workspace;
                }
                await context.dispatch('refreshOwnedFreeWorkspacesCount');
                context.commit('setWorkspace', workspace);
                context.commit('setTeam', team);
                context.dispatch('selectWorkspace', workspace.id);
            }
            catch (e) {
                if (e.code === 'team_is_archived') {
                    router.push({
                        name: 'showWorkspace',
                        params: { id: param.workspaceId }
                    });
                    context.dispatch('flashThenClear', { message: 'unauthorized_action' });
                }
                else {
                    router.push({ name: 'indexWorkspace' });
                    context.dispatch('flashThenClear', { message: 'error_fetching_resource' });
                }
            }
        },
        async removeWorkspaceMember({ commit, dispatch, getters, rootGetters }, user) {
            try {
                await removeWorkspaceMember({ userId: user.id, workspaceId: getters.workspace.id });
                commit('removeWorkspaceMember', user.id);
                if (user.id === rootGetters.session.id) {
                    router.push({
                        name: 'showWorkspace',
                        params: { id: getters.workspace.id }
                    });
                }
                else {
                    dispatch('flashThenClear', {
                        message: 'workspace_member_removed',
                        argument: { name: user.name }
                    });
                }
            }
            catch {
                dispatch('flashThenClear', { message: 'error_removing_member' });
            }
        },
        async removeTeamMember({ commit, dispatch, getters, rootGetters }, member) {
            try {
                await removeTeamMember({ userId: member.id, teamId: getters.team.id });
                commit('removeTeamMember', member.id);
                if (member.id === rootGetters.session.id) {
                    router.push({
                        name: 'showWorkspace',
                        params: { id: getters.workspace.id }
                    });
                    dispatch('flashThenClear', { message: 'left_the_team' });
                }
                else {
                    dispatch('flashThenClear', {
                        message: 'teammate_removed',
                        argument: { name: member.name }
                    });
                }
            }
            catch (e) {
                const errorMessage = e.code === 'last_teammate' ? 'last_teammate_error' : 'error_removing_member';
                dispatch('flashThenClear', { message: errorMessage });
            }
        },
        async cancelInvitations({ state, commit, dispatch }, emails) {
            try {
                await cancelInvitations({ workspaceId: state.workspace.id, emails });
                commit('removeInvitationsByEmails', { resource: 'workspace', emails });
            }
            catch {
                dispatch('flashThenClear', { message: 'error_canceling_invitation' });
            }
        },
        async cancelTeamInvitations({ state, commit, dispatch }, emails) {
            try {
                await cancelTeamInvitations({
                    workspaceId: state.workspace.id,
                    teamId: state.team.id,
                    emails
                });
                commit('removeInvitationsByEmails', { resource: 'team', emails });
                dispatch('flashThenClear', { message: 'team_invitation_removed' });
                const workspace = await getWorkspace(state.workspace.id);
                commit('setWorkspace', workspace);
            }
            catch {
                dispatch('flashThenClear', { message: 'error_canceling_invitation' });
            }
        },
        async updateWorkspaceAvatar({ commit, dispatch, state }, avatar) {
            const workspace = await updateAvatar(avatar, state.workspace.id);
            await dispatch('refreshOwnedFreeWorkspacesCount');
            commit('setWorkspace', workspace);
        },
        async updateWorkspace({ commit, dispatch }, params) {
            try {
                const workspace = await updateWorkspace(params);
                await dispatch('refreshOwnedFreeWorkspacesCount');
                commit('setWorkspace', workspace);
            }
            catch (e) {
                dispatch('flashThenClear', { message: 'update_workspace_failure' });
            }
        },
        async refreshWorkspace({ commit, dispatch, state }) {
            const workspace = await getWorkspace(state.workspace.id);
            await dispatch('refreshOwnedFreeWorkspacesCount');
            commit('setWorkspace', workspace);
        },
        async deleteWorkspace({ dispatch }) {
            const workspaceId = this.getters.workspace.id;
            try {
                await deleteWorkspace(workspaceId);
                router.push({ name: 'indexWorkspace' });
                dispatch('flashThenClear', { message: 'workspace_deleted' });
            }
            catch (e) {
                dispatch('flashThenClear', { message: 'error_deleting_workspace' });
            }
        },
        async changePlan({ commit, getters, dispatch }, { paymentPeriod, plan, selectedTeam }) {
            const { workspace } = getters;
            if (plan === 'free') {
                const updatedWorkspace = await downgradeWorkspace({
                    workspaceId: workspace.id,
                    selectedTeamId: selectedTeam?.id
                });
                commit('workspace', updatedWorkspace);
            }
            else {
                const membersEmails = new Set(workspace.members.map((m) => m.email));
                const invitationsEmails = new Set(workspace.invitations.map((i) => i.email));
                const seats = new Set([...membersEmails, ...invitationsEmails]).size;
                const updatedWorkspace = await updateWorkspace({
                    workspaceId: workspace.id,
                    plan,
                    seats,
                    paymentPeriod
                });
                commit('setWorkspace', updatedWorkspace);
            }
            await dispatch('refreshOwnedFreeWorkspacesCount');
        },
        async redirectToPaymentMethod({ getters }) {
            const { workspace } = getters;
            await redirectToPaymentMethod(workspace.id);
        },
        async redirectToBillingDetails({ getters }) {
            const { workspace } = getters;
            await redirectToBillingDetails(workspace.id);
        }
    },
    getters: {
        loadingInvitations: (state) => state.loadingInvitations,
        sentInvitations: (state) => state.sentInvitations,
        workspace: (state) => state.workspace,
        team: (state) => state.team,
        isWorkspaceOwner: (state, _getters, _rootState, rootGetters) => {
            const members = state.workspace && isWorkspace(state.workspace) ? state.workspace.members : [];
            return isWorkspaceOwnerIn(rootGetters.session.id, members);
        },
        isFreeWorkspace: (state) => {
            return (state.workspace && state.workspace.plan === 'free') || false;
        },
        invitedEmails: (state) => {
            const emails = state.workspace && isWorkspace(state.workspace)
                ? state.workspace.invitations.map((i) => i.email)
                : [];
            return Array.from(new Set(emails));
        },
        seatsTaken: (state, getters) => state.workspace && isWorkspace(state.workspace)
            ? state.workspace.members.length + getters.invitedEmails.length
            : 0,
        seatsAvailable: ({ workspace }, { seatsTaken }) => {
            const seats = workspace?.seats ?? seatsTaken;
            return seats - seatsTaken;
        },
        showPricingReleaseDashboard: (state) => isPricingEnabled && !!state.workspace && !state.workspace.hasDecidedPricingPlan
    },
    mutations: {
        resetState(state) {
            state.team = null;
            state.workspace = null;
        },
        removeTeamMember(state, userId) {
            state.team.members = state.team.members.filter((member) => member.id !== userId);
        },
        removeWorkspaceMember(state, memberId) {
            if (isWorkspace(state.workspace)) {
                state.workspace.members = state.workspace.members.filter((m) => m.id !== memberId);
            }
        },
        setWorkspace(state, workspace) {
            state.workspace = workspace;
        },
        setTeam(state, team) {
            state.team = team;
        },
        replaceTeamInWorkspaceTeams(state, team) {
            if (isWorkspace(state.workspace)) {
                state.workspace.teams = state.workspace.teams.filter((t) => t.id !== team.id);
                state.workspace.teams.push(team);
            }
        },
        removeInvitationsByEmails(state, { emails, resource }) {
            if (resource === 'workspace' && isWorkspace(state.workspace)) {
                const toRemove = new Set(emails);
                state.workspace.invitations = state.workspace.invitations.filter((i) => !toRemove.has(i.email));
            }
            if (resource === 'team' && state.team) {
                const toRemove = new Set(emails);
                state.team.invitations = state.team.invitations.filter((i) => !toRemove.has(i.email));
            }
        },
        addLoadingInvitations(state, emails) {
            emails.forEach((e) => state.loadingInvitations.add(e));
        },
        removeLoadingInvitations(state, emails) {
            emails.forEach((e) => state.loadingInvitations.delete(e));
        },
        addSentInvitations(state, emails) {
            emails.forEach((e) => state.sentInvitations.add(e));
        }
    }
};
export default module;
