import { defineComponent } from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import SvgIcon from '@/components/SvgIcon.vue';
import componentI18n from '@/i18n/componentI18n';
export default defineComponent({
    name: 'FlashMessages',
    components: {
        SvgIcon
    },
    ...componentI18n('components.flash_messages'),
    computed: {
        ...mapGetters(['flashMessages'])
    },
    methods: {
        ...mapMutations(['clearFlash']),
        handleDimiss(message) {
            this.clearFlash(message);
        }
    }
});
