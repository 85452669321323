/**
 * Module to work with data related to workspaces and teams.
 */
import { callApi, sendMultiPart } from '@/helpers/apiClient';
import { useNewTeamStore } from '@/pinia_stores/newTeam';
import { camel } from '@/helpers/strings';
import { store } from '@/store';
/**
 * Applies an exclusive transformation for workspace data.
 *
 * TODO: Cameling and snaking values could be implemented in the api client.
 */
function transformWorkspaceForFrontend(workspace) {
    return { ...workspace, plan: camel(workspace.plan) };
}
/**
 * Creates a new workspace.
 *
 * Resolves with that workspace or `null` if a redirect will happen.
 */
export async function createWorkspace(params) {
    const response = await callApi({
        method: 'post',
        route: '/workspaces',
        payload: params
    });
    if (response.redirectUrl) {
        const newTeamState = useNewTeamStore().$state;
        const newWorkspaceState = store.state.newWorkspace;
        const workspaceState = {
            workspaceId: response.workspace.id,
            newWorkspaceState,
            newTeamState
        };
        localStorage.setItem('lastWorkspaceCreationState', JSON.stringify(workspaceState));
        window.location.href = response.redirectUrl;
        return new Promise(() => {
            // Will never resolve.
        });
    }
    return transformWorkspaceForFrontend(response.workspace);
}
/**
 * Gets a workspace by id.
 */
export async function getWorkspace(workspaceId) {
    const response = await callApi({
        method: 'get',
        route: `/workspaces/${workspaceId}`
    });
    return transformWorkspaceForFrontend(response.workspace);
}
/**
 * Joins a workspace by id.
 *
 * Resolves with the workspace.
 */
export async function joinWorkspace(params) {
    const { invitationId, workspaceId } = params;
    const response = await callApi({
        method: 'post',
        route: `/workspaces/invitations/${invitationId}`,
        payload: { workspaceId }
    });
    return transformWorkspaceForFrontend(response.workspace);
}
/**
 * Deletes a workspace
 */
export async function deleteWorkspace(workspaceId) {
    await callApi({
        method: 'delete',
        route: `/workspaces/${workspaceId}`
    });
}
/**
 * Rejects an invitation.
 *
 * This will consume all invitations related to the same workspace.
 */
export async function rejectInvitation(invitationId) {
    await callApi({
        method: 'delete',
        route: `/workspaces/invitations/${invitationId}`
    });
}
/**
 * Sends workspace invitations.
 *
 * Can be used to resend invitations.
 */
export async function sendWorkspaceInvitations(params) {
    const { workspaceId, emails } = params;
    const response = await callApi({
        method: 'post',
        route: `/workspaces/${workspaceId}/invitations`,
        payload: { emails }
    });
    return transformWorkspaceForFrontend(response.workspace);
}
/**
 * Gets all the workspaces for the current user.
 */
export async function getWorkspaces() {
    const response = await callApi({
        method: 'get',
        route: '/workspaces'
    });
    return response.workspaces.map((w) => transformWorkspaceForFrontend(w));
}
/**
 * Selects a workspace as the last viewed by the user.
 *
 * Signals the server with which workspace was selected.
 *
 * Note: It's recommended to call this function without awaiting it.
 */
export async function selectWorkspace(workspaceId) {
    await callApi({
        method: 'post',
        route: '/workspaces/select',
        payload: { workspaceId }
    });
}
/**
 * Get a `Team` by team id and workspace id.
 *
 * Note: The workspace id is required for validation purposes.
 */
export async function getTeam(params) {
    const { workspaceId, teamId } = params;
    const response = await callApi({
        method: 'get',
        route: `/workspaces/${workspaceId}/teams/${teamId}`
    });
    return response.team;
}
/**
 * Creates a team.
 */
export async function createTeam(params) {
    const { emails, name, workspaceId, userIds } = params;
    const response = await callApi({
        route: `/workspaces/${workspaceId}/teams/new`,
        method: 'post',
        payload: { name, emails, userIds }
    });
    return response.team;
}
/**
 * Updates a team.
 */
export async function updateTeam(params) {
    const { name, maturity, teamId, workspaceId, archived } = params;
    const response = await callApi({
        route: `/workspaces/${workspaceId}/teams/${teamId}`,
        method: 'patch',
        payload: { name, maturity, archived }
    });
    return response.team;
}
/**
 * Invites users (not necessarily on the workspace) to the team.
 */
export async function sendTeamInvitations(params) {
    const { emails, teamId, userIds, workspaceId } = params;
    const response = await callApi({
        route: `/workspaces/${workspaceId}/teams/${teamId}/invitations`,
        method: 'post',
        payload: { emails, userIds }
    });
    return response.team;
}
/**
 * Removes a team member.
 */
export async function removeTeamMember(params) {
    const { userId, teamId } = params;
    await callApi({
        route: `/teams/${teamId}/members/${userId}`,
        method: 'delete'
    });
}
/**
 * Updates the workspace's avatar.
 */
export async function updateWorkspaceAvatar(avatar, workspaceId) {
    const response = await sendMultiPart({
        route: `/workspaces/${workspaceId}/avatar`,
        method: 'put',
        payload: { avatar }
    });
    return transformWorkspaceForFrontend(response.workspace);
}
/**
 * Updates the workspace.
 */
export async function updateWorkspace({ name, paymentPeriod, plan, seats, workspaceId }) {
    const response = await callApi({
        route: `/workspaces/${workspaceId}`,
        method: 'patch',
        payload: { name, paymentPeriod, plan, seats }
    });
    if (response.redirectUrl) {
        window.location.href = response.redirectUrl;
        return new Promise(() => {
            // Will never resolve.
        });
    }
    return transformWorkspaceForFrontend(response.workspace);
}
export async function redirectToPaymentMethod(workspaceId) {
    const response = await callApi({
        route: `/workspaces/${workspaceId}/payment_method`,
        method: 'get'
    });
    window.location.href = response.redirectUrl;
    return new Promise(() => {
        // Will never resolve.
    });
}
export async function redirectToBillingDetails(workspaceId) {
    const response = await callApi({
        route: `/workspaces/${workspaceId}/billing_details`,
        method: 'get'
    });
    window.location.href = response.redirectUrl;
    return new Promise(() => {
        // Will never resolve.
    });
}
/**
 * Performs the downgrade action for the workspace.
 */
export async function downgradeWorkspace({ selectedTeamId, workspaceId }) {
    const response = await callApi({
        route: `/workspaces/${workspaceId}/downgrade`,
        method: 'put',
        payload: { selectedTeamId }
    });
    return transformWorkspaceForFrontend(response.workspace);
}
/*
 * Removes a member from the workspace.
 */
export async function removeWorkspaceMember(params) {
    const { workspaceId, userId } = params;
    await callApi({
        method: 'delete',
        route: `/workspaces/${workspaceId}/members/${userId}`
    });
}
/**
 * Cancel all workspace invitations tied to specific emails.
 */
export async function cancelInvitations(params) {
    const { workspaceId, emails } = params;
    await callApi({
        method: 'post',
        route: `/workspaces/${workspaceId}/invitations/cancel`,
        payload: { emails }
    });
}
/**
 * Cancel all team invitations tied to specific emails.
 */
export async function cancelTeamInvitations(params) {
    const { workspaceId, teamId, emails } = params;
    await callApi({
        method: 'post',
        route: `/workspaces/${workspaceId}/teams/${teamId}/invitations/cancel`,
        payload: { emails }
    });
}
/**
 * Get workspace stats/metrics.
 */
export async function getWorkspaceStats(workspaceId) {
    const response = await callApi({
        method: 'get',
        route: `/workspaces/${workspaceId}/stats?months=3`
    });
    return response.stats;
}
