<template>
  <div class="radio-cards-grid">
    <label
      v-for="option in options"
      :key="option.value"
      class="relative flex w-full h-full p-2 overflow-hidden bg-white rounded-lg shadow-tile dark:bg-gray-800"
      :class="{
        'cursor-pointer items-center justify-center': option.value !== liveValue,
        'items-start justify-end': option.value === liveValue
      }"
      :style="{
        background: option.value === liveValue ? option.backgroundColor : null
      }"
    >
      <!-- input -->
      <input v-model="liveValue" :name="name" type="radio" class="hidden" :value="option.value" />
      <!-- thumbnail -->
      <div
        :class="{
          'mt-8 w-45 h-45': option.value !== liveValue,
          'w-20 h-20': option.value === liveValue
        }"
      >
        <slot name="thumbnail" v-bind="option" />
      </div>
      <!-- name -->
      <div
        class="absolute top-0 left-0 right-0 mt-10 ml-8 font-bold text-left"
        :class="{
          // TODO when active, color should be white for some of the background colors and black for others
          'text-primary-900 dark:text-gray-900': option.value === liveValue,
          'text-primary-900 dark:text-gray-300': option.value !== liveValue
        }"
      >
        <slot name="name" v-bind="option" />
      </div>
      <!-- details -->
      <div
        v-show="option.value === liveValue"
        class="absolute inset-0 px-8 pt-6 pb-2 mt-20 bg-white rounded-lg dark:bg-gray-800"
      >
        <div class="h-full overflow-auto text-left">
          <slot name="details" v-bind="option" />
        </div>
      </div>
      <!-- badge -->
      <slot name="badge" v-bind="option" />
      <!-- flag -->
      <slot name="flag" v-bind="option" />
    </label>
  </div>
</template>

<script>
export default {
  name: 'RadioCards',
  props: {
    options: { type: Array, required: true },
    name: { type: String, default: '' },
    value: { type: String, default: null }
  },
  computed: {
    liveValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  },
  watch: {
    options: {
      handler() {
        if (this.$refs.slider) {
          this.$refs.slider.slideTo(0);
        }
      },
      deep: true
    }
  }
};
</script>

<style lang="scss">
.radio-cards-grid {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 18rem);
  grid-auto-rows: 18rem;
  justify-items: center;
  align-items: center;
  grid-gap: 1.25rem;
}
</style>
