import componentMessages from './componentMessages';
import deepFind from '@/helpers/deepFind';
import activityMessages from './activityMessages';
export default function componentI18n(path) {
    return {
        i18n: {
            messages: {
                en: Object.assign(deepFind(componentMessages.en, path), {
                    ...activityMessages.en,
                    common: componentMessages.en.common,
                    errors: componentMessages.en.errors
                }),
                es: Object.assign(deepFind(componentMessages.es, path), {
                    ...activityMessages.es,
                    common: componentMessages.es.common,
                    errors: componentMessages.es.errors
                })
            }
        }
    };
}
