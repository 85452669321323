module.exports = {
  'transparent': 'transparent',
  'white': '#fff',
  'black': '#000',
  'gray': {
    100: '#f8f8f8', // side bar, disabled topic bg
    200: '#f1f1f1', // user list border, button border
    250: '#ACAAC3',
    300: '#d8d8d8',
    400: '#96939f',
    500: '#67676a',
    550: '#454a51',
    600: '#2b2a30',
    700: '#222529',
    800: '#1a1d21',
    850: '#19171d',
    900: '#121016',
    950: '#4e4a6e' // blue-gray for pricing page
  },
  'green': {
    300: '#e8f679',
    500: '#bee167'
  },
  'teal': {
    100: '#e0f6fa',
    500: '#67d1e1',
    600: '#1cb0ce'
  },
  'yellow': {
    100: '#f8e9b0',
    200: '#ffe1a1',
    // TODO: Use this as the yellow for topics after refactoring this file in another story.
    500: '#ffd220',
    600: '#ffb616'
  },
  'orange': {
    500: '#ffaf20',
    600: '#ff7645'
  },
  'purple': {
    500: '#904c6f'
  },
  'red': {
    500: '#e85c62'
  },
  'pink': {
    // TODO: Use this as the pink for topics after refactoring this file in another story.
    400: '#ffc4c7'
  },
  'topics': {
    blue: '#5985d2',
    teal: '#5cd3e0',
    red: '#ff724b',
    pink: '#ffc3c7',
    lime: '#c0e174',
    purple: '#924b6e',
    yellow: '#ffd143',
    orange: '#ffad3a'
  },
  'blue': {
    'link': '#0052d5',
    'light-link': '#4d91ff',
    'skyblue': '#6284D6',
    'dark': '#292b4b'
  },
  'primary': {
    100: '#EFF2FB',
    200: '#CFDAF2',
    300: '#9EB3E6',
    400: '#7D9BDE',
    500: '#5D81D6',
    600: '#3D68CD',
    700: '#2E56B3',
    800: '#213E82',
    900: '#192F61',
    950: '#312E52',
    1000: '#2C2E30'
  },
  'desaturated-primary': {
    700: '#3f5179'
  },
  'wyeworks': {
    black: '#0B0B0B'
  },
  'google': {
    blue: '#4285F4'
  }
};
