import cloneDeep from 'lodash.clonedeep';
/**
 * Plugin to reset function stores.
 *
 * - https://dev.to/the_one/pinia-how-to-reset-stores-created-with-functionsetup-syntax-1b74
 * - https://stackoverflow.com/questions/71690883/pinia-reset-alternative-when-using-setup-syntax
 */
export default function resetStore({ store }) {
    const initialState = cloneDeep(store.$state);
    store.$reset = () => store.$patch(cloneDeep(initialState));
}
