<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" v-if="type == 'awesome'" v-bind:svg-inline="''" v-bind:src="'@/assets/images/badges/motivation/awesome.svg'" v-bind:alt="'Awesome'"><g fill="none" fill-rule="evenodd"><path fill="#FFD220" stroke="#FFF" d="M3.438.5h11.124a2.5 2.5 0 012.498 2.422l.375 12a2.5 2.5 0 01-2.42 2.577l-.078.001H3.063a2.5 2.5 0 01-2.5-2.5l.002-.078.375-12A2.5 2.5 0 013.438.5z"/><g stroke="#00292B" stroke-linecap="round" stroke-width=".8"><path d="M5.267 5.8a.8.8 0 111.6 0h0m4.8 0a.8.8 0 111.6 0h0"/><path fill="#00292B" stroke-linejoin="round" d="M12.467 8.2a3.2 3.2 0 01-6.4 0h6.4z"/></g></g></svg>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" v-else-if="type == 'happy'" v-bind:svg-inline="''" v-bind:src="'@/assets/images/badges/motivation/happy.svg'" v-bind:alt="'Happy'"><g fill="none" fill-rule="evenodd"><path fill="#FFD220" stroke="#FFF" d="M3.438.5h11.124a2.5 2.5 0 012.498 2.422l.375 12a2.5 2.5 0 01-2.42 2.577l-.078.001H3.063a2.5 2.5 0 01-2.5-2.5l.002-.078.375-12A2.5 2.5 0 013.438.5z"/><g transform="translate(4 5)"><path stroke="#00292B" stroke-linecap="round" stroke-linejoin="round" stroke-width=".8" d="M7.633 4.267a3.197 3.197 0 01-4.766.001"/><circle cx="2.067" cy=".533" r="1" fill="#00292B"/><circle cx="8.467" cy=".533" r="1" fill="#00292B"/></g></g></svg>

  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" v-else-if="type == 'meh'" v-bind:svg-inline="''" v-bind:src="'@/assets/images/badges/motivation/meh.svg'" v-bind:alt="'Meh'"><g fill="none" fill-rule="evenodd"><path fill="#FFD220" stroke="#FFF" d="M3.438.5h11.124a2.5 2.5 0 012.498 2.422l.375 12a2.5 2.5 0 01-2.42 2.577l-.078.001H3.063a2.5 2.5 0 01-2.5-2.5l.002-.078.375-12A2.5 2.5 0 013.438.5z"/><g stroke="#00292B"><g fill="#00292B" stroke-width=".5" transform="translate(5.533 5)"><circle cx=".533" cy=".533" r="1"/><circle cx="6.933" cy=".533" r="1"/></g><path stroke-linecap="round" stroke-linejoin="round" stroke-width=".8" d="M5.667 10.2h1.066"/></g></g></svg>

  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" v-else-if="type == 'sad'" v-bind:svg-inline="''" v-bind:src="'@/assets/images/badges/motivation/sad.svg'" v-bind:alt="'Sad'"><g fill="none" fill-rule="evenodd"><path fill="#FFD220" stroke="#FFF" d="M3.438.5h11.124a2.5 2.5 0 012.498 2.422l.375 12a2.5 2.5 0 01-2.42 2.577l-.078.001H3.063a2.5 2.5 0 01-2.5-2.5l.002-.078.375-12A2.5 2.5 0 013.438.5z"/><g transform="translate(4 5)"><path stroke="#00292B" stroke-linecap="round" stroke-linejoin="round" stroke-width=".8" d="M6.929 5.067a3.182 3.182 0 00-3.529.001"/><circle cx="2.067" cy=".533" r="1" fill="#00292B"/><circle cx="8.467" cy=".533" r="1" fill="#00292B"/></g></g></svg>

  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" v-else-if="type == 'awful'" v-bind:svg-inline="''" v-bind:src="'@/assets/images/badges/motivation/awful.svg'" v-bind:alt="'Awful'"><g fill="none" fill-rule="evenodd"><path fill="#FFD220" stroke="#FFF" d="M3.438.5h11.124a2.5 2.5 0 012.498 2.422l.375 12a2.5 2.5 0 01-2.42 2.577l-.078.001H3.063a2.5 2.5 0 01-2.5-2.5l.002-.078.375-12A2.5 2.5 0 013.438.5z"/><g fill="#00292B" transform="translate(4 5)"><circle cx="2.067" cy=".533" r="1"/><circle cx="8.467" cy=".533" r="1"/><path stroke="#00292B" stroke-linecap="round" stroke-linejoin="round" stroke-width=".8" d="M7.133 5.6c0-1.414-.72-2.133-1.866-2.133-1.146 0-1.867.72-1.867 2.133.48-.427 1.103-.64 1.867-.64.763 0 1.386.213 1.866.64z"/></g></g></svg>
</template>

<script>
const badgeTypes = ['awesome', 'awful', 'happy', 'meh', 'sad'];

export default {
  name: 'UserBadge',
  props: {
    type: {
      type: String,
      default: 'awesome',
      validator(value) {
        return badgeTypes.includes(value);
      }
    }
  }
};
</script>
