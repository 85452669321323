/**
 * This module contains data access functions implemented in the old fashioned
 * way.
 *
 * DO NOT ADD MORE FUNCTIONS TO THIS FILE! New data access functions should
 * use the new api client and be in a module with a name related to the
 * feature or group of features. Check other files in the data folder as an
 * example.
 */
import { legacyCallApi } from '@/helpers/apiClient';
import { snakeKeys } from '@/helpers/objects';
import { HTTP_STATUS } from '@/constants';
export default {
    async createRetro(params) {
        return legacyCallApi({
            route: '/retro',
            params,
            handleData: ({ retroId }) => {
                return { success: true, id: retroId };
            },
            handleError: () => {
                return { success: false };
            }
        });
    },
    async getUser() {
        return legacyCallApi({
            method: 'get',
            route: '/user',
            handleData: ({ user }) => {
                return user;
            }
        });
    },
    async resendConfirmationEmail(email) {
        return legacyCallApi({
            method: 'put',
            route: '/users/send_confirmation_email',
            params: { email }
        });
    },
    async resetPasswordEmail(params) {
        return legacyCallApi({
            route: '/users/password',
            params
        });
    },
    async updatePassword(params) {
        return legacyCallApi({
            method: 'put',
            route: '/users/password',
            params: snakeKeys(params),
            handleData: () => {
                return { success: true };
            },
            handleError: (e) => {
                if (e.response.status === HTTP_STATUS.UNAUTHORIZED) {
                    return {
                        success: false,
                        message: 'The reset link has expired, please try generating another one'
                    };
                }
                else {
                    return { success: false, message: 'Error while updating the password, please try again' };
                }
            }
        });
    },
    async updateUser(params) {
        return legacyCallApi({
            method: 'patch',
            route: '/users/me',
            params: snakeKeys(params),
            handleData: (data) => {
                return { ...data, success: true };
            },
            handleError: (e) => ({ success: false, message: e.response?.data?.error })
        });
    },
    async updateLocale(params) {
        return legacyCallApi({
            method: 'patch',
            route: '/users/me/locale',
            params: snakeKeys(params)
        });
    },
    async getPublicRetroInfo(retroId) {
        return legacyCallApi({
            method: 'get',
            route: `/retro/${retroId}`,
            handleData: ({ retro }) => retro,
            handleError: () => null
        });
    },
    async getRetros({ offset }) {
        return legacyCallApi({
            method: 'get',
            route: `/retros?offset=${offset}`,
            handleData: (data) => data
        });
    }
};
