import Vue, { createApp } from 'vue';
import App from './App.vue';
import * as Sentry from '@sentry/vue';
import router from './router';
import '@/assets/css/tailwind.css';
import { createPinia } from 'pinia';
import { store } from '@/store/index';
import i18n from '@/i18n/index';
import VTooltip from 'v-tooltip';
import VueGtag from 'vue-gtag';
import VueClipboard from 'v-clipboard';
import { createMetaManager, plugin as metaPlugin } from 'vue-meta';
import VueScrollTo from 'vue-scrollto';
import VueClickOutside from 'click-outside-vue3';
import resetStore from './pinia_plugins/resetStore';
import debugStore from './pinia_plugins/debugStore';
import registerBaseComponents from '@/helpers/registerBaseComponents';
const app = createApp(App, {
    // TODO(UPGRADE) TEMPORAL WORKAROUND
    // @ts-ignore
    i18n
});
if (process.env.VUE_APP_DEPLOY_ENV === 'production' || process.env.VUE_APP_DEPLOY_ENV === 'dev') {
    Sentry.init({
        app,
        // @ts-ignore
        dsn: process.env.VUE_APP_SENTRY_DSN,
        environment: process.env.VUE_APP_DEPLOY_ENV
    });
}
const pinia = createPinia();
pinia.use(resetStore);
pinia.use(debugStore);
app.use(pinia);
app.use(store);
app.use(router);
app.use(i18n);
app.use(createMetaManager());
app.use(metaPlugin);
registerBaseComponents(app);
app.mount('#app');
Vue.use(VueGtag, { config: { id: process.env.VUE_APP_GOOGLE_TAG_TRACKING_ID } }, router);
app.use(VTooltip, {
    defaultOffset: 4,
    defaultDelay: 300
});
// TODO(UPGRADE) REMOVED: Vue.use(VueMeta);
app.use(VueScrollTo);
// TODO(UPGRADE): This shouldn't be added to the global app
// since we only use it in 2/3 places.
app.use(VueClipboard);
app.use(VueClickOutside);
const entries = performance.getEntriesByType('navigation');
entries.forEach((entry) => {
    // @ts-expect-error
    if (entry.type === 'back_forward') {
        window.location.reload();
    }
});
