import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Layout from '@/views/Layout.vue';
import constants from '@/constants';
import transitions from '@/helpers/transitions';
export default defineComponent({
    name: 'App',
    components: {
        Layout
    },
    data() {
        return {
            hasMountedHookRun: true
        };
    },
    computed: {
        ...mapGetters(['isDarkMode', 'locale', 'session']),
        loading() {
            return this.hasMountedHookRun || !this.session.initialized;
        }
    },
    watch: {
        isDarkMode(isDark) {
            if (isDark) {
                document.querySelector('html').classList.add('mode-dark');
            }
            else {
                document.querySelector('html').classList.remove('mode-dark');
            }
        },
        locale(locale) {
            this.$root.$i18n.locale = locale;
        }
    },
    async mounted() {
        this.loadPreferences();
        this.loadGoogleApi();
        this.hasMountedHookRun = false;
    },
    methods: {
        ...mapActions(['loadPreferences']),
        ...transitions.fade,
        async loadGoogleApi() {
            gapi.load('auth2', () => {
                gapi.auth2.init({ client_id: constants.GOOGLE_CLIENT_ID });
            });
        },
        /* Needed to avoid graphical glitches when changing screens, see `useFullWidth` */
        unsetFullWidth() {
            this.$store.commit('setFullWidth', false);
        }
    }
});
