/**
 * Typeguard for checking if an object is a valid User.
 *
 * Note: This checks for the User interface, not the BaseUser interface.
 */
export function isUser(obj) {
    return obj.id && obj.name && obj.email;
}
/**
 * Typeguard for checking if an object is a valid UnknownUser.
 */
export function isUnknownUser(obj) {
    return !obj.id && obj.email;
}
