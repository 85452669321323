<template>
  <div class="flex flex-col items-center mb-10">
    <div id="google-signin-button"></div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'GoogleSignIn',
  mounted() {
    gapi.load('auth2', () => {
      gapi.signin2.render('google-signin-button', {
        onsuccess: this.googleSignIn,
        onfailure: this.onError,
        longtitle: true,
        theme: this.isDarkMode ? 'dark' : 'light',
        height: 60,
        width: 320
      });
    });
  },
  methods: {
    ...mapActions(['signInGoogleUser']),
    async googleSignIn(signIn) {
      signIn.disconnect();
      const googleToken = signIn.getAuthResponse().id_token;
      await this.signInGoogleUser({ googleToken });
      this.$emit('loginSuccess');
    },
    onError(error) {
      throw error;
    }
  }
};
</script>

<style lang="scss">
#google-signin-button > div {
  @apply rounded;
  @apply border;
  @apply dark:border-0;
  @apply border-google-blue;
  @apply shadow-none;
  @apply text-google-blue;
  @apply bg-white;
  @apply dark:bg-gray-200;

  span {
    font-weight: bold;
  }
}
</style>
