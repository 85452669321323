/* eslint-disable max-lines */
import legacyData from '@/data/legacy';
import moment from 'moment';
import roundHourUp from '@/helpers/roundHourUp';
import router from '@/router';
import snake from 'snakecase-keys';
import { event } from 'vue-gtag';
import constants from '@/constants';
import { isUnknownUser, isUser } from '@/types/user';
import { isInvitation } from '@/types/workspaces';
const CALL_LINK_MAX_LENGTH = 255;
const RETRO_NAME_MAX_LENGTH = 50;
// round up to the X+2 hour instead of X+1 if it's X:55 or more.
const ROUND_HOUR_THRESHOLD = 5;
const STEPS = ['participants', 'time', 'activity', 'advanced'];
function currentTime() {
    const currentTime = moment().add(ROUND_HOUR_THRESHOLD, 'minutes');
    return roundHourUp(currentTime);
}
function defaultDate() {
    return currentTime().format('YYYY-MM-DD');
}
function defaultTime() {
    return currentTime().format('HH:mm');
}
function defaultState() {
    const retroDate = defaultDate();
    const retroName = `Retro ${retroDate}`;
    return {
        callLink: '',
        currentStepIndex: 0,
        duration: 60,
        invitedUsers: [],
        maxTopics: null,
        participantIds: new Set(),
        requirePermanentAccounts: false,
        retroDate: retroDate,
        retroName: retroName,
        retroPostingDuration: null,
        retroTime: defaultTime(),
        retroWritingDuration: null,
        selectedActivity: null,
        teamId: null,
        useWaitingRoom: true,
        votingMechanic: 'one_and_two'
    };
}
function invitedEmails(invitedUsers) {
    return invitedUsers
        .filter((u) => isUnknownUser(u) || isInvitation(u))
        .map((u) => u.email);
}
function invitedUsers(invitedUsers) {
    return invitedUsers.filter((u) => isUser(u)).map((u) => u.id);
}
function prepareRetroParams(state, sessionId, slots) {
    const date = moment(state.retroDate + 'T' + state.retroTime).format();
    const callLink = !!state.callLink && !state.callLink.startsWith('https://')
        ? `https://${state.callLink}`
        : state.callLink;
    return snake({
        activity: state.selectedActivity,
        callLink,
        date,
        duration: state.duration,
        invitedEmails: invitedEmails(state.invitedUsers),
        invitedUsers: invitedUsers(state.invitedUsers),
        maxTopics: state.maxTopics,
        name: state.retroName,
        participantIds: Array.from(state.participantIds),
        participantSlots: slots,
        postingDuration: state.retroPostingDuration,
        requiresPermanentAccount: state.requirePermanentAccounts,
        teamId: state.teamId,
        votingMechanic: state.votingMechanic,
        waitingRoomEnabled: state.useWaitingRoom,
        writingDuration: state.retroWritingDuration
    });
}
const advanceStepGetters = {
    isAdvanceTimeStepDisabled: (_state, getters) => {
        return (getters.currentStep === 'time' &&
            (getters.isBehindSchedule || getters.invalidTime || getters.invalidDuration));
    },
    isAdvanceActivityStepDisabled: (state, getters) => {
        return getters.currentStep === 'activity' && !state.selectedActivity;
    },
    isAdvanceAdvancedStepDisabled: (state, getters) => {
        return (getters.currentStep === 'advanced' &&
            (!state.retroWritingDuration ||
                !state.retroPostingDuration ||
                getters.isDurationLongEnough ||
                getters.isRetroNameEmpty ||
                getters.isRetroNameTooLong ||
                getters.isCallLinkTooLong ||
                getters.hasCallLinkWhiteSpaces ||
                getters.isCallLinkInvalidUrl ||
                !getters.isMaxTopicsValid));
    },
    isParticipantsStepDisabled: (state) => {
        return state.teamId !== null && state.participantIds.size < constants.MIN_PARTICIPANTS;
    },
    isNextStepDisabled: (_state, getters) => {
        return (getters.isAdvanceTimeStepDisabled ||
            getters.isAdvanceActivityStepDisabled ||
            getters.isAdvanceAdvancedStepDisabled ||
            getters.isParticipantsStepDisabled);
    },
    nextStepDisabledReason: (state, getters) => {
        if (getters.invalidTime) {
            return 'time.empty_time';
        }
        else if (getters.isBehindSchedule) {
            return 'time.past_retro';
        }
        else if (getters.isAdvanceTimeStepDisabled) {
            return 'time.retro_duration.invalid_duration';
        }
        else if (getters.isAdvanceActivityStepDisabled) {
            return 'activity.required';
        }
        else if (!state.retroWritingDuration) {
            return 'advanced.timing.writing_duration_required';
        }
        else if (getters.isParticipantsStepDisabled) {
            return 'participants_picker.not_enough';
        }
        else if (!state.retroPostingDuration) {
            return 'advanced.timing.posting_duration_required';
        }
        else if (getters.isRetroNameEmpty) {
            return 'errors.name.empty';
        }
        else if (getters.isDurationLongEnough) {
            return 'advanced.timing.values_too_long';
        }
        else if (getters.isRetroNameTooLong) {
            return 'errors.name.too_long';
        }
        else if (getters.isCallLinkTooLong) {
            return 'advanced.call_link.too_long';
        }
        else if (getters.isCallLinkInvalidUrl) {
            return 'advanced.call_link.invalid_url';
        }
        else if (getters.hasCallLinkWhiteSpaces) {
            return 'advanced.call_link.white_spaces';
        }
        else {
            return null;
        }
    }
};
async function trackRetroEvents(state, getters) {
    event('created', {
        event_category: 'retro_creation'
    });
    event('selected_activity', {
        event_category: 'retro_creation',
        event_label: state.selectedActivity
    });
    if (!getters.moderatorParticipates) {
        event('moderator_does_not_post', {
            event_category: 'retro_creation'
        });
    }
}
const module = {
    state: defaultState(),
    actions: {
        async createRetro({ dispatch, rootGetters, state, getters }) {
            const { session, team, workspace } = rootGetters;
            const isTeamRetro = !!state.teamId;
            const slots = state.participantIds.size;
            const params = prepareRetroParams(state, session.id, slots);
            const { success, id } = await legacyData.createRetro(params);
            if (success) {
                trackRetroEvents(state, getters);
                if (isTeamRetro) {
                    router.push({
                        name: 'showTeamRetro',
                        params: { workspaceId: workspace.id, teamId: team.id, retroId: id }
                    });
                }
                else {
                    router.push({ name: 'showRetro', params: { retroId: id } });
                }
            }
            else {
                dispatch('flashThenClear', { message: 'error_creating_retro' });
            }
        },
        /**
         * Adds or removes the current user from the participants set.
         *
         * This function exists just for compatibility with older code.
         */
        updateModeratorParticipation: ({ commit, rootGetters }, participates) => {
            const currentId = rootGetters.session.id;
            if (participates) {
                commit('addParticipantId', currentId);
            }
            else {
                commit('removeParticipantId', currentId);
            }
        }
    },
    getters: {
        ...advanceStepGetters,
        currentStep: (state) => {
            return STEPS[state.currentStepIndex];
        },
        hasCallLinkWhiteSpaces: (state) => {
            return state.callLink.includes(' ');
        },
        isRetroNameEmpty: (state) => {
            return state.retroName === '';
        },
        isCallLinkInvalidUrl: (state) => {
            return state.callLink && !state.callLink.match(/^(https:\/\/)?(?![http://])\S+\.\S+$/);
        },
        isDurationLongEnough: (state) => {
            return state.retroPostingDuration + state.retroWritingDuration >= state.duration;
        },
        isCallLinkTooLong: (state) => {
            return state.callLink.length > CALL_LINK_MAX_LENGTH;
        },
        isFirstStep: (state) => {
            return state.currentStepIndex === 0;
        },
        isBehindSchedule: (state) => {
            return moment(`${state.retroDate} ${state.retroTime}`, 'YYYY-MM-DD HH:mm').isBefore(moment());
        },
        invalidTime: (state) => {
            return !moment(`${state.retroTime}`, 'HH:mm').isValid();
        },
        invalidDuration: (state) => {
            return state.duration <= 0;
        },
        isActivityStep: (state) => {
            return STEPS[state.currentStepIndex] === 'activity';
        },
        isLastStep: (state) => {
            return state.currentStepIndex === STEPS.length - 1;
        },
        isRetroNameTooLong: (state) => {
            return state.retroName.length > RETRO_NAME_MAX_LENGTH;
        },
        isMaxTopicsValid(state) {
            return state.maxTopics === null || state.maxTopics > 0;
        },
        moderatorParticipates: (state, _getters, _rootState, rootGetters) => {
            return state.participantIds.has(rootGetters.session.id);
        },
        participantSlots: (state) => {
            return (state.participantIds.size +
                invitedEmails(state.invitedUsers).length +
                invitedUsers(state.invitedUsers).length);
        },
        pendingParticipants: (state) => {
            const externalUsers = invitedEmails(state.invitedUsers.filter((i) => !('id' in i)));
            return [...new Set(externalUsers)].map((email) => ({ email: email }));
        },
        stepNumber: (state) => {
            return state.currentStepIndex + 1;
        },
        steps: () => {
            return STEPS;
        }
    },
    mutations: {
        addParticipantId: (state, id) => {
            state.participantIds.add(id);
        },
        decrementStep: (state) => {
            if (state.currentStepIndex > 0) {
                state.currentStepIndex--;
            }
        },
        incrementStep: (state) => {
            state.currentStepIndex++;
        },
        removeParticipantId: (state, id) => {
            state.participantIds.delete(id);
        },
        resetState: (state) => {
            Object.assign(state, defaultState());
        },
        selectActivity: (state, activity) => {
            state.selectedActivity = activity;
        },
        updateDate: (state, newDate) => {
            state.retroDate = newDate;
        },
        updateTime: (state, newTime) => {
            state.retroTime = newTime;
        },
        updateDuration: (state, duration) => {
            state.duration = duration;
        },
        updateMaxTopics: (state, maxTopics) => {
            state.maxTopics = maxTopics;
        },
        updateRetroName: (state, name) => {
            state.retroName = name;
        },
        updateRetroPostingDuration: (state, duration) => {
            state.retroPostingDuration = duration;
        },
        updateTeamId: (state, id) => {
            state.teamId = id;
        },
        updateWaitingRoomUsage: (state, usage) => {
            state.useWaitingRoom = usage;
        },
        updatePermanentAccountsRequirement: (state, required) => {
            state.requirePermanentAccounts = required;
        },
        updateCallLink: (state, callLink) => {
            state.callLink = callLink;
        },
        updateVotingMechanic: (state, mechanic) => {
            state.votingMechanic = mechanic;
        },
        updateRetroWritingDuration: (state, duration) => {
            state.retroWritingDuration = duration;
        },
        updateInvitedUsers: (state, users) => {
            state.invitedUsers = users;
        }
    }
};
export default module;
