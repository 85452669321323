import { defineStore } from 'pinia';
import { isValidEmail } from '@/helpers/emails';
import { createTeam } from '@/data/workspaces';
import router from '@/router';
import { store } from '@/store/index';
import { TEAM_CONSTANTS } from '@/constants/index';
const STEP_NAMES = ['teamName', 'invites', 'buySeats'];
function splitUsers(users) {
    const existingUsers = [];
    const newUsers = [];
    users.forEach((user) => {
        'id' in user ? existingUsers.push(user) : newUsers.push(user);
    });
    return { existingUsers, newUsers };
}
export const useNewTeamStore = defineStore('newTeam', {
    state: () => ({
        invitesSearchTerm: '',
        seatsToBuy: 0,
        step: 1,
        teamName: '',
        users: []
    }),
    getters: {
        advanceDisabledReason() {
            if (this.teamName.trim() === '') {
                return 'errors.name.empty';
            }
            else if (!this.nameHasValidLength) {
                return 'errors.name.too_long';
            }
            else {
                return null;
            }
        },
        areAllEmailsValid() {
            const emails = this.users.map((u) => u.email);
            if (this.trimmedInvitesSearchTerm.length > 0) {
                emails.push(this.trimmedInvitesSearchTerm);
            }
            return emails.every((e) => isValidEmail(e));
        },
        canAdvanceStep() {
            return this.advanceDisabledReason === null;
        },
        canCreateTeam() {
            return this.stepName === 'invites' && this.areAllEmailsValid;
        },
        nameHasValidLength() {
            return this.teamName.length <= TEAM_CONSTANTS.MAX_NAME_LENGTH;
        },
        stepName() {
            return STEP_NAMES[this.step - 1];
        },
        trimmedInvitesSearchTerm() {
            return this.invitesSearchTerm.trim();
        }
    },
    actions: {
        async createTeam(workspaceId) {
            const { newUsers, existingUsers } = splitUsers(this.users);
            if (this.trimmedInvitesSearchTerm.length > 0) {
                newUsers.push({ email: this.trimmedInvitesSearchTerm });
            }
            const params = {
                name: this.teamName,
                emails: newUsers.map((user) => user.email),
                userIds: existingUsers.map((user) => user.id),
                workspaceId
            };
            try {
                const team = await createTeam(params);
                router.push({ name: 'showTeam', params: { teamId: team.id, workspaceId } });
            }
            catch (e) {
                const errorMessage = e.code === 'name_taken' ? 'error_team_name_taken' : 'error_creating_team';
                store.dispatch('flashThenClear', { message: errorMessage }, { root: true });
            }
        },
        decrementStep() {
            if (this.step > 1) {
                this.step--;
            }
        },
        incrementStep() {
            if (this.step < STEP_NAMES.length) {
                this.step++;
            }
        },
        reset() {
            this.$reset();
        }
    }
});
