import { createRouter, createWebHistory } from 'vue-router';

import { FEATURE_FLAGS } from '@/constants';
import { store } from '@/store';

const viewComponents = {
  aboutUs: () => import('./views/about_us/AboutUsPage.vue'),
  archivedTeams: () => import('./views/workspaces/ArchivedTeams.vue'),
  checkYourInbox: () => import('./views/auth/CheckYourInbox.vue'),
  completeAccount: () => import('./views/auth/CompleteAccount.vue'),
  confirmationExpired: () => import('./views/auth/ConfirmationExpired.vue'),
  createWorkspace: () => import('./views/workspaces/New.vue'),
  error404: () => import('./views/error/404.vue'),
  home: () => import('./views/Home.vue'),
  joinRetroError: () => import('./views/error/JoinRetroError.vue'),
  kickedOutOfRetroError: () => import('./views/error/KickedOutOfRetroError.vue'),
  licenseSubscription: () => import('./views/legal/LicenseSubscription.vue'),
  login: () => import('./views/auth/Login.vue'),
  newRetro: () => import('./views/retro/New.vue'),
  patternLibrary: () => import('./views/dev/PatternLibrary.vue'),
  pricing: () => import('./views/pricing/PricingPage.vue'),
  privacyPolicy: () => import('./views/legal/PrivacyPolicy.vue'),
  resetEmail: () => import('./views/auth/ResetEmail.vue'),
  resetPassword: () => import('./views/auth/ResetPassword.vue'),
  retrosIndex: () => import('./views/retro/Index.vue'),
  showRetro: () => import('./views/retro/Show.vue'),
  signUp: () => import('./views/auth/SignUp.vue'),
  teamEdit: () => import('./views/teams/Edit.vue'),
  teamNew: () => import('./views/teams/New.vue'),
  teamShow: () => import('./views/teams/Show.vue'),
  userPassword: () => import('./views/profile/UserPassword.vue'),
  userProfile: () => import('./views/profile/UserProfile.vue'),
  verifyEmail: () => import('./views/auth/VerifyEmail.vue'),
  workspaceChangePlan: () => import('./views/workspaces/ChangePlan.vue'),
  workspaceCreate: () => import('./views/workspaces/New.vue'),
  workspaceDelete: () => import('./views/workspaces/Delete.vue'),
  workspaceEdit: () => import('./views/workspaces/Edit.vue'),
  workspaceIndex: () => import('./views/workspaces/Index.vue'),
  workspaceJoin: () => import('./views/workspaces/Join.vue'),
  workspaceMembers: () => import('./views/workspaces/Members.vue'),
  workspaceSeats: () => import('./views/workspaces/Seats.vue'),
  workspaceShow: () => import('./views/workspaces/Show.vue')
};

const patternLibrary = [
  {
    path: '/patterns',
    name: 'patterns',
    component: viewComponents.patternLibrary
  }
];

/**
 * Fetch the user's data if they are logged in.
 *
 * This ensures that user data will always be available in the store from the get go.
 */
const fetchUserInfo = async () => {
  if (!store.state.session.initialized) {
    await store.dispatch('initSession');
  }
};

const unverifiedAllowedRoutes = ['checkYourInbox', 'signUpUser'];

/**
 * Ensures that the user is logged in before going to the route.
 *
 * The user is redirected to the login (or verification) if necessary.
 */
const ensureLoggedIn = (to) => {
  if (!store.getters.loggedIn) {
    return { name: 'login', params: { redirect: to.fullPath } };
  } else if (store.getters.loggedIn && store.getters.session.temporary) {
    return { name: 'completeAccount' };
  } else if (
    store.getters.loggedIn &&
    !store.getters.session.verified &&
    !unverifiedAllowedRoutes.includes(to.name)
  ) {
    return { name: 'checkYourInbox' };
  }
};

/**
 * Ensures the pricing flag is enabled begore going to the route.
 *
 * Redirects to `home` on failure.
 */
const ensurePricingFlag = () => {
  if (!FEATURE_FLAGS.PRICING_ENABLED) {
    return { name: 'home' };
  }
};

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: function (to) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { left: 0, top: 0 };
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: viewComponents.home
    },
    {
      path: '/pricing',
      name: 'pricing',
      component: viewComponents.pricing
    },
    {
      path: '/about_us',
      name: 'aboutUs',
      component: viewComponents.aboutUs
    },
    {
      path: '/auth/confirmation_expired',
      name: 'confirmationExpired',
      component: viewComponents.confirmationExpired
    },
    {
      path: '/auth/login',
      name: 'login',
      component: viewComponents.login,
      props: true
    },
    {
      path: '/auth/password',
      name: 'resetPassword',
      component: viewComponents.resetPassword
    },
    {
      path: '/auth/reset',
      name: 'resetPasswordEmail',
      component: viewComponents.resetEmail
    },
    {
      path: '/auth/sign_up',
      name: 'signUpUser',
      component: viewComponents.signUp,
      props: true
    },
    {
      path: '/error/joining_retro',
      name: 'joinRetroError',
      component: viewComponents.joinRetroError
    },
    {
      path: '/error/kicked_out_of_retro',
      name: 'kickedOutOfRetroError',
      component: viewComponents.kickedOutOfRetroError
    },
    {
      path: '/auth/complete_account',
      name: 'completeAccount',
      component: viewComponents.completeAccount,
      beforeEnter: [ensureLoggedIn]
    },
    {
      path: '/auth/check_your_inbox',
      name: 'checkYourInbox',
      component: viewComponents.checkYourInbox,
      beforeEnter: [ensureLoggedIn]
    },
    {
      path: '/auth/verify_email',
      name: 'verifyEmail',
      component: viewComponents.verifyEmail
    },
    {
      path: '/profile',
      name: 'profile',
      component: viewComponents.userProfile,
      beforeEnter: [ensureLoggedIn]
    },
    {
      path: '/profile/password',
      name: 'password_change',
      component: viewComponents.userPassword,
      beforeEnter: [ensureLoggedIn]
    },
    {
      path: '/legal/license_subscription',
      name: 'licenseSubscription',
      component: viewComponents.licenseSubscription
    },
    {
      path: '/legal/privacy_policy',
      name: 'privacyPolicy',
      component: viewComponents.privacyPolicy
    },
    {
      path: '/retros',
      name: 'retros',
      component: viewComponents.retrosIndex,
      beforeEnter: ensureLoggedIn
    },
    {
      path: '/retro/:retroId',
      name: 'showRetro',
      component: viewComponents.showRetro,
      beforeEnter: [ensureLoggedIn]
    },
    {
      path: '/workspaces/:workspaceId/edit/plan',
      name: 'changeWorkspacePlan',
      component: viewComponents.workspaceChangePlan,
      beforeEnter: [ensureLoggedIn, ensurePricingFlag]
    },
    {
      path: '/workspaces/:workspaceId/edit/seats',
      name: 'editWorkspaceSeats',
      component: viewComponents.workspaceSeats,
      beforeEnter: [ensureLoggedIn, ensurePricingFlag]
    },
    {
      path: '/workspaces/new',
      name: 'createWorkspace',
      component: viewComponents.workspaceCreate,
      beforeEnter: [ensureLoggedIn]
    },
    {
      path: '/workspaces/:workspaceId/edit',
      name: 'editWorkspace',
      component: viewComponents.workspaceEdit
    },
    {
      path: '/workspaces/:workspaceId/edit/delete',
      name: 'deleteWorkspace',
      component: viewComponents.workspaceDelete
    },
    {
      path: '/workspaces/',
      name: 'indexWorkspace',
      component: viewComponents.workspaceIndex,
      beforeEnter: [ensureLoggedIn]
    },
    {
      path: '/workspaces/join',
      name: 'joinWorkspace',
      component: viewComponents.workspaceJoin,
      beforeEnter: [ensureLoggedIn]
    },
    {
      path: '/workspaces/:workspaceId',
      name: 'showWorkspace',
      component: viewComponents.workspaceShow,
      beforeEnter: [ensureLoggedIn]
    },
    {
      path: '/workspaces/:workspaceId/users',
      name: 'membersWorkspace',
      component: viewComponents.workspaceMembers,
      beforeEnter: [ensureLoggedIn]
    },
    {
      path: '/workspaces/:workspaceId/teams/new',
      name: 'newTeam',
      component: viewComponents.teamNew,
      // TODO: Should only be accesible if user belongs to team
      beforeEnter: [ensureLoggedIn]
    },
    {
      path: '/workspaces/:workspaceId/teams/:teamId/edit',
      name: 'editTeam',
      component: viewComponents.teamEdit,
      // TODO: Should only be accesible if user belongs to team
      beforeEnter: [ensureLoggedIn]
    },
    {
      path: '/workspaces/:workspaceId/teams/:teamId',
      name: 'showTeam',
      component: viewComponents.teamShow,
      beforeEnter: [ensureLoggedIn]
    },
    {
      path: '/workspaces/:workspaceId/teams/:teamId/retros/:retroId',
      name: 'showTeamRetro',
      component: viewComponents.showRetro
    },
    {
      path: '/workspaces/:workspaceId/teams/:teamId/retros/new',
      name: 'newTeamRetro',
      component: viewComponents.newRetro
    },
    ...(process.env.NODE_ENV !== 'production' ? patternLibrary : []),
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: viewComponents.error404
    },
    {
      path: '/workspaces/:workspaceId/archived_teams',
      name: 'archivedTeams',
      component: viewComponents.archivedTeams,
      beforeEnter: [ensureLoggedIn]
    }
  ]
});

router.beforeEach(fetchUserInfo);
router.beforeEach((to) => {
  if (to.name !== 'createWorkspace') {
    localStorage.removeItem('lastWorkspaceCreationState');
  }
});

export default router;
