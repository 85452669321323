<template>
  <nav class="px-5 py-2 print:hidden text-white text-lg">
    <ul class="flex items-center text-center">
      <nav-item :active="$route.name === 'pricing'" to="/pricing">
        {{ $t('pricing') }}
      </nav-item>
      <nav-item :active="$route.name === 'aboutUs'" to="/about_us">
        {{ $t('about_us') }}
      </nav-item>
      <nav-item href="https://www.linkedin.com/company/retroally/posts/?feedView=articles" new-tab>
        {{ $t('blog') }}
      </nav-item>
    </ul>
  </nav>
</template>

<script>
import componentI18n from '@/i18n/componentI18n';
import NavItem from '@/components/header/SiteNav/NavItem';

export default {
  name: 'SecondarySiteNav',
  components: {
    NavItem
  },
  ...componentI18n('components.nav')
};
</script>
