<template>
  <div>
    <site-header />
    <main class="min-h-screen px-5 sm:px-0" :class="{ 'max-w-site-max-w mx-auto': !fullWidth }">
      <flash-messages />
      <slot />
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SiteHeader from '@/components/header/SiteHeader';
import FlashMessages from '@/components/FlashMessages';

export default {
  name: 'Layout',
  components: {
    FlashMessages,
    SiteHeader
  },
  computed: {
    ...mapGetters(['fullWidth'])
  },
  metaInfo() {
    const description =
      'Your ally to make retros effective, engage in fruitful discussions and improve in the next iteration.';
    return {
      meta: [
        {
          vmid: 'og:description',
          property: 'og:description',
          content: description
        },
        {
          vmid: 'twitter:description',
          property: 'twitter:description',
          content: description
        },
        { vmid: 'description', name: 'description', content: description }
      ]
    };
  }
};
</script>
