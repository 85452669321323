<template>
  <a
    :href="externalLink"
    target="_blank"
    rel="noopener noreferrer"
    class="text-blue-link dark:text-blue-light-link"
  >
    {{ text }}
  </a>
</template>

<script>
export default {
  name: 'ExternalLink',
  props: {
    href: { type: String, default: '#' },
    text: { type: String, default: '' }
  },
  computed: {
    externalLink() {
      if (this.link.startsWith('http') || this.link.startsWith('mailto')) {
        return this.href;
      }

      return `//${this.href}`;
    },
    link() {
      return this.href.toLowerCase();
    }
  }
};
</script>
