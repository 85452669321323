import { defineComponent } from 'vue';
import SvgIcon from '@/components/SvgIcon.vue';
import componentI18n from '@/i18n/componentI18n';
const LENGTH_REMINDER = 11;
export default defineComponent({
    name: 'TextField',
    components: {
        SvgIcon
    },
    ...componentI18n('components.base.text_field'),
    compatConfig: { COMPONENT_V_MODEL: false },
    props: {
        autocomplete: { type: String, default: '' },
        bottomHint: { type: String, default: '' },
        disabled: { type: Boolean, default: false },
        error: { type: String, default: '' },
        errorBorder: { type: Boolean, default: false },
        hideLabel: { type: Boolean, default: false },
        label: { type: String, required: true },
        labelTooltip: { type: String, default: null },
        modelValue: { type: String, required: true },
        pattern: { type: String, default: '.*' },
        placeholder: { type: String, default: '' },
        required: { type: Boolean, default: false },
        type: { type: String, default: 'text' },
        //VARCHAR limit is 255
        limit: { type: Number, default: 255 }
    },
    data() {
        return {
            liveValue: this.modelValue
        };
    },
    computed: {
        charactersRemaining() {
            return this.limit - ((this.liveValue && this.liveValue.length) || 0);
        },
        showCharactersRemaining() {
            return LENGTH_REMINDER > this.charactersRemaining;
        }
    },
    watch: {
        liveValue() {
            this.$emit('update:modelValue', this.liveValue);
        },
        modelValue(newValue) {
            this.liveValue = newValue;
        }
    },
    methods: {
        enterPressed() {
            this.$refs.input.blur();
            this.$emit('enter');
        }
    }
});
