<template>
  <label class="block">
    <div class="mb-1 text-xs text-primary-700 dark:text-gray-300" :class="{ 'sr-only': hideLabel }">
      {{ label }}
    </div>
    <div class="relative">
      <input
        v-model="liveValue"
        type="time"
        :required="required"
        class="w-28 form-input"
        :class="{
          'form-input-error pr-8': error
        }"
        step="300"
      />
      <svg-icon
        v-if="error"
        name="warning"
        class="h-5 w-5 absolute right-2 absolute-center-y dark:text-primary-200 text-primary-900"
      />
    </div>
    <div v-if="error" class="h-5 relative whitespace-nowrap">
      <span class="absolute absolute-center-y text-gray-500 dark:text-primary-200 text-xs">
        {{ error }}
      </span>
    </div>
  </label>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue';

export default {
  name: 'TextField',
  components: {
    SvgIcon
  },
  props: {
    error: { type: String, default: '' },
    hideLabel: { type: Boolean, default: false },
    label: { type: String, required: true },
    required: { type: Boolean, default: false },
    value: { type: String, required: true }
  },
  data() {
    return {
      liveValue: this.value
    };
  },
  watch: {
    liveValue() {
      this.$emit('input', this.liveValue);
    }
  }
};
</script>
