<template>
  <pill-button v-if="!pressed" kind="secondary" :disabled="disabled" @click="handleOn">
    <slot />
  </pill-button>
  <pill-button v-else kind="secondary" pressed :disabled="disabled" @click="handleOff">
    <slot />
  </pill-button>
</template>

<script>
export default {
  name: 'PillToggle',
  props: {
    pressed: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  methods: {
    handleOn() {
      if (!this.pressed) {
        this.$emit('on');
      }
    },
    handleOff() {
      if (this.pressed) {
        this.$emit('off');
      }
    }
  }
};
</script>
