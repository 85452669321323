import axios from 'axios';
import { camelKeys, snakeKeys } from './objects';
/**
 * Error used when rejecting the resultant promise of `callApi`.
 */
export class ApiError extends Error {
    _isApiError;
    code;
    status;
    /**
     * The whole request data (if any).
     *
     * Prefer using the other properties before using this one.
     */
    data;
    constructor({ code, message, status, data }) {
        super(message);
        this.name = 'ApiError';
        this.code = code;
        this.status = status;
        this.data = data;
        this._isApiError = true;
    }
}
/** Current access token. Used to make requests. */
let accessToken = null;
/**
 * Preconfigured HTTP client.
 */
const HttpClient = () => axios.create({
    baseURL: '/api',
    headers: { Authorization: `Bearer ${accessToken}` }
});
/**
 * Calls a backend api asynchronously.
 *
 * If the call fails, a promise rejection occurs.
 * The resolved promise contains the parsed JSON data.
 *
 * If the `automaticCaseConversion` flag is `true` (default) and the payload is
 * an object, the function will convert its keys into snake case, and if the
 * response is an object, it will convert its keys into cammel case.
 *
 * This allows you to don't worry about language conventions. If the payload is
 * already in snake case or the response is already in cammel case, nothing
 * happens so it should be rare that you need to change its default value.
 *
 * `T` can safely be ommited since it doesn't guarante that the response is
 * actually `T` at runtime, and this function will probably be encapsualted
 * by data access abstractions.
 */
export async function callApi({ method, route, payload = null, automaticCaseConversion = true }) {
    try {
        const preparedPayload = payload && automaticCaseConversion ? snakeKeys(payload) : payload;
        const response = await HttpClient().request({
            method: method,
            url: route,
            data: preparedPayload
        });
        // Posible successful responses are objects (truthy) or null.
        // Keys of an object are cammel cased before returning.
        if (response.data && automaticCaseConversion) {
            return camelKeys(response.data);
        }
        else {
            return response.data;
        }
    }
    catch (e) {
        if (axios.isAxiosError(e)) {
            if (e.response?.data === 'Invalid credentials') {
                location.reload();
                throw new Error('Invalid credentials');
            }
            throw new ApiError({
                code: e.response?.data?.error,
                message: e.message,
                status: e.response?.status,
                data: e.response?.data
            });
        }
        throw e;
    }
}
/**
 * The LEGACY (old) implementation of the api client.
 *
 * Still supported for compatibility until we refactor old api calls.
 * Copy pasted with minor modifications.
 *
 * Do not use this function anymore (unless absolutley necessary).
 */
export async function legacyCallApi({ method = 'post', route, params = null, handleData = (x) => x, handleError = (e) => console.log(e) // eslint-disable-line no-console
 }) {
    try {
        let response = null;
        switch (method) {
            case 'put':
                response = await HttpClient().put(route, params);
                break;
            case 'get':
                response = await HttpClient().get(route);
                break;
            case 'delete':
                response = await HttpClient().delete(route, params);
                break;
            case 'patch':
                response = await HttpClient().patch(route, params);
                break;
            default:
                response = await HttpClient().post(route, params);
                break;
        }
        if (response.data) {
            return handleData(camelKeys(response.data));
        }
        else {
            return handleData(response);
        }
    }
    catch (e) {
        if (axios.isAxiosError(e) && e.response?.data === 'Invalid credentials') {
            location.reload();
            throw new Error('Invalid credentials');
        }
        return handleError(e);
    }
}
/**
 * Sends multipart form data to the server.
 */
export async function sendMultiPart({ method, route, payload, 
// TODO: Automatic conversion for sending has been removed because of
// a problem with `snakeKeys` and `Blob` data. See issue #2803.
automaticCaseConversion = true }) {
    try {
        const formData = new FormData();
        for (const [key, value] of Object.entries(payload)) {
            formData.append(key, value);
        }
        const response = await HttpClient().request({
            method: method,
            url: route,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        // Posible successful responses are objects (truthy) or null.
        // Keys of an object are cammel cased before returning.
        if (response.data && automaticCaseConversion) {
            return camelKeys(response.data);
        }
        else {
            return response.data;
        }
    }
    catch (e) {
        if (axios.isAxiosError(e)) {
            throw new ApiError({
                code: e.response?.data?.error,
                message: e.message,
                status: e.response?.status,
                data: e.response?.data
            });
        }
        else {
            throw e;
        }
    }
}
/** Time to wait before a token autorefresh attempt (in milliseconds). */
const autoRefreshInterval = 50 * 60 * 1000;
/** Time to wait before retrying autorefresh after a fail (in milliseconds). */
const autoRefreshRecoveryInterval = 3 * 60 * 1000;
/** Id of the next token refresh timeout to be executed. */
let autoRefresherId = null;
/** Refresh the current auth tokens. */
export async function refresh() {
    const { token } = await callApi({
        method: 'post',
        route: '/auth/refresh'
    });
    accessToken = token;
}
/** Stops the auto refresh mechanism */
function stopAutoRefresher() {
    if (autoRefresherId !== null) {
        clearTimeout(autoRefresherId);
        autoRefresherId = null;
    }
}
/** Ensure auth data is cleared locally without interacting with the server. */
export function forceLocalLogout() {
    accessToken = null;
    stopAutoRefresher();
}
/** Setup the auto refresh mechanism. */
export function startAutoRefresher({ onForcedLogout }) {
    const callback = async (recoverying = false) => {
        try {
            await refresh();
            autoRefresherId = window.setTimeout(callback, autoRefreshInterval);
        }
        catch (error) {
            if (recoverying) {
                forceLocalLogout();
                await onForcedLogout();
            }
            else {
                autoRefresherId = window.setTimeout(() => callback(true), autoRefreshRecoveryInterval);
            }
            throw error;
        }
    };
    autoRefresherId = window.setTimeout(callback, autoRefreshInterval);
}
/**
 * Login the API client abstraction by using email-password credentials.
 *
 * Returns the user.
 *
 * TODO: Return type.
 */
export async function loginByEmailAndPassword(email, password, { onForcedLogout }) {
    const { token, user } = await callApi({
        method: 'post',
        route: '/users/login',
        payload: { email, password }
    });
    accessToken = token;
    startAutoRefresher({ onForcedLogout });
    return user;
}
/** Login the API client abstraction by using email-password credentials. */
export async function loginByGoogle(googleToken, { onForcedLogout }) {
    const { token, user } = await callApi({
        method: 'post',
        route: '/users/google',
        payload: { googleToken }
    });
    accessToken = token;
    startAutoRefresher({ onForcedLogout });
    return user;
}
/**
 * Sign up a user and login as that user.
 *
 * TODO: Type.
 */
export async function signUpAndLogin(data, { onForcedLogout }) {
    const { token, user } = await callApi({
        method: 'post',
        route: '/users/sign_up',
        payload: data
    });
    accessToken = token;
    startAutoRefresher({ onForcedLogout });
    return user;
}
/**
 * Verify user and log in as that user.
 *
 * TODO: Type.
 */
export async function verifyUserByTokenAndLogin(emailToken, { onForcedLogout }) {
    const { token, user } = await callApi({
        method: 'put',
        route: '/users/verify_email',
        payload: { token: emailToken }
    });
    accessToken = token;
    startAutoRefresher({ onForcedLogout });
    return user;
}
/**
 * Logout the API client abstraction.
 *
 * Calls logout server-side action. This leaves cookie control to the server.
 */
export async function logout() {
    await callApi({ method: 'post', route: '/users/logout' });
    forceLocalLogout();
}
/**
 * Read-only getter function to retrive the access token.
 *
 * Try to keep token management abstracted here in the API client and avoid
 * accessing this from outside if possible.
 */
export function getAccessToken() {
    return accessToken;
}
