<template>
  <div class="flex flex-wrap sm:space-x-8">
    <label
      v-for="option in options"
      :key="option.value"
      class="flex flex-col items-center"
      :class="{
        'w-16 sm:w-24': size === 'md',
        'w-20 sm:w-32': size === 'lg'
      }"
    >
      <div
        class="my-3 overflow-hidden transform border-transparent cursor-pointer rounded-xl hover:scale-110"
        :class="{
          'w-16 h-16 sm:w-24 sm:h-24': size === 'md',
          'w-20 h-20 sm:w-32 sm:h-32': size === 'lg',
          'dark:bg-gray-800 shadow-tile dark:border dark:border-gray-900': tileShadow,
          'dark:bg-gray-800': option.value !== liveValue,
          [`bg-${activeColor} dark:bg-${activeColor}`]: option.value === liveValue
        }"
      >
        <input v-model="liveValue" :name="name" type="radio" class="hidden" :value="option.value" />
        <slot name="tile" v-bind="option" />
      </div>
      <div class="text-sm font-medium text-center text-primary-800 dark:text-gray-300">
        <span>{{ option.label }}</span>
      </div>
      <paragraph v-if="option.description" small class="text-center">
        {{ option.description }}
      </paragraph>
    </label>
  </div>
</template>

<script>
export default {
  name: 'RadioTiles',
  props: {
    options: { type: Array, required: true },
    name: { type: String, default: '' },
    value: { type: [String, Number], default: null },
    size: { type: String, default: 'md' },
    tileShadow: { type: Boolean, default: true },
    activeColor: { type: String, default: 'gray-400' }
  },
  computed: {
    liveValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  }
};
</script>
