<template>
  <p
    class="text-sm font-medium break-words whitespace-pre-line text-primary-900 dark:text-gray-200"
  >
    {{ text }}
  </p>
</template>

<script>
export default {
  name: 'UserEnteredText',
  props: {
    text: { type: String, required: true }
  }
};
</script>
