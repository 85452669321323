<template>
  <div>
    <div class="mb-1 text-xs text-primary-700 dark:text-gray-300" :class="{ 'sr-only': hideLabel }">
      {{ label }}
    </div>
    <date-pick
      v-model="liveValue"
      :weekdays="$tm('days')"
      :months="$tm('months')"
      class="w-76"
      :has-input-element="false"
      :is-date-disabled="disabledDates"
    />
  </div>
</template>

<script>
import componentI18n from '@/i18n/componentI18n';
import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';

export default {
  name: 'CalendarField',
  components: {
    DatePick
  },
  props: {
    label: { type: String, required: true },
    value: { type: String, required: true },
    hideLabel: { type: Boolean, default: false },
    disabledDates: { type: Function, default: () => false }
  },
  ...componentI18n('components.calendar_field'),
  data() {
    return {
      liveValue: this.value
    };
  },
  watch: {
    liveValue() {
      this.$emit('input', this.liveValue);
    }
  }
};
</script>

<style lang="scss">
.vdpOuterWrap {
  @apply dark:border-gray-600;
}

.vdpCell.selectable:hover .vdpCellContent {
  @apply bg-primary-400 dark:bg-gray-500;
}

.vdpCell.selected.selectable:hover .vdpCellContent,
.vdpCell.selected .vdpCellContent {
  @apply bg-primary-900 dark:bg-primary-500;
  @apply text-white;
}

.vdpCell,
.vdpCell.today {
  @apply text-primary-900 dark:text-primary-300;
}

.vdpHeader {
  background: transparent;
}

.vdpInnerWrap {
  border: 0;
  @apply text-primary-900 dark:text-gray-300;
  @apply dark:bg-gray-700;
}

.vdpPeriodControl:first-child button {
  text-transform: uppercase;
  @apply font-medium;
}

.vdpPeriodControl:last-child button {
  @apply font-normal;
  @apply text-sm;
}

.vdpCell.outOfRange {
  @apply dark:text-gray-500;
}
</style>
