/* eslint-disable max-lines */

import colors from './colors';

//////////////////////////////////
// Credentials, URIs and Keys   //
//////////////////////////////////

let wsScheme;

if (window.location.protocol === 'https:') {
  wsScheme = 'wss:';
} else {
  wsScheme = 'ws:';
}

const SOCKET_URL = `${wsScheme}//${window.location.host}/socket`;

const initialQueryParams = new URLSearchParams(window.location.search);

const CREDENTIALS = {
  // URL for websocket communication
  SOCKET_URL,

  // google client id for google auth
  GOOGLE_CLIENT_ID: process.env.VUE_APP_GOOGLE_CLIENT_ID
};

//////////////////////////////////
// Website settings             //
//////////////////////////////////

const globallyDisabledAnimations = process.env.VUE_APP_DISABLE_ANIMATIONS === 'true';
const MEDIA_QUERY_REDUCE_MOTION = 'screen and (prefers-reduced-motion: reduce)';
const { matches = false } = window.matchMedia(MEDIA_QUERY_REDUCE_MOTION);
export const REDUCE_MOTION = globallyDisabledAnimations || matches;

const WEBSITE_SETTINGS = { REDUCE_MOTION };

//////////////////////////////////
// Retro settings               //
//////////////////////////////////

const MIN_PARTICIPANTS = 1;
const SHORT_DISCUSSION_TIME = 3;
const MEDIUM_DISCUSSION_TIME = 5;
const LONG_DISCUSSION_TIME = 10;

const RETRO_SETTINGS = {
  // minimum participants in a retro
  MIN_PARTICIPANTS,

  // you can join a retro X hours before it starts
  EARLY_JOINING_THRESHOLD: 12,

  // maximum topic length
  MAX_TOPIC_LENGTH: 150,

  // maximum topic length
  MAX_ACTION_ITEM_LENGTH: 150,

  // show length reminder when there are less than X characters left
  LENGTH_REMINDER: 30,

  // minutes options you can select when discussing a new group
  SUPPORTED_TIME_OPTIONS: [SHORT_DISCUSSION_TIME, MEDIUM_DISCUSSION_TIME, LONG_DISCUSSION_TIME],

  // minutes reserved for review, used to check if there's time for discussing more groups
  EXPECTED_REVIEW_MINUTES: 0,

  // minimum writing duration in minutes
  MINIMUM_WRITING_DURATION: 5,

  // fraction of a retro that's supposed to be taken by writing phase
  DEFAULT_WRITING_RATIO: 0.1,

  // default sharing turn duration in minutes
  DEFAULT_TURN_DURATION: 2
};

//////////////////////////////////
// Technical stuff              //
//////////////////////////////////

// keys to the motivation face components
const MOTIVATION_FACES = {
  0: 'awful',
  25: 'sad',
  50: 'meh',
  75: 'happy',
  100: 'awesome'
};

// ms for the timer interval refresh
const TIMER_FREQ = 1000;

// animation speed
/* eslint-disable no-magic-numbers */
const SPEED = {
  instant: 0.0001,
  fastest: REDUCE_MOTION ? 0 : 0.15,
  fast: REDUCE_MOTION ? 0 : 0.2,
  normal: REDUCE_MOTION ? 0 : 0.25,
  slow: REDUCE_MOTION ? 0 : 0.3
};
/* eslint-enable no-magic-numbers */

const DEFAULT_FLASH_TIMEOUT = 5000;

// animation speed
const INTERVALS = {
  instant: 1,
  fastest: 150,
  fast: 200,
  normal: 250,
  slow: 300,
  slowest: 400
};

const RETRO_PHASES = ['setup', 'writing', 'posting', 'grouping', 'voting', 'discussion', 'review'];

const TECHNICAL_CONSTANTS = {
  COLORS: colors,
  DEFAULT_FLASH_TIMEOUT,
  INTERVALS,
  MOTIVATION_FACES,
  RETRO_PHASES,
  SPEED,
  TIMER_FREQ
};

//////////////////////////////////
// Auth constants               //
//////////////////////////////////

const AUTH_CONSTANTS = {
  PASSWORD_MINIMUM_LENGTH: 8
};

//////////////////////////////////
// Ice Breaker constants        //
//////////////////////////////////

const ICE_BREAKER_CONSTANTS = {
  MAX_ICE_BREAKER_ANSWER_LENGTH: 40,
  MAX_ICE_BREAKER_QUESTION_LENGTH: 255
};

//////////////////////////////////
// File Upload constants        //
//////////////////////////////////
export const FILE_UPLOAD_CONSTANTS = {
  ALLOWED_EXTENSIONS: ['image/jpg', 'image/jpeg', 'image/png'],
  MAX_IMAGE_SIZE: 2000000
};

//////////////////////////////////
// Team constants               //
//////////////////////////////////
export const TEAM_CONSTANTS = {
  MAX_NAME_LENGTH: 50
};

//////////////////////////////////
// Plan prices               //
//////////////////////////////////
export const PLAN_PRICES = {
  PRO: {
    ANNUAL: 4.25,
    MONTHLY: 5
  },
  PREMIUM: {
    ANNUAL: 7.5,
    MONTHLY: 9
  }
};

//////////////////////////////////
// 'Feature Flags'              //
//////////////////////////////////

// For testing purposes since `process.env` is read at compile time.
const PRICING_ENABLED = initialQueryParams.has('force_pricing_ff')
  ? initialQueryParams.get('force_pricing_ff') === 'true'
  : process.env.VUE_APP_PRICING_ENABLED === 'true';

export const FEATURE_FLAGS = {
  PRICING_ENABLED
};

//////////////////////////////////
// HTTP Status codes            //
//////////////////////////////////

export const HTTP_STATUS = {
  UNAUTHORIZED: 401,
  UNPROCESSABLE_ENTITY: 422
};

//////////////////////////////////
// EXPORT EVERYTHING HERE       //
//////////////////////////////////

export default Object.freeze({
  ...AUTH_CONSTANTS,
  ...CREDENTIALS,
  ...ICE_BREAKER_CONSTANTS,
  ...RETRO_SETTINGS,
  ...TECHNICAL_CONSTANTS,
  ...WEBSITE_SETTINGS
});
