<!-- Credit: https://regenrek.com/posts/how-to-create-an-animated-vue-sidebar-menu-with-vue-observable/ -->
<template>
  <div>
    <div id="burger" :class="{ active: isBurgerActive }" @click.prevent="toggle">
      <slot>
        <button type="button" class="burger-button" title="Menu">
          <span class="burger-bar burger-bar--1 bg-wyeworks-black dark:bg-gray-300"></span>
          <span class="burger-bar burger-bar--2 bg-wyeworks-black dark:bg-gray-300"></span>
          <span class="burger-bar burger-bar--3 bg-wyeworks-black dark:bg-gray-300"></span>
        </button>
      </slot>
    </div>
    <transition-a11y :css="false" @enter="slideXIn" @leave="slideXOut">
      <div v-if="isBurgerActive" class="p-2 sidebar-panel">
        <div class="flex flex-col text-2xl font-bold text-gray-100">
          <div class="pb-10">
            <router-link
              to="/"
              class="flex items-center space-x-1"
              custom
              v-slot="{ href, navigate }"
            >
              <a :href="href" @click="toggleOnLinkNavigation(navigate, $event)">
                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" v-bind:svg-inline="''" v-bind:src="'@/assets/images/logo.svg'"><g fill="none" fill-rule="evenodd"><path fill="#FF7645" stroke="#FF7645" stroke-width=".5" d="M4.862 1h17.476a3.171 3.171 0 013.17 3.073l.59 18.856a3.172 3.172 0 01-3.071 3.27l-.1.001H4.273a3.172 3.172 0 01-3.171-3.172l.001-.099.59-18.856A3.168 3.168 0 014.861 1z"/><g stroke="#00292B" stroke-linecap="round" stroke-width=".595"><path d="M13.797 8.678a.59.59 0 111.181 0h0m3.544 0a.59.59 0 111.181 0h0"/><path fill="#00292B" stroke-linejoin="round" d="M19.112 10.45a2.362 2.362 0 11-4.724.001h4.724z"/></g><path fill="#FFF" fill-rule="nonzero" stroke="#FFF" stroke-linejoin="round" stroke-width=".68" d="M10.777 15.777H6.699v5.437h4.078z"/><path stroke="#DDDBEA" stroke-linecap="round" stroke-width=".68" d="M8.058 14.304l.276-.871a.683.683 0 000-.41l-.211-.666a.683.683 0 010-.41l.21-.666a.668.668 0 000-.41L8.059 10h0m1.359 4.304l.275-.871a.683.683 0 000-.41l-.275-.87h0" style="mix-blend-mode:multiply"/><path fill="#FFF" fill-rule="nonzero" d="M6.19 16.796a1.53 1.53 0 01-.001 3.059c-.825 0-1.189-.602-1.189-1.53 0-.927.364-1.529 1.19-1.529zm0 .68c-.36 0-.51.25-.51.85s.15.85.51.85a.85.85 0 00-.001-1.7z"/></g></svg>
                <h1 class="text-gray-900 block-inline">{{ $t('common.app_name') }}</h1>
              </a>
            </router-link>
          </div>
          <div
            class="px-10 pb-4"
            :class="{ underline: $route.name === 'retros' || $route.name === 'home' }"
          >
            <router-link to="/" custom v-slot="{ href, navigate }">
              <a :href="href" @click="toggleOnLinkNavigation(navigate, $event)">
                {{ $t('home') }}
              </a>
            </router-link>
          </div>
          <div class="px-10 pb-4" :class="{ underline: $route.name === 'pricing' }">
            <router-link to="/pricing" custom v-slot="{ href, navigate }">
              <a :href="href" @click="toggleOnLinkNavigation(navigate, $event)">
                {{ $t('pricing') }}
              </a>
            </router-link>
          </div>
          <div class="px-10 pb-4" :class="{ underline: $route.name === 'aboutUs' }">
            <router-link to="/about_us" custom v-slot="{ href, navigate }">
              <a :href="href" @click="toggleOnLinkNavigation(navigate, $event)">
                {{ $t('about_us') }}
              </a>
            </router-link>
          </div>
          <div class="px-10">
            <a
              href="https://www.linkedin.com/company/retroally/posts/?feedView=articles"
              target="_blank"
            >
              {{ $t('blog') }}
            </a>
          </div>
          <div v-if="!loggedIn" class="px-10 pt-20">
            <pill-button>
              <router-link to="/auth/login" custom v-slot="{ href, navigate }">
                <a :href="href" @click="toggleOnLinkNavigation(navigate, $event)">
                  {{ $t('get_started') }}
                </a>
              </router-link>
            </pill-button>
          </div>
        </div>
      </div>
    </transition-a11y>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import componentI18n from '@/i18n/componentI18n';
import transitions from '@/helpers/transitions';

export default {
  name: 'MobileMenu',
  ...componentI18n('components.nav'),
  data: () => ({
    isBurgerActive: false
  }),
  computed: {
    ...mapGetters(['loggedIn'])
  },
  methods: {
    ...transitions.slideX,
    toggle() {
      this.isBurgerActive = !this.isBurgerActive;
    },
    toggleOnLinkNavigation(navigate, event) {
      this.toggle();
      navigate(event);
    }
  }
};
</script>
<style>
.burger-button {
  position: relative;
  height: 30px;
  width: 32px;
  display: block;
  z-index: 999;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  pointer-events: all;
  transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.burger-bar {
  position: absolute;
  top: 50%;
  right: 6px;
  left: 6px;
  height: 2px;
  width: auto;
  margin-top: -1px;
  transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.2s cubic-bezier(0.165, 0.84, 0.44, 1),
    background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.burger-bar--1 {
  -webkit-transform: translateY(-6px);
  transform: translateY(-6px);
}

.burger-bar--2 {
  transform-origin: 100% 50%;
}

.burger-bar--3 {
  transform: translateY(6px);
}

#burger.active .burger-button {
  transform: rotate(-180deg);
}

#burger.active .burger-bar--1 {
  transform: rotate(45deg);
  background-color: #fff;
}

#burger.active .burger-bar--2 {
  opacity: 0;
}

#burger.active .burger-bar--3 {
  transform: rotate(-45deg);
  background-color: #fff;
}

.sidebar-panel {
  overflow-y: auto;
  background-color: #67d1e1;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 990;
}
</style>
