<template>
  <pill-button v-if="!recentlyCopied" kind="secondary" size="sm" @click="handleCopy">
    <slot />
  </pill-button>
  <pill-button v-else kind="secondary" size="sm">
    {{ $t('copied') }}
  </pill-button>
</template>

<script>
import componentI18n from '@/i18n/componentI18n';

const recentlyCopiedDuration = 1000;

export default {
  name: 'RetroLink',
  ...componentI18n('components.base.copy_button'),
  props: {
    copy: { type: String, required: true }
  },
  data() {
    return {
      recentlyCopied: false
    };
  },
  methods: {
    handleCopy() {
      this.$clipboard(this.copy);
      this.recentlyCopied = true;
      setTimeout(() => {
        this.recentlyCopied = false;
      }, recentlyCopiedDuration);

      this.$emit('click');
    }
  }
};
</script>
