<template>
  <p
    class="text-gray-400"
    :class="{
      'text-xs leading-relaxed': small,
      'text-sm': !small
    }"
  >
    <slot />
  </p>
</template>

<script>
export default {
  name: 'Paragraph',
  props: {
    small: { type: Boolean, default: false }
  }
};
</script>
