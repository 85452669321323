import SvgIcon from '@/components/SvgIcon.vue';
export default {
    name: 'NumberField',
    components: {
        SvgIcon
    },
    props: {
        label: { type: String, required: true },
        modelValue: { type: Number, default: null },
        autocomplete: { type: String, default: '' },
        required: { type: Boolean, default: false },
        min: { type: Number, default: null },
        max: { type: Number, default: null },
        step: { type: Number, default: 1 },
        placeholder: { type: String, default: '' },
        hideLabel: { type: Boolean, default: false },
        arrows: { type: Boolean, default: false },
        size: { type: String, default: 'md' },
        disabled: { type: Boolean, default: false },
        errorBorder: { type: Boolean, default: false },
        integer: { type: Boolean, default: false }
    },
    computed: {
        disableInc() {
            return this.max !== null && this.modelValue >= this.max;
        },
        disableDec() {
            return this.min !== null && this.modelValue <= this.min;
        },
        font() {
            switch (this.size) {
                case 'md':
                    return '';
                case 'lg':
                    return 'text-2xl font-medium leading-none h-10';
                case 'xl':
                    return 'text-6xl font-medium';
                default:
                    return '';
            }
        }
    },
    methods: {
        inc() {
            this.handleUpdate(this.modelValue + this.step);
        },
        dec() {
            this.handleUpdate(this.modelValue - this.step);
        },
        handleUpdate(newValue) {
            let value = Number(newValue);
            if (this.integer) {
                value = Math.floor(value);
            }
            if (this.max !== null && value > this.max) {
                value = this.max;
            }
            if (this.min !== null && value < this.min) {
                value = this.min;
            }
            this.$refs.input.value = value;
            this.$emit('update:modelValue', value);
        }
    },
    compatConfig: {
        COMPONENT_V_MODEL: false
    }
};
