import { gsap } from 'gsap';
import constants from '@/constants';

export default {
  fade: {
    fadeIn: (element, done) => {
      gsap.from(element, { duration: constants.SPEED.fast, autoAlpha: 0, onComplete: done });
    },
    fadeOut: (element, done) => {
      gsap.to(element, { duration: constants.SPEED.fast, autoAlpha: 0, onComplete: done });
    }
  },
  scale: {
    randomDelayedScaleIn: (element, done) => {
      gsap.from(element, {
        duration: constants.SPEED.fast,
        autoAlpha: 0,
        scale: 0.2,
        ease: 'back.out',
        delay: Math.random() * constants.SPEED.fast,
        onComplete: done
      });
    },
    scaleIn: (element, done) => {
      gsap.from(element, {
        duration: constants.SPEED.fast,
        autoAlpha: 0,
        scale: 0.2,
        ease: 'back.out',
        onComplete: done
      });
    },
    scaleOut: (element, done) => {
      gsap.to(element, {
        duration: constants.SPEED.fast,
        autoAlpha: 0,
        scale: 0.2,
        ease: 'back.in',
        onComplete: done
      });
    }
  },
  slideX: {
    slideXIn: (element, done) => {
      gsap.from(element, {
        duration: constants.SPEED.normal,
        autoAlpha: 0,
        x: '120%',
        ease: 'none',
        onComplete: done
      });
    },
    slideXOut: (element, done) => {
      gsap.to(element, {
        duration: constants.SPEED.normal,
        autoAlpha: 0,
        x: '120%',
        ease: 'back.in',
        onComplete: done
      });
    }
  },
  slideY: {
    slideYIn: (element, done) => {
      gsap.from(element, {
        duration: constants.SPEED.fast,
        autoAlpha: 0,
        y: '120%',
        ease: 'back.out',
        onComplete: done
      });
    },
    slideYOut: (element, done) => {
      gsap.to(element, {
        duration: constants.SPEED.fast,
        autoAlpha: 0,
        y: '120%',
        ease: 'back.in',
        onComplete: done
      });
    }
  }
};
