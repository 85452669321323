<template>
  <button
    class="flex items-center h-8 text-sm font-bold text-right whitespace-nowrap"
    :class="{
      'text-gray-400 hover:text-gray-400 cursor-not-allowed': disabled,
      'text-gray-900 hover:text-primary-900 dark:text-gray-200 dark-hover:text-white':
        enabled && !inverted,
      'text-white hover:text-primary-100 dark:text-gray-200 dark-hover:text-white':
        enabled && inverted
    }"
    :disabled="disabled"
    :type="type"
    @click="handleClick"
  >
    <span><slot /></span>
  </button>
</template>

<script>
export default {
  name: 'TextButton',
  props: {
    disabled: { type: Boolean, default: false },
    inverted: { type: Boolean, default: false },
    type: { type: String, default: 'button' }
  },
  computed: {
    enabled() {
      return !this.disabled;
    }
  },
  methods: {
    handleClick() {
      this.$emit('click');
    }
  }
};
</script>
