// Globally register all components in base
const requireComponent = require.context(
// The relative path of the components folder
'../components/base', 
// Whether or not to look in subfolders
true, 
// The regular expression used to match base component filenames
/\.vue$/);
export default function registerBaseComponents(app) {
    requireComponent.keys().forEach((filePath) => {
        // Get component config.
        const componentConfig = requireComponent(filePath);
        const [fileName] = filePath.split('/').slice(-1);
        const componentName = fileName.replace(/\.\w+$/, '');
        // Register component globally
        app.component(componentName, 
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig);
    });
}
