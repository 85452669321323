<template>
  <div class="flex mb-12">
    <span class="text-4xl font-bold text-gray-600 dark:text-white"> {{ index }}. </span>
    <p class="ml-3 text-2xl font-light text-gray-500 whitespace-pre-line dark:text-gray-200">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'IndexedParagraph',
  props: {
    index: { type: [Number, String], required: true },
    text: { type: String, required: true }
  }
};
</script>

<style></style>
