export default {
  activities: {
    mad_sad_glad: {
      name: 'Mad / Sad / Glad',
      description:
        "Build positive teamwork dynamics by discussing the team's feelings about last iteration. {0} This activity is a great way for the team to identify opportunities for improvement.",
      topic_types: {
        mad: {
          name: 'Mad',
          placeholder: 'What makes you mad?'
        },
        sad: {
          name: 'Sad',
          placeholder: 'What makes you sad?'
        },
        glad: {
          name: 'Glad',
          placeholder: 'What makes you glad?'
        }
      }
    },
    the_soup: {
      name: 'The Soup',
      description:
        'Do you need to exercise project responsibility awareness? {0} The soup will help the team to distinguish where they have direct responsibility, where they can influence and where they cannot.',
      topic_types: {
        control: {
          name: 'Control',
          placeholder: 'What is under control of the team?'
        },
        influence: {
          name: 'Influence',
          placeholder: 'What can the team influence on?'
        },
        context: {
          name: 'Context',
          placeholder: "What is in the team's context?"
        }
      }
    },
    sailboat: {
      name: 'Sailboat',
      description:
        'Do you have an upcoming goal? Maybe an approaching project milestone? {0} Analyze with the team what pushes it forward and what can be obstacles to reach the goal.',
      topic_types: {
        wind: {
          name: 'Wind',
          placeholder: 'What is carrying the team forward?'
        },
        anchor: {
          name: 'Anchors',
          placeholder: 'What is slowing the team down?'
        },
        rocks: {
          name: 'Rocks',
          placeholder: 'What is a danger for the team?'
        }
      }
    },
    kalm: {
      name: 'K.A.L.M.',
      description:
        'Keep up the good work by analyzing which good practices the team should KEEP, ADD, do MORE, and which practices should do LESS.',
      topic_types: {
        keep: {
          name: 'Keep',
          placeholder: 'What do you want to keep?'
        },
        add: {
          name: 'Add',
          placeholder: 'What do you want to add?'
        },
        less: {
          name: 'Less',
          placeholder: 'What do you want less of?'
        },
        more: {
          name: 'More',
          placeholder: 'What do you want more of?'
        }
      }
    },
    cardiac_rhythm: {
      name: 'Cardiac Rhythm',
      description:
        "What is the sprint pace like? Every team needs a sustainable work pace to perform well. {0} This activity will help you and the team map the sprint's activities to find out if the pace is right for it.",
      topic_types: {
        high: {
          name: 'High',
          placeholder: 'What were things with high rhythm?'
        },
        medium: {
          name: 'Medium',
          placeholder: 'What were things with medium rhythm?'
        },
        low: {
          name: 'Low',
          placeholder: 'What were things with low rhythm?'
        }
      }
    },
    scrum_flow: {
      name: 'Scrum flow',
      description:
        'Do you feel that the Scrum ceremonies can be improved? {0} Analyze with the team how the scrum ceremonies (Daily, Planning, Refinement & Review) are being executed.',
      topic_types: {
        daily: {
          name: 'Daily',
          placeholder: 'Things to do with daily meeting'
        },
        planning: {
          name: 'Planning',
          placeholder: 'Things to do with planning meeting'
        },
        refinement: {
          name: 'Refinement',
          placeholder: 'Things to do with refinement meting'
        },
        review: {
          name: 'Review',
          placeholder: 'Things to do with sprint review'
        }
      }
    },
    discuss_and_mention: {
      name: 'Discuss & Mention',
      description:
        "Need to focus on things to discuss? This activity is good if you want to go straight to the point. {0} Look for consensus, coordinate actions and discuss what's going on.",
      topic_types: {
        discussion: {
          name: 'Discuss',
          placeholder: 'What do you want to discuss?'
        },
        mention: {
          name: 'Mention',
          placeholder: 'What do you want to mention?'
        }
      }
    },
    effort_and_pain: {
      name: 'Effort & Pain',
      description:
        'A deadline is approaching and the team is exhausted? {0} Reflect on how much effort the deadline demands and the pain it causes, then look for actions to migitate them.',
      topic_types: {
        effort: {
          name: 'Effort',
          placeholder: 'What are you focusing your efforts on?'
        },
        pain: {
          name: 'Pain',
          placeholder: 'What is causing you pain?'
        }
      }
    },
    hope_and_concern: {
      name: 'Hope & Concern',
      description:
        "Looking forward to the next sprint? {0} Analyze the team's hopes and concerns about the immediate future and take action.",
      topic_types: {
        hope: {
          name: 'Hope',
          placeholder: 'What do you hope for?'
        },
        concern: {
          name: 'Concern',
          placeholder: 'What concerns you?'
        }
      }
    },
    three_ls: {
      name: '3Ls: Liked, Learned, Lacked',
      description:
        'Take a moment to review the lessons learned, best practices, and workflow the team has been developing over the past sprints to build on and strengthen the team.',
      topic_types: {
        liked: {
          name: 'Liked',
          placeholder: 'What did you like?'
        },
        learned: {
          name: 'Learned',
          placeholder: 'What did you learn?'
        },
        lacked: {
          name: 'Lacked',
          placeholder: 'What did you lack?'
        }
      }
    },
    start_stop_continue: {
      name: 'Start, Stop, Continue',
      description:
        'Looking for short-term team behavior changes? {0} Immerse the team in an action-oriented retrospective by asking what should we start doing, what should we stop doing, and what should we continue doing.',
      topic_types: {
        start: {
          name: 'Start',
          placeholder: 'What should the team start doing?'
        },
        stop: {
          name: 'Stop',
          placeholder: 'What should the team stop doing?'
        },
        continue: {
          name: 'Continue',
          placeholder: 'What should the team keep doing?'
        }
      }
    }
  }
};
