import { camelKeys, snakeKeys } from './objects';
/**
 * Turns a string into it's snake form.
 *
 * Note: This is a quick implementation using the implementation for objects.
 */
export function snake(s) {
    return Object.keys(snakeKeys({ [s]: null }))[0];
}
/**
 * Turns a string into it's cammel form.
 *
 * Note: This is a quick implementation using the implementation for objects.
 */
export function camel(s) {
    return Object.keys(camelKeys({ [s]: null }))[0];
}
