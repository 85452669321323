/**
 * Module to work with users.
 */
import { callApi, sendMultiPart } from '@/helpers/apiClient';
/**
 * Updates the user's avatar.
 */
export async function updateAvatar(avatar) {
    const response = await sendMultiPart({
        route: `/users/me/avatar`,
        method: 'put',
        payload: { avatar }
    });
    return response.user;
}
/**
 * Gets the count of owned free workspaces for the user.
 */
export async function getOwnedFreeWorkspacesCount() {
    const { count } = await callApi({
        route: `/users/me/owned-free-workspaces-count`,
        method: 'get'
    });
    return count;
}
