/// import Vue from 'vue';
import 'es6-promise/auto';
import { createStore } from 'vuex';
import flash from './modules/flash';
import newRetro from './modules/newRetro';
import newWorkspace from './modules/newWorkspace';
import preferences from './modules/preferences';
import session from './modules/session';
import site from './modules/site';
import workspaces from './modules/workspaces';
export const store = createStore({
    state: {},
    modules: {
        flash,
        newRetro: {
            namespaced: true,
            ...newRetro
        },
        newWorkspace: {
            namespaced: true,
            ...newWorkspace
        },
        preferences,
        session,
        site,
        workspaces
    }
});
