import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import componentI18n from '@/i18n/componentI18n';
import MobileMenu from '@/components/header/SiteNav/MobileMenu.vue';
import NavItem from '@/components/header/SiteNav/NavItem.vue';
import SvgIcon from '@/components/SvgIcon.vue';
export default defineComponent({
    name: 'SiteNav',
    components: {
        MobileMenu,
        NavItem,
        SvgIcon
    },
    ...componentI18n('components.nav'),
    props: {
        menu: { type: Boolean, default: true }
    },
    computed: {
        ...mapGetters(['isDarkMode', 'loggedIn', 'session']),
        isLogInPage() {
            return this.$route.name === 'login';
        },
        isRetroListingPage() {
            return this.loggedIn && (this.$route.name === 'retros' || this.$route.name === 'home');
        },
        shouldDisplayArchive() {
            return this.loggedIn && this.session.hasArchivedRetros;
        }
    },
    methods: {
        ...mapActions(['toggleDarkMode', 'toggleLocale'])
    }
});
