import legacyData from '@/data/legacy';
import moment from 'moment';
export default {
    state: {
        darkMode: false,
        locale: 'en'
    },
    getters: {
        isDarkMode: (state) => state.darkMode,
        locale: (state) => state.locale
    },
    mutations: {
        setDarkMode(state, isDark) {
            state.darkMode = isDark;
        },
        setLocale(state, locale) {
            state.locale = locale;
            moment.locale(locale);
        }
    },
    actions: {
        loadPreferences({ commit }) {
            if (localStorage.getItem('darkMode') !== null) {
                commit('setDarkMode', localStorage.getItem('darkMode') === 'true');
            }
            else {
                commit('setDarkMode', window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
            }
            if (localStorage.getItem('locale') !== null) {
                commit('setLocale', localStorage.getItem('locale'));
            }
            else if (window.navigator.language.includes('es')) {
                commit('setLocale', 'es');
            }
            else {
                commit('setLocale', 'en');
            }
        },
        toggleDarkMode({ getters, commit }) {
            commit('setDarkMode', !getters.isDarkMode);
            localStorage.darkMode = getters.isDarkMode;
        },
        toggleLocale({ getters, commit }) {
            const newLocale = getters.locale === 'en' ? 'es' : 'en';
            commit('setLocale', newLocale);
            localStorage.locale = newLocale;
            if (getters.loggedIn) {
                legacyData.updateLocale({ locale: newLocale });
            }
        }
    }
};
