import constants from '@/constants';
export default {
    state: [],
    getters: {
        flashMessages: (state) => state
    },
    mutations: {
        flash(state, message) {
            state.push(message);
        },
        clearFlash(state, message) {
            const index = state.findIndex((m) => m.id === message.id);
            if (index >= 0) {
                state.splice(index, 1);
            }
        },
        clearAllFlash(state) {
            state.splice(0, state.length);
        }
    },
    actions: {
        flashThenClear({ commit }, { message, argument = {}, timeout = constants.DEFAULT_FLASH_TIMEOUT }) {
            const flashMessage = { id: Symbol(), message, argument };
            commit('flash', flashMessage);
            setTimeout(() => commit('clearFlash', flashMessage), timeout);
        }
    }
};
