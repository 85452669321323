/* eslint-disable max-lines */

export default {
  common: {
    add: 'Add',
    and: 'and',
    app_name: 'RetroAlly',
    cancel: 'Cancel',
    close: 'Close',
    confirm_leave: 'You have unsaved changes, are you sure you want to leave?',
    contact_us: 'Need help? Contact us.',
    delete: 'Delete',
    moderator: 'Moderator',
    next: 'Next',
    no_undo: 'You cannot undo this.',
    previous: 'Previous',
    save: 'Save',
    submit: 'Submit',
    team_name: 'Team Name'
  },
  components: {
    choose_team_table: {
      team_name: 'Team name',
      teammates: 'Teammates',
      status: 'Status',
      in_progress_retro: 'Retro in progress',
      upcoming_retro: 'Upcoming retro'
    },
    contract_term_ends: {
      warning_message: 'Your contract term ends on %{term}'
    },
    delete_seats_modal: {
      available_seats: 'Available seats',
      cancel: '@:common.cancel',
      close: '@:common.close',
      delete_plural: 'Delete seats',
      delete: 'Delete seat',
      description_1:
        'You can only delete available seats. To have available seats you need to delete users from ',
      description_2: 'Workspace users',
      no_seats_title: 'No available seats to delete',
      seats_title: 'Delete seats?',
      total_seats: 'Total seats'
    },
    maturity_slider: {
      heading: 'Team maturity',
      label: 'How well do you know each other?',
      maturity_1: {
        label: "We don't know each other well"
      },
      maturity_4: {
        label: 'We know each other well'
      }
    },
    image_uploads: {
      supports: {
        max_size: 'File size max. 2MB',
        supports: 'Supports JPG, JPEG & PNG'
      },
      upload_image_button: {
        change_picture: 'Change picture'
      }
    },

    need_help: {
      prompt: 'Need help? Contact us'
    },
    search_input: {
      search: 'Search...'
    },
    no_results: {
      double_check:
        'Double check your search for any typos or spelling errors - or try a different search.',
      keep_in_mind: 'Also keep in mind, you can only search by',
      no_matches: "Hmmm… I couldn't find any matches for “%{query}”."
    },
    user_multi_select: {
      seats_available_placeholder: 'Add & Invite teammates',
      seats_unavailable_placeholder: 'Invite teammates',
      teammate_email: 'Teammate email'
    },
    unlock_more_activities_dialog: {
      activity: '👉 PRO plan has +10 activities to make the best from a retrospective',
      confirm: 'Got it',
      helper: '👉 Try out our activities helper to select the perfect activity at the perfect time',
      check: '📘 Check',
      article: 'this article',
      more_information: 'for more information',
      outcomes: '👉 Using the same retrospective activity can lead your team to the same outcomes',
      title: 'Upgrade your plan',
      upgrade: 'Upgrade your plan to use this activity and more...'
    },
    breadcrumb: {
      archived_teams: 'Archived teams',
      create_retro: 'Create retrospective',
      create_team: 'Create team',
      delete_workspace: 'Delete workspace',
      edit_team: 'Edit team',
      edit_workspace: 'Workspace Settings',
      workspace_change_plan: 'Change plan',
      team_submenu: {
        edit: 'Edit team'
      },
      workspace_members: 'Workspace users',
      workspace_seats: 'Manage seats',
      workspace_submenu: {
        archived_teams: 'Archived teams',
        choose_another: 'Choose another one',
        create: 'Create workspace',
        settings: 'Workspace settings',
        switch: 'Switch workspace',
        users: 'Workspace users'
      }
    },
    team_member_item: {
      moderator_flag: 'Moderator'
    },
    common_feature_card: {
      unlimited_teams: {
        title: 'Unlimited teams',
        subtitle: 'Create as many teams as you need, the sky is the limit.'
      },
      more_activities: {
        title: '+10 Activities',
        subtitle: 'Spice things up and explore different activities with your teams.'
      },
      ice_breakers: {
        title: 'Ice breakers',
        subtitle: 'Smooth things out before the retro starts with our Ice Breakers.'
      },
      slack_integration: {
        title: 'Slack integration',
        subtitle: 'Keep your teams updated.'
      }
    },
    workspaces: {
      invites_form: {
        add_teammate: 'Add teammate',
        email_error: 'Invalid email',
        seats_available: '%{taken} seats taken, %{available} available',
        who_belongs: 'Who should be in'
      },
      invites_modal: {
        back: 'Back',
        buy: 'Buy seats',
        cancel: 'Cancel',
        invalid_one_seat_amount: 'You have to buy at least 1 seat to cover your new invitation',
        invalid_seats_amount:
          'You have to buy at least %{seats} seats to cover all your new invitations',
        invitations_step_body_retro: 'Invite your teammates to join your retro',
        invitations_step_body_team: 'Invite your teammates to join your team',
        invitations_step_body_workspace: 'Invite your teammates to join your workspace',
        next: 'Next',
        seats_available: '%{taken} seats taken, %{available} available',
        seats_step_body: 'Buy more seats for your new teammates',
        send_immediate: 'Send invite',
        send_later: 'Add teammates'
      },
      payment_banner: {
        failed_message: 'Please update your payment details',
        manage_payment: 'Manage payment info'
      },
      seats_modifier: {
        add_seats: 'Add seats',
        annual: 'annual',
        available_seats: 'Available seats',
        charge: '$%{charge} USD Estimated %{period} charge for additional seats',
        current_seats: 'Current seats',
        monthly: 'monthly',
        single_seat_charge: '$%{charge} USD Estimated %{period} charge for additional seat',
        term: 'Prorated for your remaining contract term: %{term}',
        total_seats: 'Total seats'
      },
      selector: {
        modal: {
          after: 'A member will have to invite you again if you wish to join them in the future.',
          cancel: '@:common.cancel',
          heading: 'Are you sure you want to reject the invitation to %{workspaceName}?',
          reject: 'Reject invitation'
        },
        no_workspaces: 'No workspaces joined yet, create one',
        logged_in: 'You are logged in as'
      },
      selector_item: {
        members: '1 User | %{n} Users',
        members_not_joined: '1 User, not joined yet | %{n} Users, not joined yet',
        reject_invitation: 'Reject invitation'
      },
      show: {
        ask: 'Ask to join to the team',
        create_team: 'Create Team',
        other_teams: 'Other teams',
        request_access:
          'Create a new team or request access to existing ones and start your Retroally experience',
        search_by: 'Team name',
        single_team: 'My team',
        starter_subtitle:
          'Retroally STARTER plan only allows 1 team for free. Ask to join into the available team and enjoy!',
        suspended_paragraph_owner: 'This workspace is suspended.',
        suspended_paragraph:
          'This workspace is suspended. Please ask the owner to update the payment details.',
        suspended_title: 'This is a bit awkward...',
        team_name: '@:common.team_name',
        teammates: 'Teammates',
        teams: 'My teams',
        upgrade_button: 'Upgrade now',
        upgrade_subtitle: 'Upgrade now to boost your retrospectives',
        upgrade_title: 'Ready to take off?',
        welcome_to_retroally: 'Welcome to Retroally!'
      },
      team_card: {
        just_you: 'Just you, invite others!',
        teammates: 'teammates',
        upcoming_retro: 'Upcoming retro on'
      },
      team_form: {
        choose_first_team_name: 'Create the first team, how is it named?',
        choose_team_name: 'What is the name of the team?',
        recommendation:
          'Recommendation: Think about the next team you are going to have a retro with',
        team_name: '@:common.team_name'
      },
      update_workspace_seats: {
        buy: 'Buy seats',
        buy_single_seat: 'Buy seat',
        cancel: '@:common.cancel',
        term: 'Prorated for your remaining contract term: %{term}',
        title: 'Buy more seats for your new teammates'
      },
      pricing_release_dashboard: {
        already_own_free_ws: 'You already own a workspace with starter plan',
        ask_to_choose: 'You have to ask your workspace owner to choose plan',
        code: 'CODE:',
        decide_text:
          'It is time to decide if you want to keep to the PRO plan or if you are going to downgrade your workspace.',
        downgrade: 'Downgrade',
        keep_pro_button: 'Keep PRO',
        keep_pro_text: 'Keep PRO for',
        month: 'Month',
        stats: {
          action_items: 'Action items',
          active_users: 'Active users',
          most_used_activity: 'Most used activity',
          no_activity:
            'Create your first retro to enjoy the activities that RetroAlly has for you!',
          open_discussions: 'Open discussions',
          retros_performed: 'Retros performed',
          title: 'Statistics from last 3 months'
        },
        teams_subtitle: 'Workspace teams',
        title: 'Thank you for being a loyal customer 🧡',
        value_for: 'per user/month',
        year: 'Year'
      },
      workspace_form: {
        choose_name: 'How would you name the workspace?',
        name: 'Workspace name',
        something_easy: 'Something simple, like your company name'
      },
      workspace_member_row: {
        at: 'at',
        invited: 'Invited',
        owner: 'Owner',
        user: 'User'
      }
    },
    about_us: {
      title: 'How we did RetroAlly',
      we: 'We',
      put_together_our_experience:
        'put together all our experience doing ' +
        'retros remotely with our clients for the past 15 years. After all that time, you could ' +
        "say that we should know what works and what doesn't.",
      combined_the_knowledge:
        'We combined our findings on the field with the knowledge of ' +
        'our Agile Coach on the subject.',
      the_result_is:
        'The result is a powerful yet easy to use tool that helps teams hold ' +
        'productive retrospective meetings that actually help them improve.',
      how_we_built: 'How we built RetroAlly',
      with_remotism:
        'With remotism becoming a more prominent aspect of our lives, ' +
        "we couldn't help but notice the lack of tools geared to help teams carry out " +
        'effective and meaningful retrospectives.',
      we_started_working:
        'We started working on a platform that would allow our internal teams to have the ' +
        'meetings painlessly, and felt obliged to share it with the world after witnessing its ' +
        'success.'
    },
    action_items: {
      action_item: 'Action Item',
      action_items: 'Action Items',
      new_empty: {
        heading: 'What are you going to do about it?',
        subheading: 'Discuss with the team and add action items',
        new_action_item: 'New Action Item'
      },
      new: {
        cancel: '@:common.cancel',
        description_empty: "There's nothing to save.",
        force_cancel: 'Force cancel',
        force_submit: 'Force submit',
        placeholder: 'Write a new action item...',
        save: '@:common.add',
        title: 'New Action Item',
        what_you_write_is_public: 'Think together! What you write is being seen by your teammates.',
        too_long: 'Too long! Try to keep it brief.',
        n_characters_remaining:
          '0 characters remaining. | 1 character remaining. | %{n} characters remaining.',
        x_is_writing: 'Think together! %{name} is writing...'
      },
      edit: {
        cancel: '@:common.cancel',
        force_cancel: 'Force cancel',
        force_submit: 'Force submit',
        no_changes: 'There are no changes to be saved.',
        save: '@:common.save',
        title: 'Editing Action Item',
        tooltip: 'Edit',
        what_you_write_is_public: 'Think together! What you write is being seen by your teammates.',
        too_long: 'Too long! Try to keep it brief.',
        n_characters_remaining:
          '0 characters remaining. | 1 character remaining. | %{n} characters remaining.',
        x_is_editing: 'Think together! %{name} is editing...'
      },
      owners: {
        keep_the_team_accountable:
          '%{name} will keep the team accountable for ... | %{n} people will keep the team accountable for...',
        keep_accountable_question: 'Who will keep the team accountable?',
        team_keep_in_mind: 'The team will keep in mind...',
        assign_me_too: 'Assign to me too',
        i_will: 'I will'
      },
      delete: {
        tooltip: 'Delete',
        yes: 'Yes, delete it',
        no: '@:common.cancel',
        before: '@:common.no_undo',
        question: 'Delete the action item?'
      },
      follow_up: {
        done: 'We did it!',
        useful: 'And it was useful!'
      }
    },
    base: {
      copy_button: {
        copied: 'Copied!'
      },
      text_field: {
        n_characters_remaining:
          '0 characters remaining. | 1 character remaining. | %{n} characters remaining.'
      }
    },
    calendar_field: {
      days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
    },
    flash_messages: {
      archived_team: 'Your team was archived correctly.',
      cant_remove_owner_error:
        'You can’t remove this user from the workspace, since they are the owner of it.',
      email_verification_success: 'Your email has been confirmed',
      error_canceling_invitation:
        'An error ocurred when canceling the invitation. Please try again.',
      error_creating_retro: 'There was an error creating your retro. Please try again.',
      error_creating_team: 'There was an error creating your team. Please try again',
      error_deleting_workspace: 'There was an error deleting the workspace. Please try again',
      error_creating_workspace: 'There was an error creating your workspace. Please try again.',
      error_fetching_resource: 'Could not access or find the resource.',
      error_loading_workspaces: "We couldn't fetch your workspaces. Please try again.",
      error_removing_member: 'There was an error removing the member. Please try again',
      error_team_name_taken: 'A team with that name already exists, please choose a different one',
      invitations_failure: 'We had an issue sending invitations, please try again',
      last_teammate_error: "You can't leave the team because you are the last joined member",
      left_the_team: 'You left the team',
      password_update_success: 'Password updated successfully',
      permanent_sign_up_success: 'Thanks for joining! Your account is now permanent.',
      plan_changed: 'Plan updated successfully',
      team_update_failure: "Something went wrong and we couldn't update the team",
      teammate_removed: 'You removed %{name} from the team.',
      team_invitation_removed: 'You canceled the invitation to the team',
      unarchived_team: 'Your team was unarchived correctly.',
      unauthorized_action: 'You are not authorized to perform this action.',
      unauthorized_retro: 'You are not authorized to view this retro.',
      unexpected_error: 'An unexpected error occurred. Try reloading the page.',
      user_update_failure: "Something went wrong and we couldn't update your user",
      user_update_success: 'User has been updated successfully',
      workspace_deleted: 'Workspace deleted',
      workspace_member_removed: 'You removed %{name} from the workspace.',
      workspace_seats_updated: 'Workspace seats count has been updated.',
      workspace_update_failure: "Something went wrong and we couldn't update your workspace"
    },
    groups: {
      name: {
        default: 'Untitled group'
      },
      drag_here_new_group: 'Drag topic into a new group',
      add_to_new_group: 'Add to a new group',
      drag_here: 'Drag a topic into this group',
      add_to_this_group: 'Add to this group',
      x_is_renaming: "%{name} is editing group's name...",
      votes: 'No votes | 1 vote | %{n} votes',
      vote_button: {
        used_weight: 'You can only vote +%{weight} once.',
        group_voted: 'You can only vote each group once.'
      }
    },
    mobile_not_supported: {
      heading: 'Sorry!',
      subheading: "We don't support mobile yet.",
      back: 'Back to home page'
    },
    motivation_picker: {
      awesome: 'Awesome',
      awful: 'Awful',
      happy: 'Happy',
      meh: 'Meh',
      sad: 'Sad'
    },
    nav: {
      about_us: 'About Us',
      blog: 'Blog',
      get_started: 'Get Started Now',
      home: 'Home',
      pricing: 'Pricing',
      profile: 'Profile',
      retros: 'Retros',
      sign_in: 'Sign in',
      sign_out: 'Sign out',
      tooltips: {
        archive: 'Archived retros',
        locale: 'Cambiar a español',
        preferences: 'Go to user preferences',
        theme: {
          dark: 'Switch to dark mode',
          light: 'Switch to light mode'
        }
      }
    },
    new_retro: {
      participant_slots_picker: {
        you: 'you',
        slots_sr: 'Number of participants',
        only_moderate: 'I will not post any topics, just moderate'
      },
      participants_picker: {
        drawer_ending_button: 'Add new teammate',
        drawer_title: 'Teammates',
        i_participate_checkbox: "I'm writing and sharing as well",
        i_participate_text_1: 'Because you are creating this retro you are the ',
        i_participate_text_2: 'Moderator',
        not_in_team: 'New in the team',
        not_in_workspace: 'Not in %{workspace} yet'
      }
    },
    brief: {
      title: 'Retro brief',
      export: {
        title: 'Export',
        markdown: 'Copy Markdown'
      },
      action_plan: 'Action Plan',
      participants: 'Participants',
      groups_and_topics: 'Groups & Topics'
    },
    resend_email_button: {
      resend: 'Resend email',
      sent: 'Sent!'
    },
    review: {
      title: "We're almost done!",
      subtitle: 'Did we forget something?'
    },
    sidebar: {
      add_participant: 'Add participant',
      group: {
        default_name: 'Untitled Group',
        votes: 'No votes | 1 vote | %{n} votes'
      },
      moderator_control: {
        discuss: {
          button: '+1 min',
          subtitle_with_group_name: 'Discuss %{groupName}',
          subtitle_with_group_number: 'Discuss group %{groupNumber}',
          subtitle_without_group: 'Discuss',
          title: 'Time to'
        },
        grouping: {
          subtitle: 'Check with the team if groups looks good',
          title: 'Time to group'
        },
        posting: {
          subtitle: 'Sharing phase',
          title: 'Time left'
        },
        retro: {
          subtitle: 'Finish retro',
          title: 'Time left'
        },
        voting: {
          subtitle: 'Ask participant to vote which groups to discuss first',
          title: 'Time to vote'
        },
        writing: {
          subtitle: 'Writing phase',
          title: 'Time left'
        }
      },
      cta_control: {
        discuss: {
          subtitle: 'and take action!',
          title: 'Time to discuss'
        },
        grouping: {
          button: 'I have suggestions',
          subtitle_with_suggestions: 'Help the moderator with grouping',
          subtitle_without_suggestions: 'Would you like to make suggestions?',
          title: 'Time to group'
        },
        posting: {
          button: "I'm done sharing",
          other_subtitle: "It's %{name}'s turn",
          title: 'Time to share',
          you_subtitle: "It's your turn"
        },
        setup: {
          ice_breakers_button: 'Break the ice',
          ice_breakers_subtitle: 'Retro schedule for %{time}',
          ice_breakers_title: "It's chilling in here"
        },
        voting: {
          done_button: 'Keep voting',
          not_done_button: "I'm done voting",
          subtitle: 'Which group would you prefer discussing first?',
          title: 'Time to vote'
        },
        writing: {
          done_button: 'Keep writing',
          not_done_button: "I'm done writing",
          subtitle: 'You are in private mode',
          title: 'Write your topics'
        }
      },
      participants: {
        invited: 'Pending invitation',
        title: 'Participants'
      },
      topics: {
        title: 'Your topics',
        subtitle: 'Only you can see them'
      }
    },
    lobby: {
      heading: 'Hang in there while your teammates check-in',
      previous_action_items: 'Last Retro Action Items',
      ice_breakers: {
        asking_dialog: {
          cancel: 'Cancel',
          input_error: 'Question is too long, try asking a shorter one',
          input_placeholder: 'Ask something fun...',
          send: 'Send question',
          warm_up: 'Warm up the conversation by asking something fun to your teammates'
        },
        answering_dialog: {
          input_error: 'The answer must have a maximum of 40 characters',
          input_hint: 'Max. 40 characters',
          input_placeholder: 'Answer briefly...',
          send: 'Share with everyone'
        },
        dialog: {
          subtitle:
            'Icebreaker questions are a great way to engage your team before a retrospective meeting.',
          title: 'Let’s break the ice!'
        },
        free_modal: {
          title: 'Ice breakers is a PRO feature',
          paragraph_1:
            '👉Icebreaker questions are a great way to engage your team before a retrospective meeting.',
          paragraph_2:
            '👉Build a deep connection with your team by asking them fun, open-ended questions.',
          paragraph_3:
            '👉The outcome of the retrospective can benefit from these types of activities.',
          upgrade_suggestion: 'Upgrade your plan to use Ice breakers and more…',
          accept: 'Got it'
        }
      }
    },
    waiting_room: {
      heading: 'Please wait for the moderator to let you in'
    },
    writing: {
      done: 'Wait while your teammates finish writing',
      watcher: 'Wait while the participants write their topics'
    },
    progress: {
      title: 'Retro Progress',
      close: 'Close',
      phases: {
        lobby: 'Lobby',
        write: 'Write',
        share: 'Share',
        group: 'Group',
        vote: 'Vote',
        discuss: 'Discuss',
        review: 'Review'
      },
      user_list: 'Users',
      start_writing: 'Start writing',
      start_posting: 'End writing phase',
      end_other_turn: "End %{name}'s turn",
      start_voting: 'Groups look good!',
      start_discussion: 'End voting phase',
      next_group: {
        writing: 'Finish writing the action item to advance.',
        other_writing:
          'Another participant is writing an action item, please wait for them to finish. As moderator, you can also force submit the action item.',
        cta: 'Next'
      },
      start_review: 'Go to Review',
      finish_retro: 'Finish Retro',
      dialogs: {
        start_writing: {
          heading: 'Are you sure you want to start writing?',
          before: 'Some participants are missing.',
          cancel: 'No, stay in the lobby',
          confirm: 'Yes, start writing'
        },
        start_posting: {
          heading: 'Are you sure you want to start sharing?',
          before: 'Some participants are not done writing yet.',
          cancel: 'No, keep writing',
          confirm: 'Yes, start sharing'
        },
        your_turn: {
          heading: "Now it's your turn to share.",
          after: 'Drag your topics into groups to make them public.',
          confirm: 'Got it'
        },
        end_turn: {
          before: {
            topics_missing: 'You did not share all your topics.'
          },
          heading: 'Are you sure you want to end your turn?',
          after: {
            topics_missing: 'Those topics will be lost.',
            no_undo: '@:common.no_undo'
          },
          cancel: 'No, keep sharing',
          confirm: 'Yes, end turn'
        },
        end_other_turn: {
          heading: "Are you sure you want to end %{name}'s turn?",
          after: '%{name} wrote %{n} topics. @:common.no_undo',
          cancel: 'No, let them keep sharing',
          confirm: 'Yes, end their turn'
        },
        ok_with_groups: {
          cancel: {
            moderator: 'Yes, I want to make changes',
            participant: 'Yes, I have suggestions'
          },
          confirm: "I'm okay",
          heading: 'Would you change how the topics are grouped?'
        },
        start_voting: {
          confirm: 'Yes, start voting',
          cancel: 'No, keep grouping',
          before: 'Please check that the participants are okay with the groups.',
          heading: 'Are you sure you want to start voting?'
        },
        start_discussion: {
          confirm: 'Yes, start discussion',
          cancel: 'No, keep voting',
          before: 'Some participants are still voting.',
          heading: 'Are you sure you want to start discussion?'
        },
        choose_review_or_next_group: {
          heading: 'What do you want to do?',
          after: 'You’ll be able to add more actions later at review step.',
          time_left: 'You have {0} left for the retro.',
          discuss_group_n: 'Discuss group %{n}',
          go_to_review: 'Go to review',
          cancel: 'Back'
        },
        next_group: {
          headings: {
            how_much_time: 'How much time to discuss Group %{n}?',
            one_more: 'Discuss one more group or go to review?'
          },
          after: {
            add_more_time: 'You can add more time later.',
            time_to_review: "You'll need a couple minutes to review the Action Plan."
          },
          time_left: 'You have {0} left for the retro.',
          go_to_review: 'Go to review',
          cancel: 'Back',
          one_more: 'One more group (3 min)'
        },
        start_review: {
          confirm: 'Yes, go to review',
          cancel: 'No, keep discussing',
          heading: 'Are you sure you want to go to review?'
        },
        finish_retro: {
          confirm: 'Yes, end retro',
          cancel: 'No, keep discussing',
          heading: 'Are you sure you want to end the retro?'
        }
      }
    },
    topics: {
      cancel: '@:common.cancel',
      delete: {
        tooltip: 'Delete',
        question: 'Are you sure you want to delete the topic?',
        after: '@:common.no_undo',
        cancel: '@:common.cancel',
        confirm: '@:common.delete'
      },
      edit: {
        tooltip: 'Edit'
      },
      new: {
        add: '@:common.add',
        cancel: '@:common.cancel',
        clear: 'Clear',
        edit_has_no_changes: 'You have no changes to save',
        edit_topic: 'Edit Topic',
        empty_description: 'Description cannot be empty',
        heading: "It's time to write",
        new_topic: 'New Topic',
        no_topic_type: 'Select a topic type first',
        placeholder: 'Write a new topic...',
        save: 'Save',
        subheading: 'Topics are private until you choose to share them',
        too_long: 'Too long! Try to keep it brief.',
        topic_limit_reached:
          "You've reached the topic limit. You can still edit and/or delete your topics if you like."
      },
      private_topics: {
        write_up_to_n: 'Write up to %{n} topics.'
      },
      reaction_pill: {
        and: '@:common.and'
      },
      share: 'Share >',
      sharing: {
        wrote: 'wrote'
      }
    },
    users: {
      add_participant_slot: {
        add: 'Add participant'
      },
      badges: {
        alert: 'Has suggestions',
        awesome: 'Awesome',
        awful: 'Awful',
        disconnected: 'Offline',
        done: 'Done',
        happy: 'Happy',
        meh: 'Meh',
        sad: 'Sad',
        working: 'Working'
      },
      context: {
        end_turn: 'End turn',
        make_moderator: 'Make moderator',
        remove_participant: 'Remove from retro',
        remove_slot: 'Remove slot'
      },
      empty_slot: 'Waiting for participant',
      kick: {
        after:
          "You're about to remove %{name} from the retro. When you do, they will leave the retro and won't be able to join again.",
        cancel: 'No, let them stay',
        confirm: 'Yes, remove them',
        question: 'Remove %{name}?'
      },
      labels: {
        done_voting: 'Done voting',
        done_writing: 'Done writing',
        has_suggestions: 'Has suggestions',
        moderator: '@:common.moderator',
        sharing: 'Sharing'
      },
      participant_status: {
        moderator: '@:common.moderator',
        online: 'Online'
      },
      title: 'Users',
      waiting_room_list: {
        accept_all: 'Accept all',
        title: 'Accept or reject participants who want to join retro'
      },
      waiting_room_list_item: {
        accept: 'Accept',
        reject: {
          cancel: '@:common.cancel',
          confirm: 'Yes, reject',
          initial: 'Reject'
        },
        temporary_user: 'Temporary user'
      }
    },
    user_password_form: {
      cancel: 'Cancel',
      forgot_password: 'Forgot password?',
      new_password_confirmation: 'Confirm new password',
      new_password: 'New password',
      password: 'Current password',
      password_should_be_longer: 'Password needs to be longer than 8 characters',
      passwords_dont_match: 'Passwords do not match',
      password_already_in_use: 'Password already in use',
      save: 'Save'
    },
    user_profile_form: {
      accepted_emails: 'I agree to receive messages from Retroally',
      cancel: 'Cancel',
      change_password: 'Change password',
      email: 'Email address',
      google_password:
        'You signed up with a Google account. That is why you cannot change your email or set a password.',
      name: 'Name',
      no_password: 'No password set',
      password: 'Password',
      save: 'Save'
    },
    join_retro: {
      welcome: 'Welcome, %{name}',
      already_started: 'The retro has already started. You can still join!',
      join: 'Join retro',
      watch: "I'll just watch",
      join_call: {
        text: "Don't forget to also {0} if you haven't already.",
        link: 'join the call'
      },
      not_listed:
        'You were not listed as a participant in this retro. If you think this is a mistake, please contact the moderator. You can also watch the retro.',
      no_slots:
        'There are no more participant slots for this retro. If you want to join, contact the moderator.'
    },
    login_form: {
      form: {
        email: 'Email address',
        forgot_password: 'Forgot your password?',
        minimum: 'Minimum 8 characters',
        no_account: 'Don’t have an account?',
        password: 'Password',
        submit: 'Sign in'
      },
      google_user: 'Email registered with a Google account. Please log in using Google',
      invalid_credentials: 'Invalid email or password',
      legal: {
        acceptance: 'By proceeding you agree to our {tos} and {privacy_policy}',
        privacy_policy: 'Privacy Policy',
        tos: 'Terms & Conditions'
      },
      sign_up: {
        name: 'Name',
        submit: 'Sign up'
      }
    },
    motivation_form: {
      description: 'Please enter how you feel about the team',
      join_call_text_1: "Don't forget to also ",
      join_call_text_2: " if you haven't already",
      join_call_link: 'join the call',
      link_copied: 'Copied!',
      no_motivation: 'Select a motivation level to continue',
      retro_link: "Here's the link to your retro.",
      submit: '@:common.submit',
      too_early: 'This retro starts at %{datetime}. Please come back to this page at that time.',
      time_on_date: '%{time} on %{date}',
      title: 'How are you feeling?'
    },
    pricing: {
      title: "Choose the plan that's right for your workspace",
      subtitle: 'No credit card required',
      pricing_options: {
        annual: 'person/month Billed annually',
        buy_pro: 'Buy PRO',
        choose_free: 'Choose STARTER',
        choose_pro: 'Choose PRO',
        coming_soon: 'Coming soon',
        current_plan: 'Current plan',
        free_limit_reached: 'Limit reached',
        get_started: 'Get started now',
        monthly: 'person/month Billed month-to-month',
        selected_plan: 'Selected plan',
        update_to_free: 'Downgrade to STARTER',
        update_to_pro: 'Update to PRO',
        what_is_included: "What's included",
        already_own_this_plan: 'You already own a workspace with this plan',
        plans: {
          starter: {
            name: 'Starter',
            free: 'Free',
            subtitle: 'Forever'
          },
          pro: {
            name: 'Pro'
          },
          premium: {
            name: 'Premium'
          }
        },
        features: {
          run_retros: 'Run unlimited retrospectives',
          retro_activities: 'Retrospective activities',
          action_items: 'Action items',
          teams: 'Unlimited teams',
          ice_breakers: 'Ice Breakers',
          slack: 'Slack integrations',
          health_checks: 'Team Health Checks',
          support: 'Priority support',
          customer_success_manager: 'Dedicated customer success manager',
          onboarding: 'Personalized onboarding program'
        },
        free_activities_limit: 'Up to 2',
        refund_policy: '45 days money-back guarantee',
        mobile_activities: {
          starter: 'Up to 2 retrospective activities',
          pro_and_premium: '+10 retrospective activities'
        },
        toggle: {
          monthly: 'Monthly',
          annual: 'Annual'
        }
      },
      faqs: {
        title: 'Frequently Asked Questions',
        first: {
          question: 'How does your pricing model work?',
          answer:
            'We charge by seat on the account. A person may be part of multiple teams, but we count that as a single seat being used.'
        },
        second: {
          question: 'Do you offer a free trial?',
          answer:
            "You can sign up for the Pro account and enjoy a 45-day window where you can cancel your subscription at any time and we'll refund every single dime, no questions asked."
        },
        third: {
          question: 'What is a Team?',
          answer:
            'A Team is a group of users who usually work together. Organizing users in teams allows you to track their progress and action items. Users can be part of multiple teams.'
        },
        fourth: {
          question: 'Is there a limit to the amount of teams my account could have?',
          answer: 'You can create as many teams as you want.'
        },
        fifth: {
          question: 'What forms of payment do you accept?',
          answer:
            'Pro customers must pay by credit card. Premium customers can choose to request invoices and pay via ACH or wire transfers.'
        },
        sixth: {
          question: 'How do I cancel my subscription?',
          answer: "While we'd hate to see you go, you may cancel your subscription here."
        }
      }
    },
    retro_list: {
      activity: 'Activity',
      date: 'Date',
      moderator: 'You are the moderator',
      name: 'Name',
      participants: 'Participants',
      search: 'Search',
      search_by: 'Retro name',
      status: {
        title: 'Status',
        in_progress: 'In progress',
        finished: 'Finished',
        created: 'Created'
      }
    },
    share_link_dialog: {
      before: 'Now you can share the retro with your team.',
      heading: "Here's the link to your retro.",
      after:
        'If you ever need to find it again, click on the retro name on the top left or copy the URL in your address bar.',
      cancel: 'Close without copying',
      confirm: 'Copy link and close'
    },
    sign_up_form: {
      accepted_emails: 'I agree to receive messages from Retroally',
      email: 'Email address',
      email_taken: 'Email has already been taken',
      invalid_email: 'Invalid email',
      invalid_name: 'Invalid name',
      name: 'Name',
      new_password: 'Enter password',
      new_password_requirement: 'Minimum 8 characters',
      new_password_too_short: 'The password must have a minimum of 8 characters',
      password_confirm: 'Confirm password',
      password_confirmation_mismatch: 'Password confirmation does not match',
      submit: 'Sign Up',
      update: 'Update'
    }
  },
  errors: {
    email: {
      invalid: 'Invalid email'
    },
    name: {
      empty: 'Name can not be empty',
      too_long: 'Name is too long, try using a shorter one'
    },
    no_changes: 'No changes made',
    unavailable_seats:
      'You ran out of available seats, you can only invite existing workspace users'
  },
  views: {
    workspaces: {
      change_plan: {
        choose_plan_title: 'Choose your new plan',
        features_title: 'Are you sure you want to downgrade your workspace plan?',
        features_subtitle: 'By downgrading you will lose:',
        choose_team_title: 'Choose the team you want to keep',
        choose_team_subtitle:
          "By downgrading your plan you won't be able to have more than 1 team, the rest of the teams will be archived",
        step_1: 'Change plan',
        step_2: 'Features',
        step_3: 'Choose Team',
        select_a_team: 'Select a team to preserve',
        select_new_plan: 'Select a new plan',
        next: '@:common.next',
        previous: '@:common.previous',
        downgrade: 'Downgrade',
        downgrade_now: 'Downgrade now',
        upgrade: 'Upgrade',
        retro_in_progress: 'Retro in progress',
        retro_in_progress_warning:
          'You have in-progress retros, by downgrading now the teams won’t be able to finish the retrospective. Do you want to downgrade anyway?',
        cancel: '@:common.cancel'
      },
      delete: {
        cancel: '@:common.cancel',
        confirmation_text:
          'By clicking in "Delete workspace" all your workspace information such as workspace users, teams & retrospectives as well will be destroyed.',
        contract_term_ends: 'Your contract term ends on %{term}',
        delete: 'Delete workspace',
        dialog: {
          cancel: '@:common.cancel',
          confirm: 'Yes, delete',
          subtitle: 'Your subscription will be revoked on %{term}',
          title: 'Are you sure you want to delete the workspace?'
        },
        title: 'Delete workspace',
        warning_text:
          "You and all workspace users won't be able to enter or get any longer workspace information."
      },
      edit: {
        avatar: 'Workspace picture',
        billing_details: 'Billing details',
        cancel: '@:common.cancel',
        change_plan: 'Change plan',
        delete: 'Delete workspace',
        general: 'General',
        manage_payment_info: 'Manage payment info',
        manage_seats: 'Manage seats',
        money_guarantee: '45 days money back guarantee',
        name: 'Workspace name',
        next_billing_date: 'Your next billing date is %{date}',
        plan_details: 'Plan details',
        plan_name_free: 'STARTER',
        plan_name_pro: 'PRO',
        save: '@:common.save',
        settings: 'Workspace settings',
        subscription_and_billing: 'Subscription & Billing',
        total_seats: 'Total seats'
      },
      index: {
        create_workspace: 'Create workspace',
        different_workspace: 'Looking for a different workspace?',
        different_email: 'Try using a different account',
        heading: 'Create a workspace for your teams',
        or_open: 'or select your workspace',
        subheading:
          'Retroally allows you to have all the teams you want within your workspace, this way you can keep track of retros, action plans and much more.'
      },
      new: {
        create_workspace: 'Launch Workspace',
        invalid_emails: 'There is at least one invalid email',
        next: '@:common.next',
        previous: '@:common.previous',
        workspace: {
          heading: 'Create a workspace for your teams',
          subheading:
            'Retroally allows you to have all the teams you want within your workspace, this way you can keep track of retros, action plans and much more.',
          title: 'Workspace'
        },
        teamCreation: {
          heading: 'Awesome! Let’s start!',
          subheading:
            'Retroally is organized by teams, users will be able to join multiple teams. No additional charge. Later on you will be able to create as many teams as you want.',
          title: 'Team'
        },
        invites: {
          heading: 'Last one! Invite your teammates',
          subheading: 'Invite your teammates to join the team and start making effective retros',
          title: 'Teammates'
        },
        choosePlan: {
          heading: 'Choose the plan that’s right for your workspace',
          subheading: '',
          title: 'Plan'
        }
      },
      members: {
        add_user: 'Add user',
        cancel_invite: 'Cancel invitation',
        email_col: 'Email',
        joined_col: 'Joined',
        name_col: 'User name',
        remove_member: 'Remove from workspace',
        remove_member_modal: {
          body: 'When you remove a user, this one cannot access the workspace, team and team retros anymore.  Are you sure you want to remove it anyway?',
          cancel: '@:common.cancel',
          confirm: 'Yes, remove it',
          header: 'Remove from %{workspace}?'
        },
        resend_invite: 'Resend invite',
        role_col: 'Role',
        sent_invite: 'Sent',
        title: 'Workspace users',
        buy_more: 'Buy more',
        seats_available: '%{taken} seats taken, %{available} available'
      },
      seats: {
        buy: 'Buy seats',
        delete: 'Delete seats',
        title: 'Manage seats'
      }
    },
    teams: {
      new: {
        buySeats: {
          caption: 'How many do you need?',
          heading: 'Add seats for them',
          seats_available: '%{taken} seats taken, %{available} available',
          subheading: 'Teammates will be able to join this and infinite teams in the workspace',
          title: 'Buy seats'
        },
        create_team: 'Create Team',
        invalid_emails: 'There is at least one invalid email',
        invalid_seats_amount:
          'You have to buy at least %{seats} seats to cover all your new invitations',
        invalid_one_seat_amount: 'You have to buy at least 1 seat to cover your new invitation',
        invites: {
          heading: 'Last one! Invite your teammates',
          subheading: 'Invite your teammates to join the team and start making effective retros',
          title: 'Teammates'
        },
        next: '@:common.next',
        previous: '@:common.previous',
        teamName: {
          heading: 'Create Team',
          subheading: 'Retroally is organized by teams, users will be able to join multiple teams.',
          title: 'Team'
        }
      },
      edit: {
        archive: 'Archive team',
        archive_dialog: {
          cancel: '@:common.cancel',
          confirm: 'Yes, archive',
          text: 'When you archive a team, you and your teammates will not be able to interact with it. All team information and data from past retrospectives will be stored on our servers.',
          title: 'Archive team?'
        },
        cancel: '@:common.cancel',
        edit_team: 'Edit team',
        modal: {
          before_leave: 'Are you sure you want to leave? You have unsaved changes',
          heading: 'Are you sure you want to undo your changes?',
          confirm: 'Yes, undo them'
        },
        team_name: '@:common.team_name',
        save: '@:common.save',
        sidebar: {
          add_teammate: 'Add teammate',
          teammates: 'Teammates',
          modal: {
            cancel: '@:common.cancel',
            leave: {
              confirm: 'Yes, leave',
              description:
                "By leaving the team, you won't be able to access the team and team retros anymore. Are you sure you want to leave anyway?",
              heading: 'Leave the team?'
            },
            remove: {
              confirm: 'Yes, remove it',
              description:
                "When you remove a teammate, they won't be able to access to the team and team retros anymore. Are you sure you want to remove them anyway?",
              heading: 'Remove from the team?'
            }
          },
          menu: {
            leave: 'Leave Team',
            remove: 'Remove from Team',
            cancel_invite: 'Cancel invitation'
          },
          pending_invite: 'Pending invitation'
        }
      },
      show: {
        content: {
          create_retro: 'Create retro',
          empty_title: 'You did a great work!',
          empty_subtitle: 'Create your first retro with the team',
          retros: 'Retros'
        },
        sidebar: {
          maturity: {
            1: 'Forming',
            2: 'Storming',
            3: 'Norming',
            4: 'Performing'
          },
          pending_invite: 'Pending invitation',
          teammates: 'Teammates',
          team_maturity: 'Team Maturity'
        },
        card: {
          pending: 'Pending invitation'
        }
      },
      archived: {
        no_archived_teams: 'You currently have no archived teams',
        retroally_starter:
          'Retroally STARTER plan only allows 1 team for free. Upgrade now or archive all your teams.',
        search_by: 'team name',
        team_name: 'Team Name',
        teammates: 'Teammates',
        title: 'Archived teams',
        unarchive_team: 'Unarchive team',
        unarchive_teams: 'Unarchive teams?',
        upgrade_button: 'Upgrade now'
      }
    },
    auth: {
      check_your_inbox: {
        change_email: 'Change email',
        contact_us: '@:common.contact_us',
        message: 'Click the link we sent to{0}{1} to complete your account set-up',
        subheading: 'Confirm your email within the next 5 days after creating the account',
        title: 'Check your inbox'
      },
      complete_account: {
        form: {
          email: 'Email address',
          email_taken: 'Email has already been taken',
          invalid_email: 'Invalid email',
          new_password: 'Set password',
          new_password_requirement: 'Minimum 8 characters',
          new_password_too_short: 'The password must have a minimum of 8 characters',
          password_confirm: 'Confirm password',
          password_confirmation_mismatch: 'Password confirmation does not match',
          submit: 'Complete account'
        },
        heading: 'Complete your account',
        subheading:
          "We're working on some cool features that need all our users to have an email address associated. Starting February 1st 2022 you won't be able to use this account anymore unless you complete it"
      },
      confirmation_expired: {
        back_home: 'Back home',
        check_email: 'Check your email!',
        new_link: 'Email me a new link',
        expired:
          'We will send you a new link to{break}{email} to complete{break}your account set-up',
        follow_link:
          'Click the link we have sent to{break}{email} to complete{break}your account set-up',
        link_expired: 'Sorry, your link expired!'
      },
      reset_email: {
        password_recovery_title: 'Forgot your password?',
        password_recovery_message: 'Enter the email you use for retroally.com',
        submission_title_made: 'Check your email!',
        submission_message_made: 'We have sent password reset instructions to',
        contact_us: '@:common.contact_us',
        form: {
          email: 'Email address',
          placeholder: "whatever{'@'}domain.com",
          submit: 'Reset Password',
          back: 'Back to sign in'
        }
      },
      reset_password: {
        form: {
          password: 'New password',
          password_confirmation: 'New password confirmation',
          submit: 'Change Password'
        },
        invalid_link: 'Invalid link, please try sending the reset email again',
        mismatched_passwords: 'Passwords do not match',
        password_should_be_longer: 'Password needs to be longer than 8 characters',
        reset_password: 'Reset password'
      },
      sign_in: {
        sign_in_to_join: 'Start retro by signing in',
        title: 'Sign In'
      },
      sign_up: {
        back_home: 'Back home',
        create_your_account: 'Enter your personal information to create an account',
        requires_permanent_users: 'This retro requires an account with a registered email',
        title: 'Nice to meet you!',
        update_your_email: 'Update your account email'
      }
    },
    error: {
      go_home: 'Go home',
      title: "Looks like you are lost, let's go home"
    },
    join_retro_error: {
      title: 'Sorry!',
      message:
        "The retro you are attempting to join either doesn't exist or you don't have access to it.",
      go_home: 'Go home'
    },
    kicked_out_of_retro_error: {
      title: 'Sorry!',
      message: "It seems that you've been kicked out of the retro.",
      go_home: 'Go home'
    },
    landing: {
      hero: {
        heading: 'Do you want to feel more productive during retrospective meetings?',
        cta: 'Get Started Now',
        make_retros_effective: 'Make retros effective',
        fruitful_discussions: 'Engage in fruitful discussions',
        improve: 'Improve in the next iteration'
      },
      easy_set_up: {
        heading: 'Easy set-up',
        body: "Have you ran out of ideas? Is it hard to plan an effective meeting? Want to know what's best for your current situation? We can suggest you the best alternative from a wide range of formats."
      },
      timeboxed_flow: {
        heading: 'Natural time-boxed flow',
        body: "We organize the meeting in steps so it's easier to follow and organize the team's interaction while staying on time."
      },
      write_in_private: {
        heading: 'Write in private',
        body: "You don't have to post everything you write. Write in private and let your thoughts flow. Share your topics later one by one."
      },
      discuss_and_collaborate: {
        heading: 'Discuss and Collaborate',
        body: 'Group topics, vote, discuss and come up with action plans so that you perform better on the next iteration.'
      },
      start_free: {
        heading: 'Start using RetroAlly, for free!',
        subheading: 'Effective retros for effective teams.',
        cta: 'Get Started'
      },
      footer: {
        contact_us: '@:common.contact_us'
      },
      customer_reviews: {
        heading: 'Customer Reviews',
        subheading: 'What our customers are saying about RetroAlly',
        customers: {
          alain_chuard: {
            company: 'CTO & Founder at Prisma',
            name: 'Alain Chuard',
            review:
              "We've been using RetroAlly for some time now, and everyone on the team loves it. I'm very impressed with RetroAlly and the benefit it has brought to our team.",
            title: 'The team loves it'
          },
          felipe_cordoves: {
            company: 'Agile project manager at Globant',
            name: 'Felipe Cordoves',
            review:
              'A few months ago we started using RetroAlly and since then it has been with us in every retro, renovating the way our team does feedback. The UI is friendly and intuitive, adapting it to our dynamic has been quick and it has contributed order in a large team.',
            title: 'Friendly and intuitive'
          },
          jordi_adame: {
            company: 'Engineering Director at TheRealReal',
            name: 'Jordi Adame',
            review:
              'We’ve been using RetroAlly for over a year and it has helped us build stronger teams by encouraging participation and catering to each team’s needs through different dynamics. RetroAlly helps us create better documentation for our retro meetings as well as following up on agreed action items.',
            title: 'Helps us build stronger teams'
          }
        }
      }
    },
    legal: {
      license_subscription: {
        heading: 'License subscription',
        background: {
          heading: 'FIRST. (Background).',
          1: '"RetroAlly LLC" (hereinafter the "LICENSOR") has autonomously and independently developed the technological platform - "SOFTWARE" or "PLATFORM" - named "RETROALLY" which provides its users (hereinafter the "LICENSEES") a project management system focused mainly on the retrospective analysis of "sprints" or goals, with interactive functionalities for comments, meetings, discussions, voting and topic clusters, assisted by artificial intelligence tools, which generate as a final result a high-valued added final report.',
          2: 'The LICENSOR is the owner of all the contents belonging to "RETROALLY", either those that are visualized as well as those that cause it to operate and function. The brands, logos, badges, technologies, object and source code, processes and any other contents subject to protection by intellectual property are the exclusive property of the LICENSOR.'
        },
        object: {
          heading: 'SECOND. (Object).',
          1: 'The LICENSOR hereby grants the LICENSEES a free, non-exclusive and revocable license subscription (hereinafter the "LICENSE") on RETROALLY that allows the latter to use the functionalities described in "1.1.", subject to the acceptance of the present terms and conditions and the termination of the subscription process in accordance with the data requested by the PLATFORM.\nDuring the subscription process, the LICENSEES may opt for an onerous license which shall provide the same with the additional functionality or capabilities established in the subscription process itself.',
          2: 'The LICENSEES hereby acknowledge that, in the event the data entered in the subscription process represent a third-party entity - either through the use of a corporate email account or other data provided - its acceptance shall be deemed as the acceptance of said entity, being validly associated to the present terms and conditions. In this sense, the accepting party hereby states it has the necessary capacity to accept these terms and conditions on its behalf or on behalf of third parties and that the same constitute legal, valid and binding obligations.',
          3: 'The LICENSEES must ensure that the names and username and passwords (the account) generated in the subscription process to access the PLATFORM, are safe, confidential and shall remain exclusively in the LICENSEES’ possession at all times. The LICENSOR shall not be liable for the loss, misuse or alteration of data in the system as a result of undue handling of the account credentials. All interfaces and interactions of the PLATFORM shall, at all times, have average security measures inplace as the ones used in the industry.'
        },
        terms: {
          heading: 'THIRD. (Terms).',
          1: 'The LICENSOR reserves the right at any time to modify the SOFTWARE, altering, incorporating or deleting modules or functionalities, as well as to modify its processes and interfaces. The same may make these modifications at its sole discretion.',
          2: 'The LICENSOR shall use its reasonable efforts consistent with the average industry standard to keep the PLATFORM operating so as to minimize possible errors or interruptions. Notwithstanding this, the operation of the PLATFORM may be temporarily unavailable due to maintenance or otherreasonable causes. The LICENSOR neither guarantees nor undertakes that the services shall not be interrupted, operate free of errors, or suffer any loss of stored contents. The functionalities generated by the PLATFORM, including the final reports, are provided "as is" without express or implied warranty of any kind.'
        },
        personal_data: {
          heading: 'FOURTH. (Personal data).',
          1: 'The personal data collected during the subscription process shall be used for the sole purpose of granting the LICENSE. They shall not be used for any other purpose except compliance with the present terms and conditions and the provision of the LICENSE, and shall not be transmitted to any third party. Without prejudice to this, the LICENSOR may eventually send promotional information or offers of its own products or services or that of third parties.',
          2: 'The LICENSOR shall not handle in any way the personal data or information of any nature of the LICENSEES or entered by the latter in the PLATFORM. These data or information shall only be used so that the PLATFORM can provide its functionality and shall be protected by the average security measures used by the industry.',
          3: "The LICENSOR's activity is limited to granting a license subscription of the SOFTWARE. LICENSEES are the sole responsible parties for the way they use the SOFTWARE including publications, activities or, in general, any other inappropriate use. Under no circumstance shall the LICENSOR be responsible, directly or indirectly, in any way whatsoever for the actions of the LICENSEES."
        },
        contents: {
          heading: 'FIFTH. (Contents).',
          1: 'The LICENSEES shall not use the SOFTWARE or include contents that are contrary to the applicable regulations. This includes, but is not limited to, the publication of contents that are discriminatory, violent, pornographic, materials protected by the intellectual property of third parties, confidential information of third parties, etc.',
          2: 'THE LICENSEES guarantee that the information stored on the platform is lawful and that does not harm the property or rights of third parties. In each applicable case, the same declare having acquired and possessing all the rights to the comments and contents stored on the platform. The LICENSOR shall not be responsible, in any way whatsoever, for any information or contents of any nature stored by the LICENSEES.',
          3: 'The LICENSEES must refrain from publishing links to third party sites that infringe the content policy set forth by the present terms and conditions.',
          4: 'In all cases, the LICENSOR may unilaterally, discretionally and without any liability remove any contents it deems contrary to these terms and conditions, without prejudice to the termination of the LICENSE.',
          5: 'The LICENSEES hereby authorize the LICENSOR to provide their personal data, content of information or publications made or hosted on the PLATFORM, to any police, judicial or administrative authority that formally requests the information from the LICENSOR within the framework of a duly accredited police or jurisdictional process.',
          6: 'The LICENSOR shall not be obliged to keep records or contents of the LICENSEES, including the final reports. The PLATFORM is not a data hosting service; therefore, the LICENSEES must take the corresponding safeguard measures in order to safely host their information and contents in terms of stability and duration.'
        },
        indemnity: {
          heading: 'SIXTH. (Indemnity).',
          1: 'The LICENSEES expressly hold the LICENSOR harmless from and against any civil, commercial, labor, tax, criminal, administrative or any other type of liability arising from the use made by the former of the LICENSE.',
          2: "The LICENSEES shall defend and hold the LICENSOR, its employees, directors, representatives and suppliers harmless from and against any loss, expense, damage or costs - including attorney's fees – arising from a breach of the present terms and conditions, either directly or by third parties making use of the former’s account."
        },
        termination: {
          heading: 'SEVENTH. (Termination).',
          1: 'The LICENSOR may at any time terminate the license subscription and block the LICENSEES, if the former detects that its service is being used for any other purpose than the one established in the present terms and conditions. Likewise, the LICENSOR shall have the same power if the same has reasonable doubts that the LICENSEES are carrying out illegal, irregular, fraudulent operations, tending to deception or in breach of the present terms and conditions.',
          2: 'Furthermore, the LICENSOR is empowered to temporarily suspend LICENSEES’ accounts until it obtains all the information it deems sufficient in order to verify the activity and lawful purpose and pursuant to the present terms and conditions.',
          3: 'Regardless of the cases specifically provided, the LICENSOR may terminate the license subscriptions it provides in a general and definitive manner to any user by issuing a 7-consecutive-dayprior notice.'
        },
        amendments: {
          heading: 'EIGHTH. (Amendments).',
          content:
            'The present terms and conditions may be amended by the LICENSOR, at any time, provided it notifies the LICENSEES of the said amendments. This notification, as any other notification to which these terms and conditions refer to, may be made in the application itself or to the email address provided during the subscription process.'
        },
        law_and_jurisdiction: {
          heading: 'NINTH. (Applicable Law and Jurisdiction).',
          content:
            'This agreement shall be governed by the applicable laws in the Eastern Republic of Uruguay and any dispute relating to the fulfillment of the obligations arising herefrom shall be resolved before the ordinary judicial courts of Montevideo.'
        },
        miscellaneous_provisions: {
          heading: 'TENTH. (Miscellaneous provisions).',
          1: 'The omission in the exercise of any of the rights derived herefrom shall not constitute a waiver of the same or of other rights derived from this agreement, unless it is stated in writing by the party waiving said rights.',
          2: 'The total or partial nullity of any of the clauses contained herein, shall not affect or influence, in any way whatsoever, the remaining provisions that shall remain fully effective and valid.',
          3: 'All the contents of this web page/application, whether visible, oral or those that allow the operation of the same, are protected by intellectual property rights and are the exclusive property of the LICENSOR, with the exception of those contents stored by the LICENSEES when using the PLATFORM. By way of example, the graphics, related colors, interface design, audios, logos, software, information and all other contents that allow the operation of the website and its applications for smartphones are the exclusive property of the LICENSOR; its reproduction, copying or unauthorized being prohibited use for any purpose whatsoever.',
          4: 'In the event that an onerous LICENSE with different functionalities is eventually established, the subscription process shall determine the price and payment conditions.'
        }
      },
      privacy_policy: {
        heading: 'Privacy policy',
        paragraphs: {
          '1': 'RetroAlly LLC (hereinafter “Retroally”) is the owner of the Website, contents and services which are hosted on the domain name called www.retroally.com (hereinafter the “site”).\nAccess to or use of the Site implies the acceptance and consent for Retroally to collect information that may eventually contain personal data as per the terms provided for in this Privacy Policy.',
          '2': 'Retroally must treat the personal data provided by the Users of the Site exclusively in accordance with the manner and purpose detailed under this Privacy Policy and/or registration forms, and in strict compliance with the provisions of the regulatory standards on the treatment and protection of personal data of the Eastern Republic of Uruguay, especially as provided by Law 18831 and Decrees 664/08, 414/09 and 308/14.',
          '3': 'Retroally shall maintain in strict confidentiality and reserve the personal data accessed to, using such data only for the purpose it was supplied for and shall not give access to it for any purpose. Without prejudice to the provisions, Retroally may:',
          '3a': 'Provide third parties with statistical information, provided that said information is treated in a generic way, not allowing the identification of individual Users',
          '3b': 'Retroally may disclose personal data or other information to third parties (data processor) when said information is required to comply with the relationship between Retroally and the User, such as the fulfillment of the functionalities, services or tools provided for by the Website.',
          '3c': 'Retroally may also deliver information to third parties as required by law upon written request by administrative or jurisdictional authorities.',
          '3d': 'Retroally may send users material with offers or information on other products or services, of its own or those of third parties.',
          '4': 'The information collected under this Privacy Policy may be hosted on own or contracted servers, in the territory of the República Oriental del Uruguay or abroad, Retroally being obliged in all cases to maintain a level of protection according to the average standard of the industry. In this sense, Retroally undertakes, as obligation of means, the implementation of all security measures likely to prevent unauthorized third parties from accessing the data, including the imposition of physical security measures. Likewise, access to data shall be limited to employees or subcontractors who must access data for service reasons.',
          '5': 'Retroally is only bound with regards to the use made by the User when entering directly to the Website. Thus, not being responsible in the event the User accesses it through the redirection from third-party sites or applications.',
          '6': 'The User is solely responsible for the use of his/her password and for the personal data provided, and must not share said data with third parties. The password must be modified periodically   in   accordance   with   the   recommendations   set   forth   on   the   Website   and   the expressly   authorized   communications.   The   User   must   refrain   from   responding   to   any communication received in a manner not specifically agreed or specified on the Website.',
          '7': 'The User declares that the data supplied to the Website is true, complete, truthful and accurate, being therefore directly responsible for any negative consequence that a breach of the above generates on himself/herself or third parties. The User must, at any time, keep such data updated and inform the Website with respect to any changes that are relevant to the scope of this Privacy Policy.\nThe User shall have the possibility, at any time, to edit his/her personal information in his/her user profile, but in all cases abiding by the provisions of the preceding paragraph.',
          '8': 'Retroally may modify this Privacy Policy unilaterally. However, in all cases, the modifications shall come into effect on the fifth business day counted as of the date when the same have been published on the Website, or informed to the User through the email provided during the subscription process.',
          '9': "The User may, at any time, exercise rights of access, modification, deletion or consultation, by contacting the email address hello{'@'}retroally.com."
        }
      }
    },
    retro: {
      index: {
        archived_retros: 'Archived Retrospectives',
        empty: {
          body: "Here's where you'll find all your retros.",
          title: 'Create your first retro'
        },
        loading_retros: 'Loading retros',
        more: 'More',
        new_retro: 'New Retro',
        retros: 'Retros'
      },
      new: {
        title: {
          with_date: 'Creating retro for %{date}',
          no_date: 'Creating retro'
        },
        step_n: 'Step %{n}',
        mode: {
          title: 'Choose your retro mode',
          one_time: {
            title: 'One time mode',
            description: 'Users can join with just name',
            remark: '(Perfect for trying out)'
          }
        },
        participant_slots_picker: {
          title: 'How many participants?'
        },
        participants_picker: {
          not_enough: 'You need at least one participant who writes to create a retro',
          subtitle: 'Uncheck teammates if they are not attending',
          title: 'Who is attending?'
        },
        time: {
          title: 'When and how long?',
          start_time: 'Retro will start at',
          past_retro: "You can't schedule a retro in the past.",
          empty_time: 'You need to set time.',
          duration: 'Retro will last',
          date_sr: 'Retro date',
          start_time_sr: 'Retro start time',
          retro_length_sr: 'Retro duration in minutes',
          minutes: 'min',
          retro_duration: {
            invalid_duration: 'Retro duration is invalid.',
            too_short: '%{minutes}min may be too short to have a useful retro.',
            short: '%{minutes}min is a bit short for a retro, but should work.',
            normal: {
              no_minutes: '%{hours}h is appropriate for most retros.',
              with_minutes: '%{hours}h %{minutes}min is appropriate for most retros.'
            },
            long: {
              no_minutes: '%{hours}h is a bit long for a retro, but should work.',
              with_minutes: '%{hours}h %{minutes}min is a bit long for a retro, but should work.'
            },
            too_long: {
              no_minutes: '%{hours}h may be too long to have an enjoyable retro.',
              with_minutes: '%{hours}h %{minutes}min may be too long to have an enjoyable retro.'
            }
          }
        },
        activity: {
          title: 'What are we doing?',
          subtitle: 'Let us help you pick the retro activity',
          picker_title: 'Activities',
          recommended: 'Recommended',
          activity_highlights: 'Activity highlights',
          required: 'The retro must have an activity.',
          participants: {
            best_for: 'Best for %{min} to %{max} participants'
          },
          maturity_1: {
            best_for: 'Great for newly formed teams'
          },
          maturity_2: {
            best_for: 'Great for teams with a bit of experience working together'
          },
          maturity_3: {
            best_for: 'Great for teams that have worked together for some time'
          },
          maturity_4: {
            best_for: 'Great for teams that know each other very well'
          },
          project_stage: {
            heading: 'Project status',
            label: 'Which situation describes your project the best?',
            deadline: {
              label: 'We have a deadline coming up',
              best_for: 'Great for teams with approaching deadlines'
            },
            starting: {
              label: 'We are starting a new project',
              best_for: 'Great for teams starting a new project'
            },
            standard: {
              label: 'Nothing special'
            }
          }
        },
        advanced: {
          title: "We're almost there!",
          subtitle: "Just skip this if you're not sure",
          call_link: {
            title: 'Call link',
            placeholder: 'Paste the call link',
            description:
              'Make sure the team has everything in one place. Add the call link to get faster into the retro.',
            too_long: 'The call link is too long',
            white_spaces: "The call link can't have any whitespace",
            invalid_url: 'The call link must be a valid URL, for example: https://mycall.com'
          },
          max_topics: {
            title: 'Topic limit',
            toggle_label: 'Limit number of topics',
            label: 'Topic limit per participant',
            description:
              'Setting a maximum amount of topics per participant is recommended for retros with 8 or more participants.'
          },
          voting_mechanic: {
            title: 'Voting',
            description: 'Select how the team will vote the discussion groups.',
            options: {
              like: {
                title: 'Like',
                description: 'Everyone can vote any number of groups.'
              },
              one_and_two: {
                title: 'One & Two',
                description: 'Everyone gets two votes: one worth 1 and one worth 2.'
              }
            }
          },
          retro_name: {
            name: 'Retro name'
          },
          timing: {
            title: 'Timing',
            total: 'total',
            minutes: 'min',
            x_minutes_each: '%{x} min each participant',
            turn_too_short: 'Less than 2 min each participant',
            description: "We've prefilled this for you. You can adjust it if you like.",
            writing_duration_sr: 'Writing duration in minutes',
            posting_duration_sr: 'Sharing duration in minutes',
            rest_duration_sr: 'Rest of the retro duration in minutes',
            phases: {
              write: 'Time to write',
              share: 'Time to share',
              rest: 'Time to group, vote & discuss'
            },
            reset: 'Reset to defaults',
            values_too_long: 'Writing & sharing values are too long.',
            writing_duration_required: 'Writing duration must be positive.',
            posting_duration_required: 'Sharing duration must be positive.'
          }
        },
        steps: {
          participants: 'Participants',
          time: 'Time',
          activity: 'Activity',
          advanced: 'Advanced'
        },
        controls: {
          previous: '@:common.previous',
          next: 'Next',
          submit: 'Create New Retro'
        },
        plan_modal_title: 'Boost your retrospectives by upgrading your plan'
      },
      show: {
        confirm_leave: 'Are you sure you want to leave the retro?',
        joining_channel: 'Joining channel',
        joining_retro: 'Joining retro'
      }
    },
    profile: {
      user_password: {
        change_password: 'Change password'
      },
      user_profile: {
        avatar: 'Profile picture',
        confirm_leave: '@:common.confirm_leave',
        profile: 'Profile',
        sign_out: 'Sign out'
      }
    },
    home: {
      title: 'Dashboard',
      new_retro: 'New Retro',
      sign_in: 'Sign In or Sign Up'
    }
  }
};
