import router from '@/router';
import legacyData from '@/data/legacy';
import { getOwnedFreeWorkspacesCount, updateAvatar } from '@/data/users';
import { ApiError, forceLocalLogout, loginByEmailAndPassword, loginByGoogle, logout, refresh, startAutoRefresher, signUpAndLogin, verifyUserByTokenAndLogin } from '@/helpers/apiClient';
import { selectWorkspace } from '@/data/workspaces';
import * as Sentry from '@sentry/vue';
import { HTTP_STATUS } from '@/constants';
const module = {
    state: {
        avatar: '',
        email: '',
        hasArchivedRetros: false,
        hasPassword: false,
        id: null,
        initialized: false,
        name: '',
        ownedFreeWorkspacesCount: 0
    },
    getters: {
        loggedIn: (state) => {
            return !!state.id;
        },
        selectedWorkspaceId: (state) => {
            return state.selectedWorkspaceId;
        },
        session: (state) => {
            return state;
        },
        verified: (state) => {
            return !!state.verified;
        },
        temporary: (state) => {
            return !!state.temporary;
        }
    },
    mutations: {
        logout(state) {
            Object.keys(state).forEach((key) => (state[key] = null));
            localStorage.clear();
            Sentry.configureScope((scope) => scope.setUser(null));
        },
        selectWorkspace(state, workspaceId) {
            state.selectedWorkspaceId = workspaceId;
        },
        setUser(state, user) {
            if (user) {
                state.avatar = user.avatar;
                state.email = user.email;
                state.hasArchivedRetros = user.hasArchivedRetros;
                state.hasPassword = user.hasPassword;
                state.id = user.id;
                state.name = user.name;
                state.role = user.role;
                state.selectedWorkspaceId = user.selectedWorkspaceId;
                state.temporary = user.temporary;
                state.verified = user.verified;
                state.acceptedEmails = user.acceptedEmails;
                state.ownedFreeWorkspacesCount = user.ownedFreeWorkspacesCount;
                localStorage.locale = user.locale;
                localStorage.userAvatar = user.avatar;
                localStorage.userId = user.id;
                localStorage.userName = user.name;
                Sentry.setUser({ id: user.id?.toString() });
            }
            else {
                localStorage.locale = 'en';
                localStorage.userId = null;
                localStorage.userName = null;
                localStorage.userAvatar = null;
                Sentry.configureScope((scope) => scope.setUser(null));
            }
        },
        setInitialized(state, value) {
            state.initialized = value;
        },
        setOwnedFreeWorkspacesCount(state, count) {
            state.ownedFreeWorkspacesCount = count;
        }
    },
    actions: {
        async logout({ commit }, redirect = 'home') {
            await logout();
            router.push({ name: redirect });
            commit('logout');
        },
        clearSessionAndLoginAgain({ commit }) {
            router.push({ name: 'login' });
            commit('logout');
        },
        async initSession({ commit, dispatch }) {
            try {
                await refresh();
                startAutoRefresher({
                    onForcedLogout: () => dispatch('clearSessionAndLoginAgain')
                });
                const user = await legacyData.getUser();
                commit('setUser', user);
            }
            catch (error) {
                if (error instanceof ApiError) {
                    forceLocalLogout();
                }
                else {
                    throw error;
                }
            }
            commit('setInitialized', true);
        },
        selectWorkspace({ commit }, workspaceId) {
            commit('selectWorkspace', workspaceId);
            selectWorkspace(workspaceId);
        },
        async signUpUser({ commit, dispatch }, userParams) {
            try {
                const user = await signUpAndLogin(userParams, {
                    onForcedLogout: () => dispatch('clearSessionAndLoginAgain')
                });
                commit('setUser', user);
                return { success: true };
            }
            catch (error) {
                if (error instanceof ApiError) {
                    return { message: error.code, success: false };
                }
                throw error;
            }
        },
        async updateUserEmail({ commit }, params) {
            const { message, success, user } = await legacyData.updateUser(params);
            if (success) {
                commit('setUser', user);
                return { success };
            }
            else {
                return { success: false, message };
            }
        },
        async loginPermanentUser({ commit, dispatch }, { email, password }) {
            try {
                const user = await loginByEmailAndPassword(email, password, {
                    onForcedLogout: () => dispatch('clearSessionAndLoginAgain')
                });
                commit('setUser', user);
                return { success: true };
            }
            catch (error) {
                if (error instanceof ApiError) {
                    return { message: error.code, success: false };
                }
                throw error;
            }
        },
        async sendPasswordResetEmail(_context, email) {
            await legacyData.resetPasswordEmail({ email });
        },
        async signInGoogleUser({ commit, dispatch }, { googleToken }) {
            const user = await loginByGoogle(googleToken, {
                onForcedLogout: () => dispatch('clearSessionAndLoginAgain')
            });
            commit('setUser', user);
        },
        async resendConfirmationEmail(_commit, email) {
            await legacyData.resendConfirmationEmail(email);
        },
        async updatePassword({ dispatch }, params) {
            const { message, success } = await legacyData.updatePassword(params);
            if (success) {
                router.push({ name: 'home' });
                dispatch('flashThenClear', { message: 'password_update_success' });
                return { success };
            }
            else {
                return { message, success };
            }
        },
        async updateUser({ commit, dispatch }, params) {
            const { success, user, message } = await legacyData.updateUser(params);
            if (success) {
                commit('setUser', user);
                dispatch('flashThenClear', { message: 'user_update_success' });
            }
            else if (message === 'already_in_use') {
                return { success: false, message };
            }
            else {
                dispatch('flashThenClear', { message: 'user_update_failure' });
            }
            return { success };
        },
        async verifyUserEmail({ commit, dispatch }, { verificationToken }) {
            try {
                const user = await verifyUserByTokenAndLogin(verificationToken, {
                    onForcedLogout: () => dispatch('clearSessionAndLoginAgain')
                });
                commit('setUser', user);
                router.push({ name: 'home' });
                dispatch('flashThenClear', { message: 'email_verification_success' });
            }
            catch (error) {
                if (error instanceof ApiError) {
                    const { UNAUTHORIZED, UNPROCESSABLE_ENTITY } = HTTP_STATUS;
                    // Try to preserve error capture from old code.
                    if (error.status && ![UNAUTHORIZED, UNPROCESSABLE_ENTITY].includes(error.status)) {
                        Sentry.captureMessage(`Failed email verification: ${JSON.stringify(error)}`);
                    }
                    const email = error.data?.email;
                    router.push({ name: 'confirmationExpired', params: { email } });
                }
                else {
                    throw error;
                }
            }
        },
        async updateUserAvatar({ commit }, image) {
            const user = await updateAvatar(image);
            commit('setUser', user);
        },
        async refreshOwnedFreeWorkspacesCount({ commit }) {
            const count = await getOwnedFreeWorkspacesCount();
            commit('setOwnedFreeWorkspacesCount', count);
        }
    }
};
export default module;
