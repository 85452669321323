/**
 * Contains workspace and team related interfaces.
 */
/**
 * Typeguard for checking if an obj is a valid `Invitation`.
 */
export function isInvitation(obj) {
    return obj.id && obj.email && obj.resourceId && obj.type;
}
/**
 * Typeguard to check if an object is a valid `BaseWorkspace`.
 */
export function isBaseWorkspace(obj) {
    return obj.id && obj.name;
}
/**
 * Typeguard to check if an object is a valid `Workspace`.
 */
export function isWorkspace(obj) {
    return (obj &&
        Array.isArray(obj.members) &&
        Array.isArray(obj.teams) &&
        Array.isArray(obj.invitations) &&
        isBaseWorkspace(obj));
}
