const module = {
    state: {
        fullWidth: false
    },
    getters: {
        fullWidth: (state) => state.fullWidth
    },
    mutations: {
        /**
         * This mutation should only be called from `useFullWidth`, if you think you need to call it from somewhere else
         * speak to someone that has more context on it to verify the use case.
         */
        setFullWidth(state, value) {
            state.fullWidth = value;
        }
    }
};
export default module;
