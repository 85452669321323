export default {
  activities: {
    mad_sad_glad: {
      name: 'Enojo / Tristeza / Felicidad',
      description:
        'Construye dinámicas positivas de equipo discutiendo la percepción de la última iteración. {0} Esta actividad es perfecta para identificar oportunidades de mejora.',
      topic_types: {
        mad: {
          name: 'Enojo',
          placeholder: '¿Qué te causa enojo?'
        },
        sad: {
          name: 'Tristeza',
          placeholder: '¿Qué te causa tristeza?'
        },
        glad: {
          name: 'Felicidad',
          placeholder: '¿Qué te causa felicidad?'
        }
      }
    },
    the_soup: {
      name: 'La Sopa',
      description:
        '¿El equipo necesita mejorar la percepción de responsabilidad? {0} La sopa ayudará al equipo a distinguir dónde tiene responsabilidad directa, dónde puede influenciar y dónde no tiene influencia alguna.',
      topic_types: {
        control: {
          name: 'Control',
          placeholder: '¿Qué está bajo el control del equipo?'
        },
        influence: {
          name: 'Influencia',
          placeholder: '¿En qué puede influenciar el equipo?'
        },
        context: {
          name: 'Contexto',
          placeholder: '¿Qué está en el contexto del equipo?'
        }
      }
    },
    sailboat: {
      name: 'Barco de vela',
      description:
        '¿Tienes una meta aproximándose? ¿Quizás un hito de proyecto? {0} Analiza con el equipo qué lo impulsa hacia adelante y qué pueden ser obstáculos para alcanzar la meta.',
      topic_types: {
        wind: {
          name: 'Viento',
          placeholder: '¿Qué está impulsando al equipo hacia adelante?'
        },
        anchor: {
          name: 'Anclas',
          placeholder: '¿Qué está frenando al equipo?'
        },
        rocks: {
          name: 'Rocas',
          placeholder: '¿Qué es un peligro para el equipo?'
        }
      }
    },
    kalm: {
      name: 'K.A.L.M.',
      description:
        'Mantén el buen trabajo analizando qué buenas prácticas mantener (Keep), añadir (Add), hacer más (More) y qué prácticas reducir (Less).',
      topic_types: {
        keep: {
          name: 'Keep',
          placeholder: '¿Qué quieres mantener?'
        },
        add: {
          name: 'Add',
          placeholder: '¿Qué quieres agregar?'
        },
        less: {
          name: 'Less',
          placeholder: '¿De qué quieres menos?'
        },
        more: {
          name: 'More',
          placeholder: '¿De qué quieres más?'
        }
      }
    },
    cardiac_rhythm: {
      name: 'Ritmo cardíaco',
      description:
        '¿Cómo es el ritmo del sprint? Todo equipo necesita un ritmo sustentable para funcionar óptimamente. {0} Esta actividad ayudará al equipo a determinar si el ritmo es adecuado.',
      topic_types: {
        high: {
          name: 'Alto',
          placeholder: '¿Qué tuvo ritmo alto?'
        },
        medium: {
          name: 'Medio',
          placeholder: '¿Qué tuvo ritmo medio?'
        },
        low: {
          name: 'Bajo',
          placeholder: '¿Qué tuvo ritmo bajo?'
        }
      }
    },
    scrum_flow: {
      name: 'Flujo Scrum',
      description:
        '¿Sientes que las ceremonias de Scrum pueden mejorar? {0} Analiza con el equipo cómo se están ejecutando las ceremonias (Daily, Planning, Refinement y Review).',
      topic_types: {
        daily: {
          name: 'Daily',
          placeholder: 'Cosas relacionadas a la reunión diaria'
        },
        planning: {
          name: 'Planning',
          placeholder: 'Cosas relacionadas a la reunión de planeación'
        },
        refinement: {
          name: 'Refinement',
          placeholder: 'Cosas relacionadas a la reunión de refinamiento'
        },
        review: {
          name: 'Review',
          placeholder: 'Cosas relacionadas a la revisión del Sprint'
        }
      }
    },
    discuss_and_mention: {
      name: 'Discutir y Mencionar',
      description:
        '¿Necesitan enfocarse en qué discutir? Esta actividad es buena para ir directo al grano. {0} Busca consenso, coordina acciones y discute qué está pasando.',
      topic_types: {
        discussion: {
          name: 'Discutir',
          placeholder: '¿Qué quieres discutir?'
        },
        mention: {
          name: 'Mencionar',
          placeholder: '¿Qué quieres mencionar?'
        }
      }
    },
    effort_and_pain: {
      name: 'Esfuerzo y Dolor',
      description:
        '¿El equipo está exhausto cerca de una fecha límite? {0} El equipo Reflexionará cuánto esfuerzo demanda la fecha límite y el dolor que provoca, luego buscará acciones para mitigarlos.',
      topic_types: {
        effort: {
          name: 'Esfuerzo',
          placeholder: '¿En qué estás poniendo tu esfuerzo?'
        },
        pain: {
          name: 'Dolor',
          placeholder: '¿Qué te está causando dolor?'
        }
      }
    },
    hope_and_concern: {
      name: 'Esperanza y Preocupación',
      description:
        '¿Pensando en el próximo sprint? {0} Analiza y toma acción sobre las esperanzas y preocupaciones del equipo respecto del futuro inmediato.',
      topic_types: {
        hope: {
          name: 'Esperanza',
          placeholder: '¿De qué tienes esperanza?'
        },
        concern: {
          name: 'Preocupación',
          placeholder: '¿Qué te preocupa?'
        }
      }
    },
    three_ls: {
      name: '3Ls: Liked, Learned, Lacked',
      description:
        'Tómense un momento para revisar las lecciones aprendidas, mejores prácticas y flujo de trabajo que el equipo ha desarrollado en los sprints pasados, para así fortalecer los próximos.',
      topic_types: {
        liked: {
          name: 'Liked',
          placeholder: '¿Qué te gustó?'
        },
        learned: {
          name: 'Learned',
          placeholder: '¿Qué aprendiste?'
        },
        lacked: {
          name: 'Lacked',
          placeholder: '¿Qué te faltó?'
        }
      }
    },
    start_stop_continue: {
      name: 'Empezar, Dejar, Seguir',
      description:
        '¿Buscando cambios a corto plazo en el comportamiento del equipo? {0} Sumerge al equipo en una retrospectiva orientada a la acción preguntando qué deberíamos empezar a hacer, qué deberíamos dejar de hacer y qué deberíamos seguir haciendo.',
      topic_types: {
        start: {
          name: 'Empezar',
          placeholder: '¿Qué debería empezar a hacer el equipo?'
        },
        stop: {
          name: 'Dejar',
          placeholder: '¿Qué debería dejar de hacer el equipo?'
        },
        continue: {
          name: 'Seguir',
          placeholder: '¿Qué debería seguir haciendo el equipo?'
        }
      }
    }
  }
};
