import cloneDeep from 'lodash.clonedeep';
/**
 * Plugin that makes pinia states accessible from the `window` object.
 *
 * This is a lazy workaround for debugging deployed stuff.
 *
 * The state of each store will be accessible as a window getter named
 * as the id of the store + "Debug".
 */
export default function debugStore({ store }) {
    Object.defineProperty(window, `${store.$id}Debug`, {
        get() {
            // `cloneDeep` is used to "unproxy" the state when this property is read.
            const snapshot = cloneDeep(store.$state);
            // Socket and channel are recursive objects. We are removing them
            // because with them, we can't do "Copy object" from Chrome's DevTools
            // nor `JSON.stringify`.
            if (store.$id === 'retro') {
                delete snapshot.socket;
                delete snapshot.channel;
            }
            return snapshot;
        }
    });
}
