<template>
  <h4 class="my-10 ml-20 text-2xl font-bold text-primary-800 dark:text-gray-300">
    {{ heading }}
  </h4>
</template>

<script>
export default {
  name: 'InnerHeading',
  props: {
    heading: { type: String, required: true }
  }
};
</script>
