import { createWorkspace } from '@/data/workspaces';
import router from '@/router';
import { useNewTeamStore } from '@/pinia_stores/newTeam';
const stepNames = ['workspace', 'teamCreation', 'choosePlan'];
const MAX_NAME_LENGTH = 50;
function defaultState() {
    return {
        step: 1,
        nextButtonLoading: false,
        workspaceName: '',
        plan: 'pro',
        paymentPeriod: 'annual'
    };
}
const module = {
    state: defaultState(),
    actions: {
        async createWorkspace({ commit, dispatch, state }) {
            commit('setNextButtonLoading', true);
            const teamStore = useNewTeamStore();
            const emails = teamStore.users.map((user) => user.email);
            const teamName = teamStore.teamName.trim();
            if (teamStore.trimmedInvitesSearchTerm.length > 0) {
                emails.push(teamStore.trimmedInvitesSearchTerm);
            }
            try {
                const workspace = await createWorkspace({
                    emails,
                    name: state.workspaceName.trim(),
                    paymentPeriod: state.paymentPeriod,
                    plan: state.plan,
                    teamName
                });
                if (!workspace) {
                    return;
                }
                commit('selectWorkspace', workspace.id, { root: true });
                router.push({ name: 'showWorkspace', params: { workspaceId: workspace.id } });
            }
            catch (e) {
                dispatch('flashThenClear', { message: 'error_creating_workspace' }, { root: true });
                commit('setNextButtonLoading', false);
            }
        },
        resetState({ commit }) {
            commit('resetState');
            useNewTeamStore().$reset();
        }
    },
    getters: {
        canAdvanceStep(_state, { nextStepDisabledReason }) {
            return !nextStepDisabledReason;
        },
        canAdvanceWorkspaceStep(state, { stepName, workspaceNameHasValidLength }) {
            return stepName === 'workspace' && state.workspaceName !== '' && workspaceNameHasValidLength;
        },
        nextStepDisabledReason(_state, { stepName, workspaceDisabledReason }) {
            const newTeamStore = useNewTeamStore();
            if (stepName === 'workspace') {
                return workspaceDisabledReason;
            }
            else if (stepName === 'teamCreation') {
                if (newTeamStore.stepName !== 'invites') {
                    return newTeamStore.advanceDisabledReason;
                }
                else if (!newTeamStore.canCreateTeam) {
                    return 'invalid_emails';
                }
            }
            return null;
        },
        stepName(state) {
            return stepNames[state.step - 1];
        },
        workspaceNameHasValidLength(state) {
            return state.workspaceName.length <= MAX_NAME_LENGTH;
        },
        workspaceDisabledReason(state, { stepName, workspaceNameHasValidLength }) {
            if (stepName !== 'workspace') {
                return null;
            }
            if (state.workspaceName === '') {
                return 'errors.name.empty';
            }
            else if (!workspaceNameHasValidLength) {
                return 'errors.name.too_long';
            }
            else {
                return null;
            }
        }
    },
    mutations: {
        decrementStep(state) {
            if (state.step > 1) {
                state.step--;
            }
            else {
                router.push({ name: 'indexWorkspace' });
            }
        },
        incrementStep(state) {
            if (state.step < stepNames.length) {
                state.step++;
            }
        },
        resetState: (state) => {
            Object.assign(state, defaultState());
        },
        setNextButtonLoading(state, value) {
            state.nextButtonLoading = value;
        },
        setWorkspaceName(state, name) {
            state.workspaceName = name.trim();
        },
        setPlan(state, plan) {
            state.plan = plan;
        },
        setPaymentPeriod(state, paymentPeriod) {
            state.paymentPeriod = paymentPeriod;
        }
    }
};
export default module;
