import snake from 'snakecase-keys';
import shallowCamel from 'camelcase-keys';
/**
 * Turns the keys of an object into it's snake form.
 */
export function snakeKeys(obj) {
    return snake(obj);
}
/**
 * Turns the keys of an object into it's cammel form.
 */
export function camelKeys(obj) {
    return shallowCamel(obj, { deep: true });
}
