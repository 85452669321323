<template>
  <header
    class="fixed flex top-0 left-0 z-40 print:absolute print:ml-5 w-full items-center font-bold text-sm"
    :class="{
      'h-20 px-8 pt-5 pb-3 bg-white dark:bg-gray-800 text-wyeworks-black dark:text-gray-200':
        !applicationHeader,
      'h-10 bg-primary-900 dark:bg-gray-900 text-white px-5': applicationHeader
    }"
  >
    <div class="flex flex-1 items-center">
      <div class="dark:text-gray-300">
        <router-link to="/" class="flex items-center space-x-1 sm:mr-5">
          <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" v-bind:svg-inline="''" v-bind:src="'@/assets/images/logo.svg'" v-bind:class="'w-7 h-7'"><g fill="none" fill-rule="evenodd"><path fill="#FF7645" stroke="#FF7645" stroke-width=".5" d="M4.862 1h17.476a3.171 3.171 0 013.17 3.073l.59 18.856a3.172 3.172 0 01-3.071 3.27l-.1.001H4.273a3.172 3.172 0 01-3.171-3.172l.001-.099.59-18.856A3.168 3.168 0 014.861 1z"/><g stroke="#00292B" stroke-linecap="round" stroke-width=".595"><path d="M13.797 8.678a.59.59 0 111.181 0h0m3.544 0a.59.59 0 111.181 0h0"/><path fill="#00292B" stroke-linejoin="round" d="M19.112 10.45a2.362 2.362 0 11-4.724.001h4.724z"/></g><path fill="#FFF" fill-rule="nonzero" stroke="#FFF" stroke-linejoin="round" stroke-width=".68" d="M10.777 15.777H6.699v5.437h4.078z"/><path stroke="#DDDBEA" stroke-linecap="round" stroke-width=".68" d="M8.058 14.304l.276-.871a.683.683 0 000-.41l-.211-.666a.683.683 0 010-.41l.21-.666a.668.668 0 000-.41L8.059 10h0m1.359 4.304l.275-.871a.683.683 0 000-.41l-.275-.87h0" style="mix-blend-mode:multiply"/><path fill="#FFF" fill-rule="nonzero" d="M6.19 16.796a1.53 1.53 0 01-.001 3.059c-.825 0-1.189-.602-1.189-1.53 0-.927.364-1.529 1.19-1.529zm0 .68c-.36 0-.51.25-.51.85s.15.85.51.85a.85.85 0 00-.001-1.7z"/></g></svg>
          <svg width="95" height="26" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:src="'@/assets/images/logo_text.svg'"><text transform="translate(.023)" fill="currentColor" fill-rule="evenodd" font-family="FiraSans-Ultra, Fira Sans" font-size="22" font-weight="600"><tspan x="0" y="21">RetroAlly</tspan></text></svg>
        </router-link>
      </div>
      <secondary-site-nav v-if="!applicationHeader" class="hidden sm:block" />
    </div>
    <site-nav :menu="!applicationHeader" />
  </header>
</template>

<script>
/* eslint vue/no-unused-properties: 0 */
/* eslint vue/no-unused-components: 0 */
/* eslint no-unused-vars: 0 */

import SecondarySiteNav from './SiteNav/SecondarySiteNav.vue';
import SiteNav from './SiteNav/SiteNav.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'SiteHeader',
  components: {
    SecondarySiteNav,
    SiteNav
  },
  data() {
    return {
      currentScroll: 0
    };
  },
  computed: {
    ...mapGetters(['loggedIn']),
    applicationHeader() {
      return this.loggedIn;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      this.currentScroll = window.top.scrollY;
    }
  }
};
</script>
