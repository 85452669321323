/* eslint-disable max-lines */

export default {
  common: {
    add: 'Agregar',
    and: 'y',
    app_name: 'RetroAlly',
    cancel: 'Cancelar',
    close: 'Cerrar',
    confirm_leave: 'Tienes cambios sin guardar ¿Estás seguro que quieres salir?',
    contact_us: '¿Necesitas ayuda? Contáctanos.',
    delete: 'Eliminar',
    moderator: 'Moderador',
    next: 'Continuar',
    no_undo: 'No puedes deshacer esto.',
    previous: 'Anterior',
    save: 'Guardar',
    submit: 'Enviar',
    team_name: 'Nombre del equipo'
  },
  components: {
    choose_team_table: {
      team_name: 'Nombre de equipo',
      teammates: 'Compañeros',
      status: 'Estado',
      in_progress_retro: 'Retro en progreso',
      upcoming_retro: 'Retro planeada'
    },
    contract_term_ends: {
      warning_message: 'El plazo de tu contrato termina el %{term}'
    },
    delete_seats_modal: {
      available_seats: 'Asientos disponibles',
      cancel: '@:common.cancel',
      close: '@:common.close',
      delete_plural: 'Borrar asientos',
      delete: 'Borrar asiento',
      description_1:
        'Solo puede eliminar los asientos disponibles. Para tener asientos disponibles, debe eliminar usuarios desde ',
      description_2: 'Usuarios del espacio de trabajo',
      no_seats_title: 'No hay asientos disponibles para borrar',
      seats_title: '¿Borrar asientos?',
      total_seats: 'Asientos totales'
    },
    maturity_slider: {
      heading: 'Madurez de equipo',
      label: '¿Qué tan bien se conocen?',
      maturity_1: {
        label: 'Casi no nos conocemos'
      },
      maturity_4: {
        label: 'Nos conocemos mucho'
      }
    },
    image_uploads: {
      supports: {
        max_size: 'Tamaño máx. 2MB',
        supports: 'Soporta JPG, JPEG & PNG'
      },
      upload_image_button: {
        change_picture: 'Cambiar imagen'
      }
    },
    need_help: {
      prompt: 'Necesitas ayuda? Contáctanos'
    },
    search_input: {
      search: 'Buscar...'
    },
    no_results: {
      double_check: 'Chequéa que tu búsqueda no tenga errores de tipeo o prueba con otra búsqueda.',
      keep_in_mind: 'Ten en cuenta, sólo puedes buscar por',
      no_matches: 'Hmmm… No pude encontrar ningún resultado con “%{query}”.'
    },
    user_multi_select: {
      seats_available_placeholder: 'Añadir & Invitar compañeros',
      seats_unavailable_placeholder: 'Invitar compañeros',
      teammate_email: 'Correo del compañero'
    },
    unlock_more_activities_dialog: {
      activity: '👉 El plan PRO tiene +10 actividades para aprovechar al máximo tu retrospectiva',
      confirm: 'Lo tengo',
      helper:
        '👉 Prueba nuestro selector de actividades para elegir la actividad perfecta en el momento justo',
      check: '📘 Mira',
      article: 'este artículo',
      more_information: 'para más información',
      outcomes:
        '👉 Usar la misma actividad para todas las retrospectivas puede llevar a tu equipo siempre a los mismos resultados',
      title: 'Actualiza tu plan',
      upgrade: 'Actualiza tu plan para usar esta actividad y más...'
    },
    breadcrumb: {
      archived_teams: 'Equipos archivados',
      create_retro: 'Crear retrospectiva',
      create_team: 'Crear equipo',
      delete_workspace: 'Eliminar espacio de trabajo',
      edit_team: 'Editar equipo',
      edit_workspace: 'Configuración del espacio de trabajo',
      workspace_change_plan: 'Cambiar plan',
      team_submenu: {
        edit: 'Editar equipo'
      },
      workspace_members: 'Usuarios del espacio de trabajo',
      workspace_seats: 'Administrar asientos',
      workspace_submenu: {
        archived_teams: 'Equipos archivados',
        choose_another: 'Elegir otro',
        create: 'Crear espacio de trabajo',
        settings: 'Configuración del espacio de trabajo',
        switch: 'Cambiar de espacio de trabajo',
        users: 'Usuarios del espacio de trabajo'
      }
    },
    team_member_item: {
      moderator_flag: 'Moderador'
    },
    common_feature_card: {
      unlimited_teams: {
        title: 'Equipos ilimitados',
        subtitle: 'Crea tantos equipos como necesites, el cielo es el límite.'
      },
      more_activities: {
        title: '+10 Actividades',
        subtitle: 'Dale sabor a las cosas y explora diferentes actividades con tus equipos.'
      },
      ice_breakers: {
        title: 'Rompehielos',
        subtitle: 'Suaviza las cosas antes de que comience la retro con nuestros rompehielos.'
      },
      slack_integration: {
        title: 'Integración con Slack',
        subtitle: 'Mantiene a tus equipos actualizados.'
      }
    },
    workspaces: {
      invites_form: {
        add_teammate: 'Añadir compañero',
        email_error: 'Correo electrónico inválido',
        seats_available: '%{taken} asientos ocupados, %{available} disponibles',
        who_belongs: '¿Quién debería estar en'
      },
      invites_modal: {
        back: 'Anterior',
        buy: 'Comprar asientos',
        cancel: 'Cancelar',
        invalid_one_seat_amount: 'Tienes que comprar al menos 1 asiento para tu nueva invitación',
        invalid_seats_amount:
          'Tienes que comprar al menos %{seats} asientos para tus nuevas invitaciones',
        invitations_step_body_retro: 'Invita a tus compañeros a unirse a tu retro',
        invitations_step_body_team: 'Invita a tus compañeros a unirse a tu equipo',
        invitations_step_body_workspace: 'Invita a tus compañeros a unirse a tu espacio de trabajo',
        next: 'Siguiente',
        seats_available: '%{taken} asientos ocupados, %{available} disponibles',
        seats_step_body: 'Compra más asientos para tus nuevos compañeros',
        send_immediate: 'Enviar invitación',
        send_later: 'Agregar compañero de equipo'
      },
      payment_banner: {
        failed_message: 'Por favor, actualiza tus detalles de pago',
        manage_payment: 'Administrar información de pago'
      },
      seats_modifier: {
        add_seats: 'Agregar asientos',
        annual: 'anual',
        available_seats: 'Asientos disponibles',
        charge: '$%{charge} USD Cargo %{period} estimado por los nuevos asientos',
        current_seats: 'Asientos actuales',
        monthly: 'mensual',
        single_seat_charge: '$%{charge} USD Cargo %{period} estimado por el nuevo asiento',
        term: 'Prorrateado según el tiempo restante de tu contrato: %{term}',
        total_seats: 'Asientos totales'
      },
      selector: {
        modal: {
          after: 'Un miembro deberá invitarte de nuevo si deseas unirte en el futuro.',
          cancel: '@:common.cancel',
          heading: '¿Estás seguro que deseas rechazar la invitación a %{workspaceName}?',
          reject: 'Rechazar invitación'
        },
        no_workspaces: 'No te has unido a ningún equipo de trabajo, crea uno',
        logged_in: 'Has iniciado sesión cómo'
      },
      selector_item: {
        members: '1 Usuario | %{n} Usuarios',
        members_not_joined: '1 Usuario, no te has unido aún | %{n} Usuarios, no te has unido aún',
        reject_invitation: 'Rechazar invitación'
      },
      show: {
        ask: 'Pide unirte al equipo',
        create_team: 'Crear Equipo',
        other_teams: 'Otros equipos',
        request_access:
          'Crea un nuevo equipo o pide acceso a equipos existentes y empieza tu experiencia en Retroally',
        search_by: 'nombre de equipo',
        single_team: 'Mi equipo',
        starter_subtitle:
          'El plan STARTER de Retroally solo permite un equipo gratis. Pide unirte al equipo disponible y disfruta!',
        suspended_paragraph_owner: 'Este espacio de trabajo está suspendido.',
        suspended_paragraph:
          'Este espacio de trabajo está suspendido. Solicite al dueño que actualice los datos de pago.',
        suspended_title: 'Esto es un poco incómodo...',
        team_name: '@:common.team_name',
        teammates: 'Compañeros',
        teams: 'Mis equipos',
        upgrade_button: 'Actualiza ahora',
        upgrade_subtitle: 'Actualiza ahora para potenciar tus retrospectivas',
        upgrade_title: '¿Estás pronto para despegar?',
        welcome_to_retroally: 'Bienvenido a Retroally!'
      },
      team_card: {
        just_you: 'Sólo tú, invita a otros!',
        teammates: 'compañeros',
        upcoming_retro: 'Próxima retro el'
      },
      team_form: {
        choose_first_team_name: 'Crea el primer equipo, ¿Cómo se llama?',
        choose_team_name: '¿Cómo se llama el equipo?',
        recommendation: 'Recomendación: Piensa en el próximo equipo con el que harás una retro',
        team_name: '@:common.team_name'
      },
      update_workspace_seats: {
        buy: 'Comprar asientos',
        buy_single_seat: 'Comprar asiento',
        cancel: '@:common.cancel',
        term: 'Prorrateado para el tiempo restante del plazo de tu contrato: %{term}',
        title: 'Compra más asientos para tus nuevos compañeros'
      },
      pricing_release_dashboard: {
        already_own_free_ws: 'Ya eres dueño de un espacio de trabajo con el plan starter',
        ask_to_choose: 'Debes pedirle al dueño de tu espacio de trabajo que elija un plan',
        code: 'CÓDIGO:',
        decide_text:
          'Es tiempo que decidas si quieres mantener tu plan PRO o si quieres a cambiar el plan de tu espacio de trabajo.',
        downgrade: 'Cambiar plan',
        keep_pro_button: 'Mantener PRO',
        keep_pro_text: 'Mantener PRO por',
        month: 'Mes',
        stats: {
          action_items: 'Acciones a tomar',
          active_users: 'Usuarios activos',
          most_used_activity: 'Actividad más usada',
          no_activity:
            '¡Crea tu primera retro para disfrutar de las actividades que RetroAlly tiene para ti!',
          open_discussions: 'Discusiones abiertas',
          retros_performed: 'Retros realizadas',
          title: 'Estadísticas de los últimos 3 meses'
        },
        teams_subtitle: 'Equipos del espacio de trabajo',
        title: 'Gracias por ser un cliente leal 🧡',
        value_for: 'por usuario/mes',
        year: 'Año'
      },
      workspace_form: {
        choose_name: '¿Cómo llamarías al espacio de trabajo?',
        name: 'Nombre del espacio de trabajo',
        something_easy: 'Algo simple, como el nombre de la empresa'
      },
      workspace_member_row: {
        at: 'a las',
        invited: 'Invitado',
        owner: 'Dueño',
        user: 'Usuario'
      }
    },
    about_us: {
      title: 'Cómo creamos RetroAlly',
      we: 'Nosotros',
      put_together_our_experience:
        'unimos nuestra experiencia realizando ' +
        'retrospectivas remotas con clientes a lo largo de los últimos 15 años. ' +
        'Luego de todo ese tiempo, se podría decir que sabemos las prácticas que funcionan ' +
        'y las que no.',
      combined_the_knowledge:
        'Combinamos nuestro conocimiento en este campo, con el de nuestro ' +
        'Coach de Agilismo.',
      the_result_is:
        'El resultado es una poderosa y a la vez simple herramienta que ayuda a equipos a ' +
        'tener reuniones de retrospectiva productivas que realmente los ayudan a mejorar.',
      how_we_built: 'Cómo construimos RetroAlly',
      with_remotism:
        'Con el remotismo convirtiéndose en un cada vez más prominente aspecto de nuestras vidas, ' +
        'no pudimos evitar notar la ausencia de herramientas listas para que un equipo pueda ' +
        'tener retrospectivas significativas y efectivas',
      we_started_working:
        'Comenzamos trabajando en una plataforma que permitiera a nuestros equipos internos ' +
        'tener reuniones efectivas, y nos sentimos obligados a compartir con el mundo su éxito.'
    },
    action_items: {
      action_item: 'Plan de Acción',
      action_items: 'Planes de Acción',
      new_empty: {
        heading: '¿Qué van a hacer al respecto?',
        subheading: 'Discute con el equipo y agrega planes de acción',
        new_action_item: 'Nuevo Plan de Acción'
      },
      new: {
        cancel: '@:common.cancel',
        description_empty: 'No hay nada que guardar.',
        force_cancel: 'Forzar cancelar',
        force_submit: 'Forzar agregar',
        placeholder: 'Escribe un nuevo plan de acción...',
        save: '@:common.add',
        title: 'Nuevo Plan de Acción',
        what_you_write_is_public: '¡Piensen juntos! Lo que escribes es visto por tus compañeros.',
        too_long: '¡Demasiado largo! Intenta mantenerlo breve.',
        n_characters_remaining:
          '0 caracteres restantes. | 1 carácter restante. | %{n} caracteres restantes.',
        x_is_writing: '¡Piensen juntos! %{name} está escribiendo...'
      },
      edit: {
        cancel: '@:common.cancel',
        force_cancel: 'Forzar cancelar',
        force_submit: 'Forzar editar',
        no_changes: 'No hay cambios que guardar.',
        save: '@:common.save',
        title: 'Editando Plan de Acción',
        tooltip: 'Editar',
        what_you_write_is_public: '¡Piensen juntos! Lo que escribes es visto por tus compañeros.',
        too_long: '¡Demasiado largo! Intenta mantenerlo breve.',
        n_characters_remaining:
          '0 caracteres restantes. | 1 carácter restante. | %{n} caracteres restantes.',
        x_is_editing: '¡Piensen juntos! %{name} está editando...'
      },
      owners: {
        keep_the_team_accountable:
          '%{name} mantendrá al equipo responsable por... | %{n} personas mantendrán al equipo responsable por...',
        keep_accountable_question: '¿Quién mantendrá al equipo responsable por esto?',
        team_keep_in_mind: 'El equipo tendrá en mente...',
        assign_me_too: 'Asignarme también',
        i_will: 'Asignarme'
      },
      delete: {
        tooltip: 'Eliminar',
        yes: 'Sí, eliminar',
        no: '@:common.cancel',
        before: '@:common.no_undo',
        question: '¿Eliminar el plan de acción?'
      },
      follow_up: {
        done: '¡Está hecho!',
        useful: '¡Y fue útil!'
      }
    },
    base: {
      copy_button: {
        copied: '¡Copiado!'
      },
      text_field: {
        n_characters_remaining:
          '0 caracteres restantes. | 1 carácter restante. | %{n} caracteres restantes.'
      }
    },
    calendar_field: {
      days: ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'],
      months: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Setiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
      ]
    },
    flash_messages: {
      archived_team: 'Tu equipo fue archivado correctamente.',
      cant_remove_owner_error:
        'No puedes remover a este usuario del espacio de trabajo, ya que es su dueño.',
      email_verification_success: 'Tu correo ha sido confirmado.',
      error_canceling_invitation:
        'Ocurrió un error cancelando la invitación. Por favor inténtelo de nuevo.',
      error_creating_retro: 'Ocurrió un error creando la retro. Por favor inténtelo de nuevo.',
      error_creating_team: 'Ocurrió un error creando el equipo. Por favor inténtelo de nuevo.',
      error_deleting_workspace:
        'Ocurrió un error al eliminar el espacio de trabajo. Por favor inténtelo de nuevo.',
      error_creating_workspace:
        'Ocurrió un error creando su espacio de trabajo. Por favor inténtalo de nuevo.',
      error_fetching_resource: 'No se pudo acceder o encontrar el recurso.',
      error_loading_workspaces: 'No pudimos cargar tus workspacers. Por favor intenta de nuevo.',
      error_removing_member: 'Ocurrió un error removiendo al miembro. Por favor inténtelo de nuevo',
      error_team_name_taken:
        'Un equipo con ese nombre ya existe, por favor elija un nombre distinto',
      invitations_failure:
        'Tuvimos un problema al enviar las invitaciones. Por favor intenta de nuevo.',
      last_teammate_error: 'No puedes irte del equipo ya que eres el último miembro unido',
      left_the_team: 'Has abandonado el equipo',
      password_update_success: 'Contraseña actualizada con éxito.',
      permanent_sign_up_success: '¡Gracias por unirte! Ahora tu cuenta es permanente.',
      plan_changed: 'El plan se actualizó correctamente',
      team_update_failure: 'Ha ocurrido un error y no pudimos actualizar el equipo',
      teammate_removed: 'Has quitado a %{name} del equipo',
      team_invitation_removed: 'Has cancelado la invitación al equipo.',
      unarchived_team: 'Tu equipo fue desarchivado correctamente.',
      unauthorized_action: 'No estás autorizado a realizar esta acción.',
      unauthorized_retro: 'No estás autorizado para ver esta retro.',
      unexpected_error: 'Ocurrió un error inesperado. Intenta recargar la página.',
      user_update_failure: 'Ha ocurrido un error y no pudimos actualizar tu usuario',
      user_update_success: 'El usuario ha sido actualizado correctamente',
      workspace_deleted: 'Espacio de trabajo eliminado',
      workspace_member_removed: 'Has quitado a %{name} del espacio de trabajo',
      workspace_seats_updated: 'Se actualizó el número de asientos correctamente.',
      workspace_update_failure: 'Ha ocurrido un error y no pudimos actualizar tu espacio de trabajo'
    },
    groups: {
      name: {
        default: 'Grupo sin título'
      },
      drag_here_new_group: 'Arrastra una tarjeta a un nuevo grupo',
      add_to_new_group: 'Añadir a un nuevo grupo',
      drag_here: 'Arrastra una tarjeta a este grupo',
      add_to_this_group: 'Añadir a este grupo',
      x_is_renaming: '%{name} está editando el nombre del grupo...',
      votes: 'Sin votos | 1 voto | %{n} votos',
      vote_button: {
        used_weight: 'Solo puedes votar +%{weight} una vez.',
        group_voted: 'Solo puedes votar cada grupo una vez.'
      }
    },
    mobile_not_supported: {
      heading: '¡Lo sentimos!',
      subheading: 'Aún no tenemos soporte para dispositivos móviles.',
      back: 'Volver a la página principal'
    },
    motivation_picker: {
      awesome: 'Genial',
      awful: 'Terrible',
      happy: 'Feliz',
      meh: 'Meh',
      sad: 'Triste'
    },
    nav: {
      about_us: 'Nosotros',
      blog: 'Blog',
      get_started: 'Comenzar ahora',
      home: 'Home',
      pricing: 'Planes',
      profile: 'Perfil',
      retros: 'Retros',
      sign_in: 'Iniciar sesión',
      sign_out: 'Cerrar sesión',
      tooltips: {
        archive: 'Retros archivadas',
        locale: 'Switch to english',
        preferences: 'Ir a preferencias del usuario',
        theme: {
          dark: 'Cambiar a modo oscuro',
          light: 'Cambiar a modo claro'
        }
      }
    },
    new_retro: {
      participant_slots_picker: {
        you: 'tú',
        slots_sr: 'Número de participantes',
        only_moderate: 'No voy a escribir tarjetas, solo moderar'
      },
      participants_picker: {
        drawer_title: 'Compañeros',
        drawer_ending_button: 'Añadir a un nuevo compañero',
        i_participate_text_1: 'Debido a que estás creando esta retro, tu eres el ',
        i_participate_text_2: 'Moderador',
        i_participate_checkbox: 'Yo también escribiré y compartiré',
        not_in_team: 'Nuevo en el equipo',
        not_in_workspace: 'No en %{workspace} aún'
      }
    },
    brief: {
      title: 'Resumen de la retro',
      export: {
        title: 'Exportar',
        markdown: 'Markdown'
      },
      action_plan: 'Planes de Acción',
      participants: 'Participantes',
      groups_and_topics: 'Grupos y Tarjetas'
    },
    resend_email_button: {
      resend: 'Reenviar correo',
      sent: 'Enviado!'
    },
    review: {
      title: '¡Casi listos!',
      subtitle: '¿Nos falta algo?'
    },
    sidebar: {
      add_participant: 'Añadir participante',
      group: {
        default_name: 'Grupo sin título',
        votes: 'Sin votos | 1 voto | %{n} votos'
      },
      moderator_control: {
        discuss: {
          button: '+1 min',
          subtitle_with_group_name: 'Discutir %{groupName}',
          subtitle_with_group_number: 'Discutir grupo %{groupNumber}',
          subtitle_without_group: 'Discutir',
          title: 'Tiempo de'
        },
        grouping: {
          subtitle: 'Chequea con el equipo si los grupos lucen bien',
          title: 'Tiempo de agrupar'
        },
        posting: {
          subtitle: 'Fase de compartir',
          title: 'Tiempo restante'
        },
        retro: {
          subtitle: 'Finalizar retro',
          title: 'Tiempo restante'
        },
        voting: {
          subtitle: 'Pídele a los participantes que voten que grupo quieren discutir primero',
          title: 'Tiempo de votar'
        },
        writing: {
          subtitle: 'Fase de escribir',
          title: 'Tiempo restante'
        }
      },
      cta_control: {
        discuss: {
          subtitle: '¡y tomar acción!',
          title: 'Tiempo de discutir'
        },
        grouping: {
          button: 'Tengo sugerencias',
          subtitle_with_suggestions: 'Ayuda al moderador a agrupar',
          subtitle_without_suggestions: '¿Te gustaría hacer sugerencias?',
          title: 'Tiempo de agrupar'
        },
        posting: {
          button: 'Terminé de compartir',
          other_subtitle: 'Es el turno de %{name}',
          title: 'Tiempo de compartir',
          you_subtitle: 'Es tu turno'
        },
        setup: {
          ice_breakers_button: 'Rompe el hielo',
          ice_breakers_subtitle: 'La retro fue agendada para las %{time}',
          ice_breakers_title: 'Hace frío aquí'
        },
        voting: {
          done_button: 'Seguir votando',
          not_done_button: 'Terminé de votar',
          subtitle: '¿Que grupo te gustaría discutir primero?',
          title: 'Tiempo de votar'
        },
        writing: {
          done_button: 'Seguir escribiendo',
          not_done_button: 'Terminé de escribir',
          subtitle: 'Solo tú puedes ver lo que escribes',
          title: 'Escribe tus tarjetas'
        }
      },
      participants: {
        invited: 'Invitación pendiente',
        title: 'Participantes'
      },
      topics: {
        subtitle: 'Sólo tu puedes verlas',
        title: 'Tus tarjetas'
      }
    },
    lobby: {
      heading: 'Espera mientras tus compañeros se unen',
      previous_action_items: 'Planes de Acción de la última retro',
      ice_breakers: {
        asking_dialog: {
          cancel: 'Cancelar',
          input_error: 'La pregunta es muy larga, prueba una más corta',
          input_placeholder: 'Pregunta algo divertido...',
          send: 'Enviar pregunta',
          warm_up:
            'Calienta la conversación preguntándole algo divertido a tus compañeros de equipo'
        },
        answering_dialog: {
          input_error: 'La respuesta debe tener un máximo de 40 carácteres',
          input_hint: 'Máximo 40 caracteres',
          input_placeholder: 'Responde brevemente...',
          send: 'Compartir con todos'
        },
        dialog: {
          subtitle:
            'Las preguntas para romper el hielo son una excelente manera de involucrar a tu equipo antes de una reunión retrospectiva.',
          title: '¡Rompamos el hielo!'
        },
        free_modal: {
          title: 'Rompe hielos es una funcionalidad de PRO',
          paragraph_1:
            '👉Las preguntas para romper el hielo son una excelente manera de involucrar a tu equipo antes de una reunión retrospectiva.',
          paragraph_2:
            '👉Construye una conexión profunda con tu equipo haciéndoles preguntas divertidas y abiertas.',
          paragraph_3:
            '👉El resultado de la retrospectiva puede beneficiarse de este tipo de actividades.',
          upgrade_suggestion: 'Mejora tu plan para usar las preguntas rompe hielo y más...',
          accept: 'Entiendo'
        }
      }
    },
    waiting_room: {
      heading: 'Por favor espera a que el moderador te permita entrar'
    },
    writing: {
      done: 'Espera mientras tus compañeros terminan de escribir',
      watcher: 'Espera mientras los participantes escriben sus tarjetas'
    },
    progress: {
      title: 'Progreso de la Retro',
      close: 'Cerrar',
      phases: {
        lobby: 'Lobby',
        write: 'Escribir',
        share: 'Compartir',
        group: 'Agrupar',
        vote: 'Votar',
        discuss: 'Discutir',
        review: 'Revisar'
      },
      user_list: 'Usuarios',
      start_writing: 'Empezar a escribir',
      start_posting: 'Terminar fase de escribir',
      end_other_turn: 'Terminar el turno de %{name}',
      start_voting: 'Los grupos se ven bien',
      start_discussion: 'Terminar fase de votación',
      next_group: {
        writing: 'Termina de escribir el plan de acción para poder avanzar.',
        other_writing:
          'Otro participante está escribiendo un plan de acción, por favor espera a que termine. Como moderador, también puedes forzar el guardado del plan de acción.',
        cta: 'Siguiente'
      },
      start_review: 'Ir a revisión final',
      finish_retro: 'Terminar Retro',
      dialogs: {
        start_writing: {
          heading: '¿Empezar la fase de escribir?',
          before: 'Algunos participantes no han llegado.',
          cancel: 'No, seguir en el lobby',
          confirm: 'Sí, empezar a escribir'
        },
        start_posting: {
          heading: '¿Empezar a compartir?',
          before: 'Algunos participantes aún no han terminado de escribir.',
          cancel: 'No, seguir escribiendo',
          confirm: 'Sí, empezar a compartir'
        },
        your_turn: {
          heading: 'Es tu turno de compartir.',
          after: 'Arrastra tus tarjetas a grupos para hacerlos públicos.',
          confirm: 'Lo tengo'
        },
        end_turn: {
          before: {
            topics_missing: 'No compartiste todas tus tarjetas.'
          },
          heading: '¿Seguro que deseas terminar tu turno?',
          after: {
            topics_missing: 'Las tarjetas no compartidas se perderán.',
            no_undo: '@:common.no_undo'
          },
          cancel: 'No, seguir compartiendo',
          confirm: 'Sí, terminar mi turno'
        },
        end_other_turn: {
          heading: '¿Seguro que deseas terminar el turno de %{name}?',
          after: '%{name} escribió %{n} tarjetas. @:common.no_undo',
          cancel: 'No, dejar que sigan compartiendo',
          confirm: 'Sí, terminar su turno'
        },
        ok_with_groups: {
          cancel: {
            moderator: 'Sí, quiero hacer cambios',
            participant: 'Sí, tengo sugerencias'
          },
          confirm: 'Los grupos están bien',
          heading: '¿Harías algún cambio a cómo las tarjetas están separados en grupos?'
        },
        start_voting: {
          confirm: 'Sí, empezar a votar',
          cancel: 'No, seguir agrupando',
          before: 'Por favor consulta con los participantes si están bien con los grupos.',
          heading: '¿Seguro que quieres empezar a votar?'
        },
        start_discussion: {
          confirm: 'Sí, empezar discusión',
          cancel: 'No, seguir votando',
          before: 'Algunos participantes aún están votando.',
          heading: '¿Seguro que quieres empezar la discusión?'
        },
        choose_review_or_next_group: {
          heading: '¿Qué deseas hacer?',
          after: 'Podrás agregar más acciones luego en la revisión.',
          time_left: 'Tienes {0} restante en la retro.',
          discuss_group_n: 'Discutir el grupo %{n}',
          go_to_review: 'Ir a la revisión',
          cancel: 'Atrás'
        },
        next_group: {
          headings: {
            how_much_time: '¿Cuánto tiempo para discutir el Grupo %{n}?',
            one_more: '¿Discutir un grupo más o ir a la revisión final?'
          },
          after: {
            add_more_time: 'Puedes agregar más tiempo después.',
            time_to_review: 'Necesitarás un par de minutos para revisar los Planes de Acción.'
          },
          time_left: 'Tienes {0} restante en la retro.',
          go_to_review: 'Ir a la revisión',
          cancel: 'Atrás',
          one_more: 'Un grupo más (3 min)'
        },
        start_review: {
          confirm: 'Sí, ir a revisión',
          cancel: 'No, seguir discutiendo',
          heading: '¿Seguro que quieres ir a la revisión?'
        },
        finish_retro: {
          confirm: 'Sí, finalizar retro',
          cancel: 'Cancelar',
          heading: '¿Seguro que quieres finalizar la retro?'
        }
      }
    },
    topics: {
      cancel: '@:common.cancel',
      delete: {
        tooltip: 'Eliminar',
        question: '¿Seguro que quieres borrar la tarjeta?',
        after: '@:common.no_undo',
        cancel: '@:common.cancel',
        confirm: '@:common.delete'
      },
      edit: {
        tooltip: 'Editar'
      },
      new: {
        add: '@:common.add',
        cancel: '@:common.cancel',
        clear: 'Limpiar',
        edit_has_no_changes: 'No hay cambios que guardar',
        edit_topic: 'Editar Tarjeta',
        empty_description: 'La descripción no puede ser vacía',
        heading: 'Es momento de escribir',
        new_topic: 'Nueva Tarjeta',
        no_topic_type: 'Elije un tipo de tarjeta primero',
        placeholder: 'Escribe una nueva tarjeta...',
        save: 'Guardar',
        subheading: 'Las tarjetas son privadas hasta que decidas compartirlas.',
        too_long: '¡Demasiado largo! Intenta mantenerlo breve.',
        topic_limit_reached:
          'Has alcanzado el límite de tarjetas. Aún puedes editar y/o eliminar tus tarjetas si quieres.'
      },
      private_topics: {
        write_up_to_n: 'Escribe hasta %{n} tarjetas.'
      },
      reaction_pill: {
        and: '@:common.and'
      },
      share: 'Compartir >',
      sharing: {
        wrote: 'escribió'
      }
    },
    users: {
      add_participant_slot: {
        add: 'Añadir participante'
      },
      badges: {
        alert: 'Tiene sugerencias',
        awesome: 'Genial',
        awful: 'Terrible',
        disconnected: 'Ausente',
        done: 'Listo',
        happy: 'Feliz',
        meh: 'Meh',
        sad: 'Triste',
        working: 'Trabajando'
      },
      context: {
        end_turn: 'Terminar turno',
        make_moderator: 'Hacer moderador',
        remove_participant: 'Remover de la retro',
        remove_slot: 'Remover vacante'
      },
      empty_slot: 'Esperando por el participante',
      kick: {
        after:
          'Estás a punto de remover a %{name} de la retro. Si lo haces, saldrá de la retro y no podrá volver a entrar.',
        cancel: 'No, cancelar',
        confirm: 'Sí, remover',
        question: '¿Remover a %{name}?'
      },
      labels: {
        done_voting: 'Terminó de votar',
        done_writing: 'Terminó de escribir',
        has_suggestions: 'Tiene sugerencias',
        moderator: '@:common.moderator',
        sharing: 'Compartiendo'
      },
      participant_status: {
        moderator: '@:common.moderator',
        online: 'Conectado'
      },
      title: 'Usuarios',
      waiting_room_list: {
        accept_all: 'Aceptar todos',
        title: 'Acepta o rechaza a los participantes que quieren unirse'
      },
      waiting_room_list_item: {
        accept: 'Aceptar',
        reject: {
          cancel: '@:common.cancel',
          confirm: 'Sí, rechazar',
          initial: 'Rechazar'
        },
        temporary_user: 'Usuario temporal'
      }
    },
    user_password_form: {
      cancel: 'Cancelar',
      forgot_password: 'Olvidaste tu contraseña?',
      new_password_confirmation: 'Confirmación nueva contraseña',
      new_password: 'Nueva contraseña',
      password: 'Contraseña actual',
      password_should_be_longer: 'La contraseña debe tener más de 8 caracteres',
      passwords_dont_match: 'Las contraseñas no coinciden',
      password_already_in_use: 'Esta contraseña ya está en uso',
      save: 'Guardar'
    },
    user_profile_form: {
      accepted_emails: 'Acepto recibir mensajes de RetroAlly',
      cancel: 'Cancelar',
      change_password: 'Cambiar contraseña',
      email: 'Email',
      google_password:
        'Te has registrado con una cuenta de Google. Por lo tanto, no puedes cambiar tu correo electrónico o establecer una contraseña.',
      name: 'Nombre',
      no_password: 'Usuario sin contraseña',
      password: 'Contraseña',
      save: 'Guardar'
    },
    join_retro: {
      welcome: 'Hola, %{name}',
      already_started: 'La retro ya ha empezado. ¡Pero aún puedes unirte!',
      join: 'Unirme a la retro',
      watch: 'Solo voy a mirar',
      join_call: {
        text: 'No olvides también {0} si aún no lo hiciste.',
        link: 'unirte a la llamada'
      },
      not_listed:
        'No fuiste listado como participante para esta retro. Si crees que se trata de un error, por favor contacta al moderador. También puedes mirar la retro.',
      no_slots:
        'No hay más espacios de participante disponibles en esta retro. Si quieres unirte, contacta al moderador.'
    },
    login_form: {
      form: {
        email: 'Correo electrónico',
        forgot_password: '¿Olvidaste tu contraseña?',
        minimum: 'Mínimo 8 caracteres',
        no_account: '¿No tenés cuenta?',
        password: 'Contraseña',
        submit: 'Iniciar sesión'
      },
      google_user:
        'Este mail pertenece a una cuenta de Google. Por favor, inicie sesión usando Google',
      invalid_credentials: 'Mail o contraseña inválidos',
      legal: {
        acceptance: 'Al continuar, aceptas nuestros {tos} y {privacy_policy}',
        privacy_policy: 'Política de Privacidad',
        tos: 'Términos y Condiciones'
      },
      sign_up: {
        name: 'Nombre',
        submit: 'Crear cuenta'
      }
    },
    motivation_form: {
      description: 'Por favor ingresa como te sientes en el equipo',
      join_call_text_1: 'No olvides ',
      join_call_text_2: ' en caso de que no lo hayas hecho aún',
      join_call_link: 'unirte a la llamada',
      link_copied: 'Copiado!',
      no_motivation: 'Elije un nivel de motivación para continuar',
      retro_link: 'Aquí está el enlace a tu retro.',
      submit: '@:common.submit',
      too_early: 'Esta retro empieza %{datetime}. Por favor vuelve a esta página en ese momento.',
      time_on_date: 'a las %{time} el %{date}',
      title: '¿Cómo te sientes?'
    },
    pricing: {
      title: 'Elige el plan apropiado para tu equipo',
      subtitle: 'No se requiere tarjeta de crédito',
      pricing_options: {
        annual: 'por persona al mes, con facturación anual',
        buy_pro: 'Comprar PRO',
        choose_free: 'Elegir STARTER',
        choose_pro: 'Elegir PRO',
        coming_soon: 'Próximamente',
        current_plan: 'Plan actual',
        free_limit_reached: 'Límite alcanzado',
        get_started: 'Comenzar',
        monthly: 'por persona al mes, con facturación mensual',
        selected_plan: 'Plan seleccionado',
        update_to_free: 'Pasa a STARTER',
        update_to_pro: 'Cambia a PRO',
        what_is_included: 'Incluye',
        already_own_this_plan: 'Ya tienes un espacio de trabajo con este plan',
        plans: {
          starter: {
            name: 'Starter',
            free: 'Gratis',
            subtitle: 'Para siempre'
          },
          pro: {
            name: 'Pro'
          },
          premium: {
            name: 'Premium'
          }
        },
        features: {
          run_retros: 'Hacer infinitas retrospectivas',
          retro_activities: 'Actividades de retrospectiva',
          action_items: 'Seguimiento de plan de acción',
          teams: 'Equipos ilimitados',
          ice_breakers: 'Rompe hielos',
          slack: 'Integración con Slack',
          health_checks: 'Chequeos del estado del equipo',
          support: 'Soporte personalizado',
          customer_success_manager: 'Customer Success Manager dedicado',
          onboarding: 'Programa de onboarding personalizado'
        },
        free_activities_limit: 'Hasta 2',
        refund_policy: 'Reembolso garantizado en los primeros 45 días',
        mobile_activities: {
          starter: 'Hasta 2 actividades de retrospectiva',
          pro_and_premium: '+10 actividades de retrospectiva'
        },
        toggle: {
          monthly: 'Mensual',
          annual: 'Anual'
        }
      },
      faqs: {
        title: 'Preguntas Frecuentes',
        first: {
          question: '¿Cómo funciona el modelo de suscripción?',
          answer:
            'Cobramos por asiento en la cuenta. Una persona puede ser parte de múltiples equipos, pero lo contaremos como un solo asiento siendo usado.'
        },
        second: {
          question: '¿Ofrecen una prueba gratuita?',
          answer:
            'Puede registrarse para obtener la cuenta Pro y disfrutar de una ventana de 45 días en la que puede cancelar su suscripción en cualquier momento y le reembolsaremos cada centavo, sin hacer preguntas.'
        },
        third: {
          question: '¿Qué es un equipo?',
          answer:
            'Un equipo es un grupo de usuarios que normalmente trabajan juntos. Organizar a los usuarios en equipos le permite realizar un seguimiento de su progreso y planes de acción. Los usuarios pueden formar parte de varios equipos.'
        },
        fourth: {
          question: '¿Hay un límite en la cantidad de equipos que puede tener mi cuenta?',
          answer: 'Puedes crear todos los equipos que quieras.'
        },
        fifth: {
          question: '¿Qué formas de pago aceptan?',
          answer:
            'Los clientes con suscripción Pro deben pagar con tarjeta de crédito. Los clientes con suscripción Premium pueden optar por solicitar facturas y pagar a través de ACH o transferencias bancarias.'
        },
        sixth: {
          question: '¿Cómo puedo cancelar mi suscripción?',
          answer: 'Si bien no nos gustaría que se vaya, puede cancelar su suscripción aquí.'
        }
      }
    },
    retro_list: {
      activity: 'Actividad',
      date: 'Fecha',
      moderator: 'Tú eres el moderador',
      name: 'Nombre',
      participants: 'Participantes',
      search: 'Buscar',
      search_by: 'nombre de retro',
      status: {
        title: 'Estado',
        in_progress: 'En curso',
        finished: 'Terminada',
        created: 'Creada'
      }
    },
    share_link_dialog: {
      before: 'Ahora puedes compartir la retro con tu equipo.',
      heading: 'Aquí está el enlace a tu retro',
      after:
        'Si necesitas encontrarlo de nuevo, haz click en el nombre de la retro arriba a la izquierda o copia el contenido de tu barra de direcciones.',
      cancel: 'Cerrar sin copiar',
      confirm: 'Copiar enlace y cerrar'
    },
    sign_up_form: {
      accepted_emails: 'Acepto recibir mensajes de RetroAlly',
      email: 'Correo electrónico',
      email_taken: 'Esa dirección de correo ya está en uso',
      invalid_email: 'Correo electrónico inválido',
      invalid_name: 'Nombre inválido',
      name: 'Nombre',
      new_password: 'Ingresa contraseña',
      new_password_requirement: 'Mínimo 8 caracteres',
      new_password_too_short: 'La contraseña requiere un mínimo de 8 caracteres',
      password_confirm: 'Confirma contraseña',
      password_confirmation_mismatch: 'La confirmación de la contraseña no coincide',
      submit: 'Crear cuenta',
      update: 'Actualizar'
    }
  },
  errors: {
    email: {
      invalid: 'Correo inválido'
    },
    name: {
      empty: 'El nombre no puede estar vacío',
      too_long: 'El nombre es muy largo, prueba uno más corto'
    },
    no_changes: 'No hay cambios',
    unavailable_seats:
      'Ya no quedan asientos disponibles, solo puedes invitar usuarios ya existentes en el espacio de trabajo'
  },
  views: {
    workspaces: {
      change_plan: {
        choose_plan_title: 'Elige tu nuevo plan',
        features_title: '¿Estás seguro de que quieres desmejorar el plan de tu espacio de trabajo?',
        features_subtitle: 'Al desmejorar perderás:',
        choose_team_title: 'Elige el equipo que deseas preservar',
        choose_team_subtitle:
          'Al desmejorar tu plan no podrás tener más de 1 equipo, el resto serán archivados',
        step_1: 'Cambiar plan',
        step_2: 'Funciones',
        step_3: 'Elegir Team',
        select_a_team: 'Selecciona un equipo a preservar',
        select_new_plan: 'Selecciona un nuevo plan',
        next: '@:common.next',
        previous: '@:common.previous',
        downgrade: 'Cambiar plan',
        downgrade_now: 'Cambiar plan ahora',
        upgrade: 'Mejorar',
        retro_in_progress: 'Retrospectiva en progreso',
        retro_in_progress_warning:
          'Tienes retrospectivas en progreso. Al bajar de categoría, los equipos no podrán terminar la retrospectiva. ¿Quieres bajar de categoría de todos modos?',
        cancel: '@:common.cancel'
      },
      delete: {
        cancel: '@:common.cancel',
        confirmation_text:
          'Al hacer click en "Eliminar" toda la información de tu espacio de trabajo como los usuarios, equipos y retrospectivas serán destruidas.',
        contract_term_ends: 'El plazo de tu contrato termina en %{term}',
        delete: 'Eliminar',
        dialog: {
          cancel: '@:common.cancel',
          confirm: 'Sí, eliminar',
          subtitle: 'Tu subscripción será cancelada el %{term}',
          title: '¿Estás seguro que quieres eliminar el espacio de trabajo?'
        },
        title: 'Eliminar espacio de trabajo',
        warning_text:
          'Tú y todos los usuarios del espacio de trabajo no podrán entrar ni obtener información del mismo.'
      },
      edit: {
        avatar: 'Imagen del espacio de trabajo',
        billing_details: 'Detalles de la facturación',
        cancel: '@:common.cancel',
        change_plan: 'Cambiar plan',
        delete: 'Eliminar espacio de trabajo',
        general: 'General',
        manage_payment_info: 'Administrar información de pago',
        manage_seats: 'Administrar asientos',
        money_guarantee: 'Reembolso garantizado en los primeros 45 días',
        name: 'Nombre del espacio de trabajo',
        next_billing_date: 'Su próxima fecha de facturación es %{date}',
        plan_details: 'Detalles del plan',
        plan_name_free: 'STARTER',
        plan_name_pro: 'PRO',
        save: '@:common.save',
        settings: 'Configuración del espacio de trabajo',
        subscription_and_billing: 'Suscripción y facturación',
        total_seats: 'Asientos totales'
      },
      index: {
        create_workspace: 'Crear espacio de trabajo',
        different_workspace: '¿Buscando otro espacio de trabajo?',
        different_email: 'Prueba usando otra cuenta',
        heading: 'Crea un espacio de trabajo',
        or_open: 'o selecciona un espacio de trabajo',
        subheading:
          'RetroAlly te permite tener todos los equipos que quieras dentro de tu espacio de trabajo. De esta forma puedes hacer un seguimiento de retros, planes de acción y más.'
      },
      new: {
        create_workspace: 'Crear espacio de trabajo',
        invalid_emails: 'Hay al menos un correo inválido',
        next: 'Siguiente',
        previous: '@:common.previous',
        workspace: {
          heading: 'Crea un espacio de trabajo',
          subheading:
            'Retroally te permite tener todos los equipos que quieras en tu espacio de trabajo, de esta manera puedes seguir las retros, planes de acción y más.',
          title: 'Espacio de trabajo'
        },
        teamCreation: {
          heading: '¡Genial! ¡Comencemos!',
          subheading:
            'Retroally esta organizado por equipos, los usuarios pueden unirse a varios equipos. Sin cargo addicional. Luego podrás crear tantos equipos como desees.',
          title: 'Equipo'
        },
        invites: {
          heading: '¡Último paso! Invita a tus compañeros',
          subheading:
            'Invita a tus compañeros a unirse al equipo y empezar a hacer retrospectivas eficaces',
          title: 'Compañeros'
        },
        choosePlan: {
          heading: 'Elige el plan adecuado para tu espacio de trabajo',
          subheading: '',
          title: 'Plan'
        }
      },
      members: {
        add_user: 'Agregar usuario',
        cancel_invite: 'Cancelar invitación',
        email_col: 'Correo electrónico',
        joined_col: 'Fecha de ingreso',
        name_col: 'Nombre del usuario',
        remove_member: 'Remover del espacio de trabajo',
        remove_member_modal: {
          body: 'Cuando remueves un miembro, este no podrá volver a acceder al espacio de trabajo, equipo y retros del equipo. ¿Estás seguro de que quieres removerlo de todas formas?',
          cancel: '@:common.cancel',
          confirm: 'Sí, remover',
          header: '¿Remover de %{workspace}?'
        },
        resend_invite: 'Reenviar invitación',
        role_col: 'Rol',
        sent_invite: 'Enviada',
        title: 'Usuarios del espacio de trabajo',
        buy_more: 'Comprar más',
        seats_available: '%{taken} asientos ocupados, %{available} disponibles'
      },
      seats: {
        buy: 'Comprar asientos',
        delete: 'Borrar seats',
        title: 'Administrar asientos'
      }
    },
    teams: {
      new: {
        buySeats: {
          caption: '¿Cuántos necesitas?',
          heading: 'Compra asientos para ellos',
          seats_available: '%{taken} asientos ocupados, %{available} disponibles',
          subheading:
            'Tus compañeros de equipo podrán unirse a este y otros equipos en el espacio de trabajo',
          title: 'Compra asientos'
        },
        create_team: 'Crear Equipo',
        invalid_emails: 'Hay al menos un correo inválido',
        invalid_seats_amount:
          'Tienes que comprar al menos %{seats} asientos para tus nuevas invitaciones',
        invalid_one_seat_amount: 'Tienes que comprar al menos 1 asiento para tu nueva invitación',
        invites: {
          heading: 'Invita a tus compañeros',
          subheading:
            'Invita a tus compañeros a unirse al equipo y empezar a hacer retrospectivas eficaces',
          title: 'Compañeros'
        },
        next: '@:common.next',
        previous: '@:common.previous',
        teamName: {
          heading: 'Crear Equipo',
          subheading: 'Retroally está organizada por equipos, los usuarios pueden unirse a varios',
          title: 'Equipo'
        }
      },
      edit: {
        archive: 'Archivar equipo',
        archive_dialog: {
          cancel: '@:common.cancel',
          confirm: 'Sí, archivar',
          text: 'Cuando archive un equipo, tú y tus compañeros de equipo no podrán interactuar con él. Toda la información del equipo y los datos de retrospectivas pasadas se almacenarán en nuestros servidores.',
          title: '¿Archivar equipo?'
        },
        cancel: '@:common.cancel',
        edit_team: 'Editar equipo',
        modal: {
          before_leave: '¿Estás seguro que deseas irte? Tienes cambios sin guardar.',
          heading: '¿Seguro que deseas deshacer tus cambios?',
          confirm: 'Sí, deshaz mis cambios'
        },
        team_name: '@:common.team_name',
        save: '@:common.save',
        sidebar: {
          add_teammate: 'Añadir compañero',
          teammates: 'Compañeros',
          modal: {
            cancel: '@:common.cancel',
            leave: {
              confirm: 'Sí, abandonar',
              description:
                'Al abandonar un equipo, no podrás acceder al mismo y sus retros. ¿Estás seguro que quieres abandonar el equipo?',
              heading: '¿Abandonar equipo?'
            },
            remove: {
              confirm: 'Sí, quitar',
              description:
                'Cuando quitas a un compañero, este no podrá acceder al equipo y sus retros. ¿Estás seguro que lo quieres remover?',
              heading: '¿Quitar del equipo?'
            }
          },
          menu: {
            leave: 'Irse del equipo',
            remove: 'Quitar del equipo',
            cancel_invite: 'Cancelar invitación'
          },
          pending_invite: 'Invitación pendiente'
        }
      },
      show: {
        content: {
          create_retro: 'Crear retro',
          empty_title: 'Hiciste un buen trabajo!',
          empty_subtitle: 'Crea tu primera retro con el equipo',
          retros: 'Retros'
        },
        sidebar: {
          maturity: {
            1: 'Formación',
            2: 'Conflicto',
            3: 'Normalización',
            4: 'Desempeño'
          },
          pending_invite: 'Invitación pendiente',
          teammates: 'Compañeros',
          team_maturity: 'Madurez del equipo'
        },
        card: {
          pending: 'Invitación pendiente'
        }
      },
      archived: {
        no_archived_teams: 'No hay equipos archivados',
        retroally_starter:
          'El plan STARTER de RetroAlly solo permite un equipo gratis. Actualiza tu plan ahora y archiva todos tus equipos.',
        search_by: 'nombre del equipo',
        team_name: 'Nombre del equipo',
        teammates: 'Compañeros',
        title: 'Equipos archivados',
        unarchive_team: 'Desarchivar equipo',
        unarchive_teams: 'Desarchivar equipos?',
        upgrade_button: 'Actualiza ahora'
      }
    },
    auth: {
      check_your_inbox: {
        change_email: 'Cambiar correo',
        contact_us: '@:common.contact_us',
        message: 'Haz click en el link que enviamos a{0}{1} para completar tu registro',
        subheading:
          'Confirma tu email en los siguientes 5 días a partir de la creación de la cuenta',
        title: 'Revisa tu inbox'
      },
      complete_account: {
        form: {
          email: 'Correo electrónico',
          email_taken: 'Esa dirección de correo ya está en uso',
          invalid_email: 'Correo electrónico inválido',
          new_password: 'Nueva contraseña',
          new_password_requirement: 'Mínimo 8 caracteres',
          new_password_too_short: 'La contraseña requiere un mínimo de 8 caracteres',
          password_confirm: 'Confirma contraseña',
          password_confirmation_mismatch: 'La confirmación de la contraseña no coincide',
          submit: 'Completar cuenta'
        },
        heading: 'Completa tu cuenta',
        subheading:
          'Estamos trabajando en algunas funciones interesantes que necesitan que todos nuestros usuarios tengan una dirección de correo electrónico asociada. A partir del 1ero de Febrero de 2022, ya no podrá utilizar esta cuenta a menos que la complete.'
      },
      confirmation_expired: {
        back_home: 'Ir al inicio',
        check_email: 'Revisa tu correo electrónico!',
        new_link: 'Envíame un correo',
        expired:
          'Te envíaremos un nuevo enlace a{break}{email} para completar{break}la creación de tu cuenta',
        follow_link:
          'Haz click en el enlace envíado a{break}{email} para completar{break}la creación de tu cuenta',
        link_expired: 'Disculpa, tu link ha expirado!'
      },
      reset_email: {
        password_recovery_title: '¿Olvidaste tu contraseña?',
        password_recovery_message: 'Ingresa el correo electrónico que utilizas en retroally.com',
        submission_title_made: 'Revisa tu correo electrónico!',
        submission_message_made: 'Enviamos instrucciones para restablecer tu contraseña a',
        contact_us: '@:common.contact_us',
        form: {
          email: 'Correo electrónico',
          submit: 'Restablecer contraseña',
          placeholder: "whatever{'@'}domain.com",
          back: 'Volver a inicio de sesión'
        }
      },
      reset_password: {
        form: {
          password: 'Nueva contraseña',
          password_confirmation: 'Confirmación de la nueva contraseña',
          submit: 'Cambiar contraseña'
        },
        invalid_link: 'Enlace inválido, por favor intente reestablecer su contraseña nuevamente',
        mismatched_passwords: 'Las contraseñas no coinciden',
        password_should_be_longer: 'La contraseña debe tener más de 8 caracteres',
        reset_password: 'Resetear contraseña'
      },
      sign_in: {
        sign_in_to_join: 'Inicia sesión para unirte a la retro',
        title: 'Iniciar sesión'
      },
      sign_up: {
        back_home: 'Ir al inicio',
        create_your_account: 'Ingresa tu información personal para crear una cuenta',
        requires_permanent_users: 'Esta retro requiere de una cuenta con un mail registrado',
        title: 'Bienvenido!',
        update_your_email: 'Actualiza tu correo'
      }
    },
    error: {
      go_home: 'Ir a casa',
      title: 'Parece que te has perdido, vayamos a casa'
    },
    join_retro_error: {
      title: 'Ups!',
      message: 'La retro que estas intentando acceder no existe o no tienes acceso a ella.',
      go_home: 'Ir a casa'
    },
    kicked_out_of_retro_error: {
      title: 'Ups!',
      message: 'Parece ser que fuiste echado de la retro.',
      go_home: 'Ir a casa'
    },
    landing: {
      hero: {
        heading: '¿Quieres sentirte más productivo en tus retrospectivas?',
        cta: 'Comenzar ahora',
        make_retros_effective: 'Retros efectivas',
        fruitful_discussions: 'Discusión fructífera',
        improve: 'Progreso en cada iteración'
      },
      easy_set_up: {
        heading: 'Fácil configuración',
        body: '¿Se te acabaron las ideas? ¿Es difícil planear una reunión efectiva? ¿Quieres saber qué es lo mejor para tu situación actual? Te sugerimos la mejor alternativa de entre una variedad de formatos de retro.'
      },
      timeboxed_flow: {
        heading: 'Flujo natural',
        body: 'Organizamos la reunión en pasos cronometrados para que sea más fácil organizar las interacciones del equipo sin que se haga tarde.'
      },
      write_in_private: {
        heading: 'Escribe en privado',
        body: 'No tienes que compartir todo lo que escribes. Escribe en privado y deja tus pensamientos fluir. Comparte tus tarjetas después una por una.'
      },
      discuss_and_collaborate: {
        heading: 'Discute y Colabora',
        body: 'Agrupa tarjetas, vota grupos, discute y crea planes de acción para tener mejores resultados en la próxima iteración.'
      },
      start_free: {
        heading: '¡Empieza a usar RetroAlly, gratis!',
        subheading: 'Retros efectivas para equipos efectivos.',
        cta: 'Comenzar'
      },
      footer: {
        contact_us: '@:common.contact_us'
      },
      customer_reviews: {
        heading: 'Reseñas de clientes',
        subheading: 'Qué dicen nuestros clientes sobre Retroally',
        customers: {
          alain_chuard: {
            company: 'CTO & Founder at Prisma',
            name: 'Alain Chuard',
            review:
              'Hemos estado usando RetroAlly durante algún tiempo y a todos en el equipo les encanta. Estoy muy impresionado con RetroAlly y el beneficio que ha aportado a nuestro equipo.',
            title: 'Al equipo le encanta'
          },
          felipe_cordoves: {
            company: 'Agile project manager at Globant',
            name: 'Felipe Cordoves',
            review:
              'Hace unos meses empezamos a probar RetroAlly y desde entonces nos acompaña en todas nuestras retros, renovado la forma en la que nuestro equipo se retroalimenta entre sí. La interfaz es super amigable e intuitiva de usar, fue muy rápido de adaptar a nuestra dinámica y ha aportado orden en un equipo de gran volumen.',
            title: 'Amigable e intuitiva'
          },
          jordi_adame: {
            company: 'Engineering Director at TheRealReal',
            name: 'Jordi Adame',
            review:
              'Hemos estado usando RetroAlly durante más de un año y nos ha ayudado a construir equipos más fuertes al fomentar la participación y satisfacer las necesidades de cada equipo a través de diferentes actividades. RetroAlly nos ayuda a crear una mejor documentación para nuestras reuniones retro, así como a dar seguimiento a los elementos de acción acordados.',
            title: 'Nos ayuda a construir equipos más fuertes'
          }
        }
      }
    },
    legal: {
      license_subscription: {
        heading: 'Suscripción a licencia',
        background: {
          heading: 'PRIMERO. (Antecedentes).',
          1: '“RetroAlly LLC” (en adelante “El LICENCIANTE”) ha desarrollado de manera autónoma e independiente la plataforma tecnológica – “SOFTWARE” o “la PLATAFORMA” – denominada “RETROALLY” la cual brinda a sus usuarios (en adelante “LICENCIATARIOS”) un sistema de gestión de proyectos focalizado fundamentalmente en el análisis retrospectivo de “sprints” o metas, con funcionalidades interactivas de comentarios, reuniones, discusiones, votación y agrupación de tópicos, asistidos por herramientas de inteligencia artificial, que generan como resultado final un reporte final de alto valor agregado.',
          2: 'El LICENCIANTE es propietario de todos los contenidos propios a “RETROALLY”, ya sea aquellos que se visualizan como aquellos que lo hacen operar y funcionar. Las marcas, logos, distintivos, tecnologías, código objeto y fuente, procesos y todo otro contenido susceptible de protección por propiedad intelectual son propiedad exclusiva del LICENCIANTE.'
        },
        object: {
          heading: 'SEGUNDO. (Objeto).',
          1: 'El LICENCIANTE otorga a los LIENCIATARIOS, una licencia de uso (en adelante “la LICENCIA”) gratuita, no exclusiva y revocable sobre RETROALLY que le permite utilizar las funcionalidades descritas en “1.1.”, condicionada a la aceptación de los presentes términos y condiciones, y a la finalización del proceso de suscripción de acuerdo con los datos solicitados por la PLATAFORMA. Durante el proceso de suscripción los LICENCIATARIOS podrán optar por una licencia onerosa la cual les brindará las funcionalidades o capacidades adicionales que se establecen en el propio proceso de suscripción.',
          2: 'Los LICENCIATARIOS reconocen que, en caso de que los datos ingresados en el proceso de suscripción representen a una tercera entidad – ya sea por el uso de una cuenta de correo corporativa, u otros datos suministrados – su aceptación se reputara como la aceptación de dicha entidad, quedando válidamente vinculada a los presentes términos y condiciones. En ese sentido, el aceptante declara que tiene la capacidad necesaria para aceptar los presentes términos y condiciones por si o por terceros y que estos constituyen obligaciones legales, válidas y vinculantes.',
          3: 'Los LICENCIATARIOS deberán asegurarse que los nombres y usuario y contraseñas (la cuenta) generados en el proceso de suscripción para acceder a la PLATAFORMA, serán seguros, confidenciales y permanecerán en todo momento exclusivamente en su poder. El LICENCIANTE no será responsable por la pérdida, uso indebido o alteración de datos en el sistema que sean producto de un manejo indebido de las credenciales de la cuenta. En todo momento la PLATAFORMA contará en todas sus interfaces e interacciones con medidas de seguridad media de la industria.'
        },
        terms: {
          heading: 'TERCERO. (Condiciones).',
          1: 'El LICENCIANTE se reserva el derecho en todo momento de modificar el SOFTWARE, alterando, incorporando o suprimiendo módulos o funcionalidades, así como modificar sus procesos e interfases. Podrá realizar estas modificaciones a su solo criterio y discrecionalidad.',
          2: 'El LICENCIANTE utilizará esfuerzos razonables propios al estándar medio de la industria para mantener el funcionamiento de la PLATAFORMA de una manera que minimice posibles errores o interrupciones. Sin perjuicio de ello, el funcionamiento de la PLATAFORMA podrá estar temporalmente indisponible por mantenimiento u otras causas razonables. El LICENCIANTE tampoco garantiza ni se obliga a que los servicios no sean interrumpidos, funcionen libres de errores, ni por pérdidas de contenidos almacenados. Las funcionalidades generadas por laPLATAFORMA, incluyendo los reportes finales, se proporcionan “tal cual”, sin garantía expresa o implícita de ningún tipo.'
        },
        personal_data: {
          heading: 'CUARTO. (Datos Personales).',
          1: 'Los datos personales recogidos durante el proceso de suscripción serán utilizados a únicos efectos de otorgar la LICENCIA. No serán utilizados para ninguna otra finalidad salvo el cumplimiento de estos términos y condiciones y la prestación de la LICENCIA, y no será transmitido a ningún tercero.Sin perjuicio de ello, eventualmente el LICENCIANTE podrá remitir información promocional u ofertas de productos o servicios propios o de terceros.',
          2: 'El LICENCIANTE no tratará de manera alguna los datos personales o información de cualquier naturaleza de los LICENCIATARIOS o que estos introduzcan en la PLATAFORMA. Estos datos o información únicamente serán utilizados a efectos de que la PLATAFORMA preste su funcionalidad y serán resguardados con las medidas de seguridad medias de la industria.',
          3: 'La actividad del LICENCIANTE se limita a otorgar una licencia de uso sobre el SOFTWARE. Los LICENCIATARIOS son los únicos responsables por la manera en que emplean el SOFTWARE incluyendo publicaciones, actividades o en general cualquier otro uso inadecuado. En ningún caso el LICENCIANTE será responsable directa o indirectamente, de manera alguna por las acciones de los LICENCIATARIOS.'
        },
        contents: {
          heading: 'QUINTO. (Contenidos).',
          1: 'Los LICENCIATARIOS no podrán utilizar el SOFTWARE o incluir contenidos que sean contrarios a las normativas de aplicación. Esto incluye, de manera no taxativa, la publicación de contenidos que sean discriminatorios, violentos, pornográficos, materiales protegidos por propiedad intelectual de terceros, información confidencial de terceros, etc.',
          2: 'Los LICENCIATARIOS garantizan que la información que almacenan en la plataforma es lícita y que no lesiona bienes o derechos de terceros. En cada caso aplicable, declara que ha adquirido y posee todos los derechos sobre los comentarios y contenidos que almacena en la plataforma. El LICENCIANTE no será en ningún caso responsable por ninguna información o contenido de cualquier naturaleza almacenada por los LICENCIATARIOS.',
          3: 'Los LICENCIATARIOS deberán abstenerse de publicar enlaces a sitios de terceros que incumplan la política de contenidos enunciada en los presentes términos y condiciones.',
          4: 'En todos los casos, el LICENCIANTE podrá eliminar de manera unilateral, discrecional y sin responsabilidad alguna, cualquier contenido que considere contrario a los presentes términos y condiciones, sin perjuicio de la rescisión de la LICENCIA.',
          5: 'Los LICENCIATARIOS autorizan al LICENCIANTE a otorgar sus datos personales, contenido de informaciones o publicaciones realizadas o albergadas en la PLATAFORMA, a cualquier autoridad policial, judicial o administrativa que solicite la información al LICENCIANTE formalmente en el marco de un proceso policial o jurisdiccional que este debidamente acreditado.',
          6: 'El LICENCIANTE no estará obligado a conservar registros ni contenidos de los LICENCIATARIOS, incluyendo los reportes finales. La PLATAFORMA no es un servicio de alojamiento de dato, por tanto, los LICENCIATARIOS deben tomar las medidas de resguardo pertinentes a efectos de alojar de manera segura en cuanto a la estabilidad y duración de sus informaciones y contenidos.'
        },
        indemnity: {
          heading: 'SEXTO. (Exoneración. Indemnidad).',
          1: 'Los LICENCIATARIOS exoneran expresamente al LICENCIANTE de toda responsabilidad civil, comercial, laboral, tributaria, penal, administrativa o de cualquier otra índole emergente del uso que haga de la LICENCIA',
          2: 'Los LICENCIATARIOS defenderán y mantendrán indemne y libre de daño al LICENCIANTE, sus empleados, directores, representantes y proveedores, por cualquier pérdida, gasto, daño o costos – incluyendo honorarios de abogados – que sean consecuencia de un incumplimiento de los presentestérminos y condiciones, ya sea de manera directa o a por terceros que utilicen su cuenta.'
        },
        termination: {
          heading: 'SEPTIMO. (Término del Contrato).',
          1: 'El LICENCIANTE podrá poner fin en cualquier momento a la licencia de uso y bloquear a los LICENCIATARIOS, si detectará que su servicio está siendo utilizado para cualquier otro fin que no es el establecido en estos términos y condiciones. Asimismo, tendrá la misma facultad si tuviera dudas razonables que los LICENCIATARIOS se encuentran realizando operaciones ilícitas, irregulares, fraudulentas, tendientes al engaño o en incumplimiento de estos términos y condiciones.',
          2: 'Asimismo, el LICENCIANTE está facultado a suspender transitoriamente cuentas de LICENCIATARIOS hasta tanto obtenga toda la información que considere suficiente a efectos decomprobar la actividad y finalidad lícita y conforme a estos términos y condiciones.',
          3: 'Fuera de los casos específicamente previstos, el LICENCIANTE podrá finalizar las licencias de uso que presta de manera general y definitiva para cualquier usuario mediante el otorgamiento de un pre-aviso de 7 días corridos.'
        },
        amendments: {
          heading: 'OCTAVO. (Modificaciones).',
          content:
            'Estos términos y condiciones podrán ser modificados por el LICENCIANTE, en cualquier momento, siempre que notifique a los LICENCIATARIOS sobre las modificaciones.  Esta notificación, como cualquier otra notificación a la que refieran estos términos y condiciones, podrán realizase en la propia aplicación o en la casilla de correo electrónico aportada durante el proceso de suscripción.'
        },
        law_and_jurisdiction: {
          heading: 'NOVENO. (Legislación y Jurisdicción aplicables).',
          content:
            'Este contrato se regirá por la legislación vigente en la República Oriental del Uruguay y las disputas relativas al cumplimiento de las obligaciones emergentes del mismo se dilucidarán ante los Tribunales judiciales ordinarios con sede en la ciudad de Montevideo.'
        },
        miscellaneous_provisions: {
          heading: 'DÉCIMO. (Previsiones varias).',
          1: 'La omisión en el ejercicio de cualquiera de los derechos derivados de este documento no configurará renuncia del mismo o de otros derechos derivados de este contrato, a menos que así se manifieste mediante escrito firmado por el renunciante.',
          2: 'La eventual nulidad, total o parcial, que pudiese declararse respecto de cualquiera de las cláusulas contenidas en el presente documento, no afectará ni influirá, de modo alguno, a las restantes estipulaciones que se mantendrán integralmente con el mismo vigor y fuerza jurídica.',
          3: 'Todos los contenidos de la presente página web / aplicación sean estos visibles, auditivos o aquellos que permiten el funcionamiento de la misma se encuentran protegidas por derechos de propiedad intelectual y son de exclusiva propiedad del LICENCIANTE, con excepción de aquellos contenidos almacenados por los LICENCIATARIOS en su uso de la PLATAFORMA. A título enunciativo las gráficas, colores relacionados, diseño de interfaces, audios, logos, software, información y todo demás contenido que permite el funcionamiento del sitio web y sus aplicaciones para smartphones son de exclusiva propiedad del LICENCIANTE; quedando prohibida su reproducción, copia o utilización no autorizada para cualquier finalidad.',
          4: 'En caso de que eventualmente se establezca una LICENCIA onerosa con distintas funcionalidades, el proceso de suscripción establecerá el precio y condiciones de pago.'
        }
      },
      privacy_policy: {
        heading: 'Política de privacidad',
        paragraphs: {
          '1': 'RetroAlly LLC (en adelante “Retroally”) es propietario del Sitio Web, contenidos y servicios que se encuentran alojados a través del nombre de dominio denominado www.retroally.com (en adelante el “Sitio”).\nEl acceso o utilización del Sitio implica la aceptación y consentimiento que Retroally recopile información que eventualmente pueda contener datos personales, ajustado a los términos dispuestos en esta Política de Privacidad.',
          '2': 'Retroally se obliga a tratar los datos personales suministrados por los Usuarios del Sitio exclusivamente en apego a las modalidades y finalidades detallados en esta Política de Privacidad y/o formularios de inscripción, y en cumplimiento estricto de lo dispuesto por las normas regulatorias del tratamiento y protección de datos personales de la República Oriental del Uruguay, en especial, por lo previsto por la Ley 18.831 y los Decretos 664/08,414/09 y 308/14.',
          '3': 'Retroally guardará con reserva y confidencialidad los datos personales a los cuales tenga acceso, utilizándolos únicamente con la finalidad que le fueron entregados y no dará acceso a ellos a ningún efecto. Sin perjuicio de lo establecido, Retroally podrá:',
          '3a': 'Proveer a terceros de información estadística, siempre que dicha información sea tratada en forma genérica no permitiendo la identificación de Usuarios particulares.',
          '3b': 'Retroally podrá dar a conocer datos personales u otra información a terceros (encargados de tratamiento) cuando dicha información sea requerida para el cumplimiento de la relación entre Retroally y el Usuario, tales como el cumplimiento de las funcionalidades, servicios o herramientas previstas por el Sitio Web. mediante el Sitio Web.',
          '3c': 'Retroally también podrá entregar información a terceros por imperativo legal ante requerimiento escrito por parte de autoridades administrativas o jurisdiccionales.',
          '3d': 'Retroally podrá enviarle a los usuarios materiales con ofertas o informaciones de otros productos o servicios, propios o de terceros.',
          '4': 'La información recopilada objeto de esta Política de Privacidad podrá alojarse en servidores propios o contratados, en territorio de la República Oriental del Uruguay o en el exterior, obligándose Retroally en todos los casos a sostener un nivel de protección de acuerdo al estándar medio de la industria. En ese sentido, Retroally asume, como obligación de medios, la implementación de todas las medidas de seguridad susceptibles de evitar que terceros no autorizados accedan a los datos, incluyendo la imposición de medidas de seguridad físicas. De igual manera, el acceso a los datos estará limitado a empleados o subcontratistas que deban acceder a los datos por razones de servicio.',
          '5': 'Retroally únicamente se obliga respecto a la utilización que el Usuario haga mediante ingreso directo al Sitio Web, no siendo por lo tanto responsable en caso de que el Usuario acceda por el redireccionamiento de sitios o aplicaciones de terceros.',
          '6': 'El Usuario es el único responsable por la utilización de su contraseña y por los datos personales que suministra, no debiendo compartirlos con terceros. La contraseña deberá ser modificada en forma periódica, en concordancia con las recomendaciones enunciadas en el Sitio Web y las comunicaciones expresamente autorizadas. El Usuario deberá abstenerse de responder a cualquier comunicación que reciba en una modalidad no acordada o explicitada específicamente en el Sitio Web.',
          '7': 'El Usuario declara que los datos suministrados al Sitio Web son ciertos, completos, veraces y correctos, siendo por tanto directamente responsable por cualquier consecuencia negativa que un incumplimiento de lo enunciado genere sobre sí mismo o terceros. El Usuario deberá en todo momento mantener estos datos actualizados e informar al Sitio Web respecto a cualquier cambio que sea relevante para el alcance de esta Política de Privacidad.\nEl Usuario dispondrá en todo momento de la posibilidad de editar su información personal en su perfil de usuario, pero en todos los casos ateniéndose a lo dispuesto en el párrafo precedente.',
          '8': 'Retroally podrá modificar esta Política de Privacidad de manera unilateral. Sin embargo, en todos los casos, las modificaciones entrarán en vigor al quinto día hábil contado desde que estas hayan sido publicadas en el Sitio Web, o informadas a el Usuario mediante el correo electrónico suministrado durante el proceso de suscripción.',
          '9': "El Usuario podrá en todo momento ejercer derechos de acceso, modificación, supresión o consulta, contactándose con la dirección de correo electrónico hello{'@'}retroally.com."
        }
      }
    },
    retro: {
      index: {
        archived_retros: 'Retrospectivas Archivadas',
        empty: {
          title: 'Crea tu primera retro',
          body: 'Aquí encontrarás todas tus retros.'
        },
        loading_retros: 'Cargando retros',
        more: 'Más',
        new_retro: 'Nueva Retro',
        retros: 'Retros'
      },
      new: {
        title: {
          with_date: 'Creando retro para el %{date}',
          no_date: 'Creando retro'
        },
        step_n: 'Paso %{n}',
        mode: {
          title: 'Elige tu modo de retro',
          one_time: {
            title: 'Modo independiente',
            description: 'Los participantes se pueden unir solo con su nombre',
            remark: '(Perfecto para probar)'
          }
        },
        participant_slots_picker: {
          title: '¿Cuántos participantes?'
        },
        participants_picker: {
          not_enough: 'Necesitas al menos un participante que escriba para crear una retro',
          subtitle: 'Desmarca compañeros si no concurrirán',
          title: '¿Quiénes estarán?'
        },
        time: {
          title: '¿Cuándo y cuánto tiempo?',
          start_time: 'Comienza a las',
          past_retro: 'No puedes fijar una retro en el pasado.',
          empty_time: 'Tienes que definir una hora.',
          duration: 'Dura',
          date_sr: 'Fecha de la retro',
          start_time_sr: 'Hora de inicio de la retro',
          retro_length_sr: 'Duración de la retro en minutos',
          minutes: 'min',
          retro_duration: {
            invalid_duration: 'La duración de la retro es inválida.',
            too_short: '%{minutes}min puede ser demasiado corto para tener una retrospectiva útil.',
            short: '%{minutes}min es un poco corto para una retro, pero debería funcionar.',
            normal: {
              no_minutes: '%{hours} h es apropiado para la mayoría de las retros.',
              with_minutes: '%{hours} h %{minutes} min es apropiado para la mayoría de las retros.'
            },
            long: {
              no_minutes: '%{hours} h es bastante tiempo para una retro, pero puede funcionar.',
              with_minutes:
                '%{hours} h %{minutes} min es bastante tiempo para una retro, pero puede funcionar.'
            },
            too_long: {
              no_minutes: '%{hours} h puede ser demasiado largo para tener una retro disfrutable.',
              with_minutes:
                '%{hours} h %{minutes} min puede ser demasiado largo para tener una retro disfrutable.'
            }
          }
        },
        activity: {
          title: '¿Qué vamos a hacer?',
          subtitle: 'Déjanos ayudarte a elegir la actividad de la retro',
          picker_title: 'Actividades',
          recommended: 'Recomendada',
          required: 'La retro debe tener una actividad.',
          activity_highlights: 'Características de la actividad',
          participants: {
            best_for: 'Óptima para entre %{min} y %{max} participantes'
          },
          maturity_1: {
            best_for: 'Buena para equipos formados recientemente'
          },
          maturity_2: {
            best_for: 'Buena para equipos con algo de experiencia trabajando juntos'
          },
          maturity_3: {
            best_for: 'Buena para equipos con experiencia trabajando juntos'
          },
          maturity_4: {
            best_for: 'Buena para equipos que se conocen muy bien'
          },
          project_stage: {
            heading: 'Estado del proyecto',
            label: '¿Qué situación describe mejor tu proyecto?',
            deadline: {
              label: 'Hay una fecha límite pronto',
              best_for: 'Buena para equipos con fechas límite apróximandose'
            },
            starting: {
              label: 'Empezando un nuevo proyecto',
              best_for: 'Buena para equipos iniciando un nuevo proyecto'
            },
            standard: {
              label: 'Nada especial'
            }
          }
        },
        advanced: {
          title: '¡Casi listo!',
          subtitle: 'Puedes saltear todo esto si no estás seguro.',
          call_link: {
            title: 'Enlace de llamada',
            placeholder: 'Pega el enlace de la llamada',
            description:
              'Ayuda al equipo a tener todo listo. Agrega el enlace de la llamada para poner tu retro en marcha más rápido.',
            too_long: 'El enlace a la llamada es demasiado largo',
            white_spaces: 'El enlace a la llamada no puede tener espacios',
            invalid_url:
              'El enlace a la llamada debe ser uno válido, por ejemplo: https://millamada.com'
          },
          max_topics: {
            title: 'Límite de tarjetas',
            toggle_label: 'Limitar cantidad de tarjetas',
            label: 'Máximo de tarjetas por participante',
            description:
              'Recomendamos fijar una cantidad máxima de tarjetas por participante para retros con 8 o más participantes.'
          },
          voting_mechanic: {
            title: 'Votación',
            description: 'Elige cómo votará el equipo los grupos de discusión.',
            options: {
              like: {
                title: 'Me gusta',
                description: 'Cada uno puede votar cualquier número de grupos.'
              },
              one_and_two: {
                title: 'Uno y Dos',
                description: 'Cada uno tiene dos votos: uno con valor 1 y otro con valor 2.'
              }
            }
          },
          retro_name: {
            name: 'Nombre de retro'
          },
          timing: {
            title: 'Distribución del tiempo',
            total: 'total',
            minutes: 'min',
            x_minutes_each: '%{x} min cada participante',
            turn_too_short: 'Menos de 2 min cada participante',
            description: 'Llenamos esto por ti. Puedes ajustarlo si quieres.',
            writing_duration_sr: 'Duración de fase de escribir en minutos',
            posting_duration_sr: 'Duración de fase de compartir en minutos',
            rest_duration_sr: 'Duración del resto de la retro en minutos',
            phases: {
              write: 'Tiempo para escribir',
              share: 'Tiempo para compartir',
              rest: 'Tiempo para agrupar, votar y discutir'
            },
            reset: 'Restablecer',
            values_too_long: 'Los tiempos de escribir y compartir son demasiado largos.',
            writing_duration_required: 'La duración de la fase de escribir debe ser positiva.',
            posting_duration_required: 'La duración de la fase de compartir debe ser positiva.'
          }
        },
        steps: {
          participants: 'Participantes',
          time: 'Tiempo',
          activity: 'Actividad',
          advanced: 'Avanzado'
        },
        controls: {
          previous: '@:common.previous',
          next: 'Siguiente',
          submit: 'Crear Retro'
        },
        plan_modal_title: 'Potencia tus reuniones retrospectivas mejorando tu plan'
      },
      show: {
        confirm_leave: '¿Estás seguro que deseas salir de la retro?',
        joining_channel: 'Uniéndose al canal',
        joining_retro: 'Uniéndose a la retro'
      }
    },
    profile: {
      user_password: {
        change_password: 'Cambiar contraseña'
      },
      user_profile: {
        avatar: 'Foto de perfil',
        confirm_leave: '@:common.confirm_leave',
        profile: 'Perfil',
        sign_out: 'Cerrar sesión'
      }
    },
    home: {
      title: 'Panel de bienvenida',
      new_retro: 'Nueva Retro',
      sign_in: 'Iniciar Sesión o Crear Cuenta'
    }
  }
};
